/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import t from "../../../Viewpoint.js";
import { deg2rad as e, lerp as o } from "../../../core/mathUtils.js";
import i from "../../../geometry/Point.js";
import { distance as r } from "../../../geometry/support/pointUtils.js";
import { getScaleToResolutionFactor as s, copy as n } from "../viewpointUtils.js";
class a {
  constructor(e) {
    this._view = e, this.viewpoint = new t({
      targetGeometry: new i(),
      scale: 0,
      rotation: 0
    });
  }
  get view() {
    return this._view;
  }
  get size() {
    const [t, e] = this._view.size;
    return Math.sqrt(t * t + e * e);
  }
  get scale() {
    return this.viewpoint.scale;
  }
  get rotation() {
    return this.viewpoint.rotation;
  }
  get center() {
    return this.viewpoint.targetGeometry;
  }
  get scaleToResolutionFactor() {
    return s(this.center.spatialReference);
  }
  pixelsPerPanAtZoom(t) {
    return 1 / (t * this.scaleToResolutionFactor);
  }
  zoomAtPixelsPerPan(t) {
    return 1 / (t * this.scaleToResolutionFactor);
  }
  pixelsPerRotate() {
    return this.size / 2;
  }
  compareTo(t, o) {
    o.pan = r(this.center, t.center);
    let i = Math.abs(t.rotation - this.rotation);
    i = i >= 180 ? 360 - i : i, o.rotate = e(i), o.sourceZoom = this.scale, o.targetZoom = t.scale;
  }
  interpolate(t, e, i) {
    const {
        pan: r,
        rotate: s,
        zoom: n,
        zoomOffset: a
      } = i,
      {
        center: c
      } = this;
    c.spatialReference = t.center.spatialReference, c.x = o(t.center.x, e.center.x, r), c.y = o(t.center.y, e.center.y, r), this.viewpoint.scale = o(t.scale, e.scale + a, n);
    let p = t.rotation;
    const l = e.rotation;
    Math.abs(l - p) >= 180 && (p += 360 * (p < l ? 1 : -1)), this.viewpoint.rotation = o(p, l, s);
  }
  copyFrom(t) {
    n(this.viewpoint, t.viewpoint), this._view = t.view;
  }
}
export { a as Camera };