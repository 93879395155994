import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ReportBugModalComponent} from '../report-bug-modal/report-bug-modal.component';
import {PopupService} from '../../services/popup.service';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ToolbarComponent {
    @Input() showLeftBtn = true;
    @Input() forceLeftBtnHref = false;
    @Input() defaultHref: string = '';
    @Input() rightBtnMode: 'list' | 'close' | 'custom' | null = null;
    @Input() rightBtnIconName: string | null = null;
    @Input() showLogoutBtn = false;
    @Input() showAttachmentBtn = false;
    @Input() showRejectBtn = false;
    @Output() rightButtonClick = new EventEmitter<void>();
    @Output() attachmentButtonClick = new EventEmitter<void>();
    @Output() rejectButtonClick = new EventEmitter<void>();
    @Output() backButtonClick = new EventEmitter<void>();

    constructor(private popupService: PopupService) {
    }

    get isListMode() {
        return this.rightBtnMode && this.rightBtnMode === 'list';
    }

    get isCloseMode() {
        return this.rightBtnMode && this.rightBtnMode === 'close';
    }

    get rightBtnIcon(): string {
        if (this.rightBtnIconName) {
            return this.rightBtnIconName;
        }

        if (this.isListMode) {
            return 'grid';
        }

        if (this.isCloseMode) {
            return 'x';
        }

        return '';
    }

    async openBugReportModal() {
        this.popupService.open(ReportBugModalComponent, {})
    }
}
