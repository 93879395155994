import {Component, ElementRef, Input, QueryList, ViewChildren} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormUtils} from '../../../utils/form-utils';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';
import {
    TableColumn,
    TableColumnText,
    TabularQuestion,
    TabularQuestionAnswer,
    TabularQuestionAnswerRow
} from '../../../models/question/tabular-question';
import {numberQuestionValidator} from '../../../validators/number-question.validator';
import {QuestionTypeTabularModalComponent, QuestionTypeTabularModalResult} from './question-type-tabular-modal.component';
import {take} from 'rxjs/operators';
import {Forms} from '../../../utils/forms';
import {QUESTION_TEXT_MAX_LENGTH, QUESTION_TEXT_MULTILINE_MAX_LENGTH} from '../../../models/question/text-question';
import {Subject} from 'rxjs';
import {PopupService} from '../../../services/popup.service';
import {CdkOverlayOrigin, CdkScrollable, ConnectedPosition, Overlay} from '@angular/cdk/overlay';

type RowFormGroup = FormGroup<{
    label: FormControl<string>,
    columns: FormGroup<{ [key: string]: FormControl<string | number | null> }>
}>;

@Component({
    selector: 'app-question-v2-tabular',
    templateUrl: './question-v2-tabular.component.html',
    hostDirectives: [CdkScrollable],
    standalone: false
})
export class QuestionV2TabularComponent {
    private currentForm: AnyProjectJobForm | null = null;
    public currentQuestion: TabularQuestion | null = null;
    public currentNode: AnyLayeredFormNode | undefined = undefined;
    public doGoForward = new Subject<void>();

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.updateAnswer();
    }

    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNode = node;
        this.updateAnswer();
    }

    @Input({required: true}) set question(question: TabularQuestion) {
        this.currentQuestion = question;

        this.updateAnswer();
        this.updateRequiredValidator();
    }

    public disabled = false;
    public rowControls: FormArray<RowFormGroup> = new FormArray<RowFormGroup>([]);
    @ViewChildren('rows') rows: QueryList<ElementRef<HTMLDetailsElement>> | null = null;

    public popoverRowIndex: number | null = null;
    public popoverPreferredPositions: ConnectedPosition[] = [
        { // Position in bottom left corner
            originX: 'start',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetX: 15,
            offsetY: -8
        },
        { // Position in top left corner
            originX: 'start',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
            offsetX: 15,
            offsetY: 8
        }
    ];

    constructor(
        private popupService: PopupService,
        public overlay: Overlay,
        public hostScrollable: CdkScrollable
    ) {
    }

    buildPopoverPositionStrategy(origin: CdkOverlayOrigin) {
        return this.overlay.position()
            .flexibleConnectedTo(origin.elementRef)
            .withPositions(this.popoverPreferredPositions)
            .withScrollableContainers([this.hostScrollable])
    }

    checkValidity() {
        if (this.rowControls) {
            Forms.updateValueAndValidityRecursive(this.rowControls);

            const invalidControlIndex = Forms.firstInvalidIndex(this.rowControls);
            if (invalidControlIndex !== null) {
                const invalidControl = this.rows?.get(invalidControlIndex);
                invalidControl?.nativeElement.scrollIntoView({block: 'center', behavior: 'smooth'});
            }
        }
    }

    submit() {
        this.doGoForward.next();
    }

    get isValid() {
        return this.rowControls.valid;
    }

    get currentValue(): ProjectJobAnswerValue {
        return {
            value: JSON.stringify(this.rowControls.value),
            remarkText: null,
            remarkImage: null,
        };
    }

    private updateAnswer() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const latest = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);
        const value = latest?.value;

        this.rowControls.clear({emitEvent: false});

        if (value) {
            const rows: TabularQuestionAnswer = JSON.parse(value);
            rows.forEach(row => {
                return this.createColumnControls(row.label, row.columns);
            });
        }
    }

    isTableColumnText(column: TableColumn): TableColumnText | null {
        return column.type === 'text' ? column : null;
    }

    createColumnControls = (label: string, row: TabularQuestionAnswerRow['columns']) => {
        if (!this.currentQuestion) {
            throw new Error('Missing question');
        }

        const columnControls: { [key: string]: FormControl<string | number | null> } = {};

        this.currentQuestion.columns.forEach(column => {
            if (column.type === 'number') {
                const numberValue: string | number | null = row[column.key] || null;
                columnControls[column.key] = new FormControl<string | number | null>(numberValue, {
                    validators: [
                        Validators.maxLength(255),
                        numberQuestionValidator(column.config.totalDecimal),
                        Validators.required
                    ]
                });
            } else {
                columnControls[column.key] = new FormControl<string | number>(row[column.key] || '', {
                    validators: [
                        Validators.maxLength(column.config.multiline
                            ? QUESTION_TEXT_MULTILINE_MAX_LENGTH
                            : QUESTION_TEXT_MAX_LENGTH
                        ),
                        Validators.required
                    ]
                });
            }
        });

        this.rowControls?.push(new FormGroup({
                label: new FormControl<string>(label, {
                    nonNullable: true,
                    validators: [Validators.required]
                }),
                columns: new FormGroup(columnControls)
            })
        );
    };

    async addRow() {
        const popupRef = this.popupService.open(QuestionTypeTabularModalComponent, {
            data: {
                type: 'add',
                rowLabelValidator: (control: FormControl) => {
                    return -1 === this.rowControls?.value.findIndex((value) => {
                        return control.value === value.label;
                    }) ? null : {unique: true};
                }
            }
        });

        await popupRef.result<QuestionTypeTabularModalResult>().then(result => {
            if (result && result.type === 'value') {
                this.createColumnControls(result.value, {});
            }
        });

        // Wait for row to be created in html and then open it
        this.rows?.changes.pipe(take(1)).subscribe((change: typeof this.rows) => {
            change.last.nativeElement.open = true;
        });
    }

    async editRow(rowIndex: number) {
        const currentLabel = this.rowControls?.controls[rowIndex].controls.label.value;

        const popup = this.popupService.open(QuestionTypeTabularModalComponent, {
            data: {
                type: 'edit',
                rowLabelValue: currentLabel,
                rowLabelValidator: (control: FormControl) => {
                    return -1 === this.rowControls?.value.findIndex((value, index) => {
                        return rowIndex !== index && control.value === value.label;
                    }) ? null : {unique: true};
                },
            }
        });

        await popup.result<QuestionTypeTabularModalResult>().then(result => {
            if (result && result.type === 'value') {
                this.rowControls?.controls[rowIndex].controls.label.patchValue(result.value);
            }
        });
    }

    async showDeleteModal(row: number) {
        const popup = this.popupService.open(QuestionTypeTabularModalComponent, {
            data: {
                type: 'delete',
            }
        });
        await popup.result<QuestionTypeTabularModalResult>().then(result => {
            if (result && result.type === 'delete') {
                this.deleteRow(row);
            }
        });
    }

    async deleteRow(rowIndex: number) {
        this.rowControls?.removeAt(rowIndex);
    }

    private updateRequiredValidator() {
        if (!this.currentQuestion) {
            return;
        }

        if (this.currentQuestion.required) {
            this.rowControls?.setValidators(Validators.required);
        } else {
            this.rowControls?.clearValidators();
        }

        this.rowControls?.updateValueAndValidity();
    }
}
