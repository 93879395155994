/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import t from "../../core/Accessor.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
let r = class extends t {
  get canZoomIn() {
    const o = this.view?.ready;
    if (!o) return !1;
    const t = this.view?.constraints?.effectiveMaxScale;
    return 0 === t || this._scale > t;
  }
  get canZoomOut() {
    const {
        view: o
      } = this,
      t = o?.ready;
    if (!t) return !1;
    const e = o.constraints?.effectiveMinScale;
    return 0 === e || this._scale < e;
  }
  get _scale() {
    const o = this.view?.animation?.target;
    return (o && "then" in o ? void 0 : o?.scale) ?? this.view?.scale ?? 0;
  }
};
o([e({
  readOnly: !0
})], r.prototype, "canZoomIn", null), o([e({
  readOnly: !0
})], r.prototype, "canZoomOut", null), o([e()], r.prototype, "view", void 0), o([e()], r.prototype, "_scale", null), r = o([s("esri.widgets.Zoom.ZoomConditions2D")], r);
const c = r;
export { c as default };