import {Component, Input} from '@angular/core';
import {PaulaObject} from '../../models/paula-object';
import {PopupRef} from '../../helpers/popup-ref';
import {PopupConfig} from '../../models/popup-config';

@Component({
    selector: 'app-object-list-modal',
    templateUrl: './object-list-modal.component.html',
    standalone: false
})
export class ObjectListModalComponent {
    @Input() project: number | null = null;

    constructor(private popupRef: PopupRef, {
        data
    }: PopupConfig<{ project: number }>) {
        if (data) {
            this.project = data.project;
        }
    }

    async onSelect(object: PaulaObject) {
        await this.popupRef.close(object);
    }

    onCancel() {
        this.popupRef.close();
    }
}
