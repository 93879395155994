/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as e } from "../../../chunks/tslib.es6.js";
import t from "../../../core/Accessor.js";
import "../../../core/has.js";
import { destroyMaybe as o } from "../../../core/maybe.js";
import { when as r, watch as i, initial as n, syncAndInitial as s } from "../../../core/reactiveUtils.js";
import { property as a } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as c } from "../../../core/accessorSupport/decorators/subclass.js";
import { DoubleClickZoom as p } from "./handlers/DoubleClickZoom.js";
import { DoubleTapDragZoom as m } from "./handlers/DoubleTapDragZoom.js";
import { DragPan as l } from "./handlers/DragPan.js";
import { DragRotate as u } from "./handlers/DragRotate.js";
import { GamepadNavigation as h } from "./handlers/GamepadNavigation.js";
import { KeyPan as w } from "./handlers/KeyPan.js";
import { KeyRotate as d } from "./handlers/KeyRotate.js";
import { KeyZoom as v } from "./handlers/KeyZoom.js";
import { MouseWheelZoom as g } from "./handlers/MouseWheelZoom.js";
import { PinchRotateAndZoom as f } from "./handlers/PinchAction.js";
import { BrowserEventSource as j } from "../../input/BrowserEventSource.js";
import { InputManager as A, ViewEventPriorities as y } from "../../input/InputManager.js";
import { PreventContextMenu as _ } from "../../input/handlers/PreventContextMenu.js";
import { getPointerActions as D } from "../../input/handlers/support.js";
import { DoubleTapDrag as M } from "../../input/recognizers/DoubleTapDrag.js";
import { Drag as b } from "../../input/recognizers/Drag.js";
import { ImmediateDoubleClick as P } from "../../input/recognizers/ImmediateDoubleClick.js";
import { PointerClickHoldAndDrag as T } from "../../input/recognizers/PointerClickHoldAndDrag.js";
import { SingleAndDoubleClick as z } from "../../input/recognizers/SingleAndDoubleClick.js";
const C = {
    counter: "Control",
    pan: {
      left: "ArrowLeft",
      right: "ArrowRight",
      up: "ArrowUp",
      down: "ArrowDown"
    },
    zoom: {
      zoomIn: ["=", "+"],
      zoomOut: ["-", "_"]
    },
    rotate: {
      clockwise: ["a", "A"],
      counterClockwise: ["d", "D"],
      reset: ["n", "N"]
    }
  },
  L = Symbol("handles");
let k = class extends t {
  initialize() {
    const e = () => this.view?.ready;
    this.addHandles([r(() => !e(), () => this._disconnect()), r(e, () => this._connect())]);
  }
  destroy() {
    this._disconnect();
  }
  get latestPointerType() {
    return this._inputManager?.latestPointerType;
  }
  get latestPointerLocation() {
    return this._inputManager?.latestPointerLocation;
  }
  get multiTouchActive() {
    return this._inputManager?.multiTouchActive ?? !1;
  }
  isModifierKeyDown(e) {
    return this._inputManager?.isModifierKeyDown(e) ?? !1;
  }
  _disconnect() {
    this.view.viewEvents.disconnect(), this.removeHandles(L), this._inputManager = o(this._inputManager);
  }
  _connect() {
    const e = this.view.surface,
      t = new j(e, this.view.input),
      o = [new P(), new T(), new z(), new b(this.view.navigation), new M()],
      r = new A({
        eventSource: t,
        recognizers: o
      }),
      a = new l(this.view, ["primary"]),
      c = new u(this.view, ["secondary"]);
    r.installHandlers("prevent-context-menu", [new _()], y.INTERNAL), r.installHandlers("navigation", [new f(this.view), new h(this.view), new g(this.view), new p(this.view), new p(this.view, [C.counter]), a, new w(this.view, C.pan), new v(this.view, C.zoom), new d(this.view, C.rotate), c, new m(this.view, "touch")], y.INTERNAL), this.view.viewEvents.connect(r), this._source = t, this._inputManager = r, this.addHandles([i(() => this.view?.navigation?.browserTouchPanEnabled, e => {
      this._source && (this._source.browserTouchPanningEnabled = !e);
    }, n), i(() => {
      const {
        actionMap: e
      } = this.view.navigation;
      return {
        panActions: D("pan", e),
        rotateActions: D("rotate", e)
      };
    }, ({
      panActions: e,
      rotateActions: t
    }) => {
      a.pointerActions = e, c.pointerActions = t;
    }, s)], L);
  }
  get test() {}
};
e([a()], k.prototype, "view", void 0), e([a()], k.prototype, "latestPointerType", null), e([a()], k.prototype, "latestPointerLocation", null), e([a()], k.prototype, "multiTouchActive", null), k = e([c("esri.views.2d.input.MapViewInputManager")], k);
const E = k;
export { E as default };