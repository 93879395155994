/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import t from "../../core/Accessor.js";
import e from "../../core/Collection.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
import n from "../../geometry/Extent.js";
import i from "../../geometry/Polygon.js";
import a from "../../layers/support/LOD.js";
import { GeometryConstraint as m } from "./constraints/GeometryConstraint.js";
import c from "./constraints/RotationConstraint.js";
import l from "./constraints/ZoomConstraint.js";
const p = {
  base: null,
  key: "type",
  typeMap: {
    extent: n,
    polygon: i
  }
};
let y = class extends t {
  constructor(o) {
    super(o), this.lods = null, this.minScale = 0, this.maxScale = 0, this.minZoom = -1, this.maxZoom = -1, this.rotationEnabled = !0, this.snapToZoom = !0, this.customConstraints = new e();
  }
  destroy() {
    this.view = null;
  }
  get effectiveLODs() {
    return this._zoom.effectiveLODs;
  }
  get effectiveMinScale() {
    return this._zoom.effectiveMinScale;
  }
  get effectiveMaxScale() {
    return this._zoom.effectiveMaxScale;
  }
  get effectiveMinZoom() {
    return this._zoom.effectiveMinZoom;
  }
  get effectiveMaxZoom() {
    return this._zoom.effectiveMaxZoom;
  }
  set geometry(o) {
    o ? this._set("geometry", o) : this._set("geometry", null);
  }
  get version() {
    return `${this._zoom?.uid}/${this._rotation?.uid}/${this._geometry?.uid}`;
  }
  get _geometry() {
    const o = this._get("_geometry");
    return o && this.geometry === o.geometry && this.view?.constraintsInfo.spatialReference === o.spatialReference ? o : new m({
      geometry: this.geometry,
      spatialReference: this.view?.constraintsInfo.spatialReference
    });
  }
  get _rotation() {
    return new c({
      rotationEnabled: this.rotationEnabled
    });
  }
  get _zoom() {
    const o = this._get("_zoom"),
      t = this.lods || this.view?.constraintsInfo.lods,
      e = this.minZoom,
      r = this.maxZoom,
      s = this.minScale,
      n = this.maxScale,
      i = this.snapToZoom;
    return o && o.lods === t && o.minZoom === e && o.maxZoom === r && o.minScale === s && o.maxScale === n && o.snapToZoom === i ? o : new l({
      lods: t,
      minZoom: e,
      maxZoom: r,
      minScale: s,
      maxScale: n,
      snapToZoom: i
    });
  }
  canZoomInTo(o) {
    const t = this.effectiveMaxScale;
    return 0 === t || o >= t;
  }
  canZoomOutTo(o) {
    const t = this.effectiveMinScale;
    return 0 === t || o <= t;
  }
  constrain(o, t) {
    return this._zoom.constrain(o, t), this._rotation.constrain(o, t), this._geometry.constrain(o, t), this.customConstraints.forEach(e => e.constrain(o, t)), o;
  }
  constrainByGeometry(o) {
    return this._geometry.constrain(o), this.customConstraints.forEach(t => t.applyPanConstraint?.(o)), o;
  }
  fit(o) {
    return this._zoom.fit(o);
  }
  zoomToScale(o) {
    return this._zoom.zoomToScale(o);
  }
  scaleToZoom(o) {
    return this._zoom.scaleToZoom(o);
  }
  snapScale(o) {
    return this._zoom.snapToClosestScale(o);
  }
  snapToNextScale(o) {
    return this._zoom.snapToNextScale(o);
  }
  snapToPreviousScale(o) {
    return this._zoom.snapToPreviousScale(o);
  }
};
o([r({
  readOnly: !0
})], y.prototype, "effectiveLODs", null), o([r({
  readOnly: !0
})], y.prototype, "effectiveMinScale", null), o([r({
  readOnly: !0
})], y.prototype, "effectiveMaxScale", null), o([r({
  readOnly: !0
})], y.prototype, "effectiveMinZoom", null), o([r({
  readOnly: !0
})], y.prototype, "effectiveMaxZoom", null), o([r({
  types: p,
  value: null
})], y.prototype, "geometry", null), o([r({
  type: [a]
})], y.prototype, "lods", void 0), o([r()], y.prototype, "minScale", void 0), o([r()], y.prototype, "maxScale", void 0), o([r()], y.prototype, "minZoom", void 0), o([r()], y.prototype, "maxZoom", void 0), o([r()], y.prototype, "rotationEnabled", void 0), o([r()], y.prototype, "snapToZoom", void 0), o([r({
  type: e
})], y.prototype, "customConstraints", void 0), o([r()], y.prototype, "view", void 0), o([r({
  readOnly: !0
})], y.prototype, "version", null), o([r({
  type: m,
  readOnly: !0
})], y.prototype, "_geometry", null), o([r({
  type: c
})], y.prototype, "_rotation", null), o([r({
  readOnly: !0,
  type: l
})], y.prototype, "_zoom", null), y = o([s("esri.views.2d.MapViewConstraints")], y);
const f = y;
export { f as default };