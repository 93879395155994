/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as e } from "./chunks/tslib.es6.js";
import s from "./Basemap.js";
import r from "./Ground.js";
import t from "./core/Accessor.js";
import o from "./core/CollectionFlattener.js";
import a from "./core/Evented.js";
import { destroyMaybe as i } from "./core/maybe.js";
import { property as p } from "./core/accessorSupport/decorators/property.js";
import { cast as l } from "./core/accessorSupport/decorators/cast.js";
import "./core/has.js";
import "./core/RandomLCG.js";
import { subclass as n } from "./core/accessorSupport/decorators/subclass.js";
import { isEditableLayer as m } from "./layers/support/editableLayers.js";
import { createCache as d, destroyCache as c, basemapEnsureType as u } from "./support/basemapEnsureType.js";
import { createFlattenedTablesCollection as y } from "./support/collectionUtils.js";
import { ensureType as h } from "./support/groundUtils.js";
import { LayersMixin as b } from "./support/LayersMixin.js";
import { TablesMixin as f } from "./support/TablesMixin.js";
let j = class extends f(b(a.EventedMixin(t))) {
  constructor(e) {
    super(e), this.allLayers = new o({
      getCollections: () => [this.basemap?.baseLayers, this.ground?.layers, this.layers, this.basemap?.referenceLayers],
      getChildrenFunction: e => "layers" in e ? e.layers : null
    }), this.allTables = y(this), this.basemap = null, this.editableLayers = new o({
      getCollections: () => [this.allLayers],
      itemFilterFunction: m
    }), this.ground = new r(), this._basemapCache = d();
  }
  destroy() {
    c(this._basemapCache), this._basemapCache = null, this.allLayers.destroy(), this.allTables.destroy(), this.editableLayers.destroy(), this.basemap = i(this.basemap), i(this.ground), this._set("ground", null);
  }
  castBasemap(e) {
    return u(e, this._basemapCache);
  }
  castGround(e) {
    const s = h(e);
    return s ?? this._get("ground");
  }
  findLayerById(e) {
    return this.allLayers.find(s => s.id === e);
  }
  findTableById(e) {
    return this.allTables.find(s => s.id === e);
  }
};
e([p({
  readOnly: !0,
  dependsOn: []
})], j.prototype, "allLayers", void 0), e([p({
  readOnly: !0
})], j.prototype, "allTables", void 0), e([p({
  type: s,
  json: {
    read: {
      source: "baseMap"
    },
    write: {
      target: "baseMap"
    }
  }
})], j.prototype, "basemap", void 0), e([l("basemap")], j.prototype, "castBasemap", null), e([p({
  readOnly: !0
})], j.prototype, "editableLayers", void 0), e([p({
  type: r,
  nonNullable: !0
})], j.prototype, "ground", void 0), e([l("ground")], j.prototype, "castGround", null), e([p()], j.prototype, "undoRedo", void 0), j = e([n("esri.Map")], j);
const L = j;
export { L as default };