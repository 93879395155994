/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import "../../../../core/has.js";
import { InputHandler as t } from "../../../input/InputHandler.js";
class e extends t {
  constructor(t, e, a) {
    super(!0), this.view = t, this.pointerType = e, this.registerIncoming("double-tap-drag", a, t => this._handleDoubleTapDrag(t));
  }
  _handleDoubleTapDrag(t) {
    const {
        data: e
      } = t,
      {
        pointerType: a
      } = e;
    if (a !== this.pointerType) return;
    t.stopPropagation();
    const {
        action: s,
        delta: i
      } = e,
      {
        view: r
      } = this,
      {
        mapViewNavigation: n
      } = r;
    switch (s) {
      case "begin":
        {
          const {
            scale: t
          } = r;
          this._startScale = t, this._currentScale = t, this._previousDelta = i, n.begin();
          break;
        }
      case "update":
        {
          if (this._previousDelta.y === i.y) return;
          this._previousDelta = i;
          const t = 1.015 ** i.y,
            e = this._startScale * t,
            a = e / this._currentScale;
          n.setViewpointImmediate(a), this._currentScale = e;
          break;
        }
      case "end":
        {
          const {
              constraints: t
            } = r,
            {
              effectiveLODs: e,
              snapToZoom: a
            } = t;
          if (!a || !e) return void n.end();
          const s = t.snapScale(this._currentScale),
            o = (i.y > 0 ? Math.max(s, t.snapToPreviousScale(this._startScale)) : Math.min(s, t.snapToNextScale(this._startScale))) / this._currentScale;
          n.zoom(o).then(() => {
            n.end();
          });
          break;
        }
    }
  }
}
export { e as DoubleTapDragZoom };