/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { clock as t } from "../../../core/clock.js";
import { removeMaybe as e } from "../../../core/maybe.js";
import { createScreenPoint as i } from "../../../core/screenUtils.js";
import { DragEventSeparator as o } from "../DragEventSeparator.js";
import { InputHandler as a } from "../InputHandler.js";
import { DefaultParameters as r, getPointerId as n, manhattanDistance as s } from "./support.js";
class u extends a {
  constructor(e = r.maximumDoubleClickDelay, a = r.maximumDoubleClickDistance, n = r.maximumDoubleTouchDelay, s = r.maximumDoubleTouchDistance, u = t) {
    super(!1), this._maximumDoubleClickDelay = e, this._maximumDoubleClickDistance = a, this._maximumDoubleTouchDelay = n, this._maximumDoubleTouchDistance = s, this._clock = u, this._doubleTapDragReady = !1, this._doubleTapDragActive = !1, this._dragStartCenter = i(0, 0), this._pointerState = new Map(), this._doubleTapDrag = this.registerOutgoing("double-tap-drag"), this._dragEventSeparator = new o({
      start: (t, e) => this._dragStart(t, e),
      update: (t, e) => this._dragUpdate(e),
      end: (t, e) => this._dragEnd(e)
    }), this.registerIncoming("drag", t => this._dragEventSeparator.handle(t)), this.registerIncoming("pointer-down", t => this._handlePointerDown(t)), this.registerIncoming("pointer-up", () => this._handlePointerUp());
  }
  onUninstall() {
    this._pointerState.forEach(t => {
      t.doubleTapTimeout = e(t.doubleTapTimeout);
    });
  }
  get hasPendingInputs() {
    for (const t of this._pointerState.values()) if (null != t.doubleTapTimeout) return !0;
    return !1;
  }
  _clearPointerDown(t) {
    const i = this._pointerState.get(t);
    i && (i.doubleTapTimeout = e(i.doubleTapTimeout), this._pointerState.delete(t), this.refreshHasPendingInputs());
  }
  _dragStart(t, e) {
    if (!this._doubleTapDragReady || 1 !== t) return;
    this._doubleTapDragReady = !1, this._doubleTapDragActive = !0;
    const {
        data: o,
        modifiers: a
      } = e,
      {
        center: r
      } = o;
    this._dragStartCenter = r;
    const n = d("begin", i(0, 0), o);
    this._doubleTapDrag.emit(n, void 0, a), e.stopPropagation();
  }
  _dragUpdate(t) {
    if (!this._doubleTapDragActive) return;
    const {
        data: e,
        modifiers: o
      } = t,
      {
        center: a
      } = e,
      r = d("update", i(a.x - this._dragStartCenter.x, a.y - this._dragStartCenter.y), e);
    this._doubleTapDrag.emit(r, void 0, o), t.stopPropagation();
  }
  _dragEnd(t) {
    if (!this._doubleTapDragActive) return;
    const {
        data: e,
        modifiers: o
      } = t,
      {
        center: a
      } = e,
      r = d("end", i(a.x - this._dragStartCenter.x, a.y - this._dragStartCenter.y), e);
    this._doubleTapDrag.emit(r, void 0, o), this._doubleTapDragActive = !1, t.stopPropagation();
  }
  _handlePointerDown(t) {
    const {
        data: e
      } = t,
      i = n(e),
      o = this._pointerState.get(i),
      {
        pointerType: a
      } = e.native;
    if (o) {
      const r = "touch" === a ? this._maximumDoubleTouchDistance : this._maximumDoubleClickDistance;
      this._clearPointerDown(i), s(o.event.data, e) > r ? this._storePointerDown(t) : this._doubleTapDragReady = !0;
    } else this._storePointerDown(t);
  }
  _handlePointerUp() {
    this._doubleTapDragReady = !1;
  }
  _storePointerDown(t) {
    const {
        data: e
      } = t,
      {
        pointerType: i
      } = e.native,
      o = n(e),
      a = "touch" === i ? this._maximumDoubleTouchDelay : this._maximumDoubleClickDelay,
      r = this._clock.setTimeout(() => this._clearPointerDown(o), a);
    this._pointerState.set(o, {
      event: t,
      doubleTapTimeout: r
    }), this.refreshHasPendingInputs();
  }
}
function d(t, e, i) {
  const {
    button: o,
    buttons: a,
    pointer: r,
    pointers: n,
    pointerType: s,
    timestamp: u
  } = i;
  return {
    action: t,
    delta: e,
    button: o,
    buttons: a,
    pointer: r,
    pointers: n,
    pointerType: s,
    timestamp: u
  };
}
export { u as DoubleTapDrag };