import {Component, Input, OnChanges, ViewChild} from '@angular/core';
import {ImageViewerComponent} from '../image-viewer/image-viewer.component';
import {ModalComponent} from '../modal/modal.component';
import {BehaviorSubject} from 'rxjs';
import {FormImageService} from '../../services/form-image.service';
import sanitizeHtml from 'sanitize-html';
import {Question} from "../../models/question/question";
import {AnnotatableImage} from '../image-annotation-v3/annotatable-image';

@Component({
    selector: 'app-question-description',
    templateUrl: './question-description.component.html',
    standalone: false
})
export class QuestionDescriptionComponent implements OnChanges {
    infoModalVisible$ = new BehaviorSubject(false);
    imageViewerVisible$ = new BehaviorSubject<AnnotatableImage | null>(null);

    @ViewChild('imageViewer') imageViewerRef?: ImageViewerComponent;
    @ViewChild('moreInfoModal') moreInfoModal?: ModalComponent;
    @Input() question: Question | null = null

    descriptionImageSrc?: string;
    SanitizeHtml = sanitizeHtml;

    constructor(private imageService: FormImageService) {
    }

    get descriptionType(): 'text' | 'image' {
        return this.question?.descriptionType ?? 'text'
    }

    get descriptionText(): string {
        return this.question?.description ?? ''
    }

    get descriptionImage(): string {
        return this.question?.imageDescription ?? ''
    }

    get moreInfo(): string {
        return this.question?.helpText ?? ''
    }

    async ngOnChanges() {
        if (this.descriptionImage) {
            this.descriptionImageSrc = await this.imageService.getImageUrl(this.descriptionImage);
        }
    }

    get showDescriptionText(): boolean {
        return this.descriptionType === 'text' && this.descriptionText !== null;
    }

    get showDescriptionImage(): boolean {
        return this.descriptionType === 'image';
    }

    showImageViewer() {
        this.imageViewerVisible$.next({originalPhotoId: this.descriptionImage});
    }

    showMoreInfoModal() {
        this.infoModalVisible$.next(true);
    }
}
