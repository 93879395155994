import { Component } from '@angular/core';
import {FormControl} from '@angular/forms';
import {PopupRef} from '../../helpers/popup-ref';
import {PopupConfig} from '../../models/popup-config';

@Component({
    selector: 'app-project-job-sort-modal',
    templateUrl: './project-job-sort-modal.component.html',
    standalone: false
})
export class ProjectJobSortModalComponent {
    control = new FormControl<string>(
        '', {
            nonNullable: true,
        }
    );

    sortOptions = [
        {label: 'Opdrachtnaam', value: 'title'},
        {label: 'Objectnaam', value: 'paulaObject.objectOmschrijvingKort'},
        {label: 'Opdrachtnummer', value: 'code'}
    ];

    get sort(): string {
        return this.control.value;
    }
    set sort(value: string) {
        this.control.setValue(value);
    }

    constructor(private popupRef: PopupRef, private popupConfig: PopupConfig<string>) {
        if (popupConfig.data) {
            this.control.setValue(popupConfig.data);
        }
    }

    cancel() {
        this.popupRef.close();
    }

    saveSort() {
        this.popupRef.close(this.sort);
    }
}
