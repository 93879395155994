/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import i from "../core/ArrayPool.js";
import { watch as s, initial as a } from "../core/reactiveUtils.js";
import { property as t } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as r } from "../core/accessorSupport/decorators/subclass.js";
const l = {
    widthBreakpoint: {
      getValue(e) {
        const i = e.viewSize[0],
          s = e.breakpoints,
          a = this.values;
        return i <= s.xsmall ? a.xsmall : i <= s.small ? a.small : i <= s.medium ? a.medium : i <= s.large ? a.large : a.xlarge;
      },
      values: {
        xsmall: "xsmall",
        small: "small",
        medium: "medium",
        large: "large",
        xlarge: "xlarge"
      },
      valueToClassName: {
        xsmall: "esri-view-width-xsmall esri-view-width-less-than-small esri-view-width-less-than-medium esri-view-width-less-than-large esri-view-width-less-than-xlarge",
        small: "esri-view-width-small esri-view-width-greater-than-xsmall esri-view-width-less-than-medium esri-view-width-less-than-large esri-view-width-less-than-xlarge",
        medium: "esri-view-width-medium esri-view-width-greater-than-xsmall esri-view-width-greater-than-small esri-view-width-less-than-large esri-view-width-less-than-xlarge",
        large: "esri-view-width-large esri-view-width-greater-than-xsmall esri-view-width-greater-than-small esri-view-width-greater-than-medium esri-view-width-less-than-xlarge",
        xlarge: "esri-view-width-xlarge esri-view-width-greater-than-xsmall esri-view-width-greater-than-small esri-view-width-greater-than-medium esri-view-width-greater-than-large"
      }
    },
    heightBreakpoint: {
      getValue(e) {
        const i = e.viewSize[1],
          s = e.breakpoints,
          a = this.values;
        return i <= s.xsmall ? a.xsmall : i <= s.small ? a.small : i <= s.medium ? a.medium : i <= s.large ? a.large : a.xlarge;
      },
      values: {
        xsmall: "xsmall",
        small: "small",
        medium: "medium",
        large: "large",
        xlarge: "xlarge"
      },
      valueToClassName: {
        xsmall: "esri-view-height-xsmall esri-view-height-less-than-small esri-view-height-less-than-medium esri-view-height-less-than-large esri-view-height-less-than-xlarge",
        small: "esri-view-height-small esri-view-height-greater-than-xsmall esri-view-height-less-than-medium esri-view-height-less-than-large esri-view-height-less-than-xlarge",
        medium: "esri-view-height-medium esri-view-height-greater-than-xsmall esri-view-height-greater-than-small esri-view-height-less-than-large esri-view-height-less-than-xlarge",
        large: "esri-view-height-large esri-view-height-greater-than-xsmall esri-view-height-greater-than-small esri-view-height-greater-than-medium esri-view-height-less-than-xlarge",
        xlarge: "esri-view-height-xlarge esri-view-height-greater-than-xsmall esri-view-height-greater-than-small esri-view-height-greater-than-medium esri-view-height-greater-than-large"
      }
    },
    orientation: {
      getValue(e) {
        const i = e.viewSize,
          s = i[0],
          a = i[1],
          t = this.values;
        return a >= s ? t.portrait : t.landscape;
      },
      values: {
        portrait: "portrait",
        landscape: "landscape"
      },
      valueToClassName: {
        portrait: "esri-view-orientation-portrait",
        landscape: "esri-view-orientation-landscape"
      }
    }
  },
  h = {
    xsmall: 544,
    small: 768,
    medium: 992,
    large: 1200
  };
function n(e) {
  const i = e;
  return i && i.xsmall < i.small && i.small < i.medium && i.medium < i.large;
}
function m(e, i) {
  return i ? l[e].valueToClassName[i].split(" ") : [];
}
const o = o => {
  let g = class extends o {
    constructor(...e) {
      super(...e), this.orientation = null, this.widthBreakpoint = null, this.heightBreakpoint = null, this.breakpoints = h;
    }
    initialize() {
      this.addHandles(s(() => [this.breakpoints, this.size], () => this._updateClassNames(), a));
    }
    destroy() {
      this.destroyed || this._removeActiveClassNames();
    }
    set breakpoints(e) {
      if (e === this._get("breakpoints")) return;
      if (!n(e)) {
        const i = JSON.stringify(h, null, 2);
        console.warn("provided breakpoints are not valid, using defaults:" + i), e = h;
      }
      this._set("breakpoints", {
        ...e
      });
    }
    _updateClassNames() {
      if (!this.container) return;
      const e = i.acquire(),
        s = i.acquire();
      let a,
        t = !1;
      for (a in l) {
        const i = this[a],
          r = l[a].getValue({
            viewSize: this.size,
            breakpoints: this.breakpoints
          });
        i !== r && (t = !0, this[a] = r, m(a, i).forEach(e => s.push(e)), m(a, r).forEach(i => e.push(i)));
      }
      t && (this._applyClassNameChanges(e, s), i.release(e), i.release(s));
    }
    _applyClassNameChanges(e, i) {
      const s = this.container;
      s && (i.forEach(e => s.classList.remove(e)), e.forEach(e => s.classList.add(e)));
    }
    _removeActiveClassNames() {
      const e = this.container;
      if (!e) return;
      let i;
      for (i in l) m(i, this[i]).forEach(i => e.classList.remove(i));
    }
  };
  return e([t()], g.prototype, "breakpoints", null), e([t()], g.prototype, "orientation", void 0), e([t()], g.prototype, "widthBreakpoint", void 0), e([t()], g.prototype, "heightBreakpoint", void 0), g = e([r("esri.views.BreakpointsOwner")], g), g;
};
export { o as BreakpointsOwner };