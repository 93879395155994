import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {PageResponse} from '../models/page-response';
import {Project} from '../models/project';
import {BehaviorSubject, firstValueFrom, from, Observable} from 'rxjs';
import {db} from '../db/db';
import {liveQuery} from 'dexie';
import {WorkspaceConfigService} from './workspace-config.service';
import {AuthenticationService} from './authentication.service';

export interface ProjectServiceInterface {
    list(search: string): Observable<Project[]>;
    get(id: number): Promise<Project>;
    syncProjects(): Promise<void>;
}

@Injectable()
export class ProjectService implements ProjectServiceInterface {
    readonly syncInProgress$ = new BehaviorSubject<boolean>(false)
    private lastSyncDate: number | null = null;

    constructor(
        private http: HttpClient,
        private workspaceConfigService: WorkspaceConfigService,
        authenticationService: AuthenticationService,
    ) {
        authenticationService.logout$.subscribe(() => {
            db.projects.clear()
        })
    }

    list(search = ''): Observable<Project[]> {
        return from(liveQuery(() => {
            const searchLower = search.toLowerCase()
            return db.projects.orderBy('name').filter(project => {
                return project.code.toLowerCase().indexOf(searchLower) !== -1
                    || project.name.toLowerCase().indexOf(searchLower) !== -1;
            }).toArray()
        }));
    }

    async get(id: number): Promise<Project> {
        let project = await db.projects.get(id);
        if (project === undefined) {
            // If project is not found locally try syncing
            await this.syncProjects();
            project = await db.projects.get(id);
            if (project === undefined) {
                // If still not found throw error
                throw new Error(`No project found for id ${id}`)
            }
        }

        return project
    }

    async syncProjects() {
        if (this.lastSyncDate !== null && this.lastSyncDate > (new Date().getTime() - 5000)) {
            // Limit sync to max once every 5 seconds
            return;
        }

        try {
            this.syncInProgress$.next(true)
            const orphanedIds = await db.projects.toCollection().primaryKeys(ids => new Set(ids))

            let page = 0;
            let projectPageResponse: PageResponse<Project>;
            do {
                projectPageResponse = await firstValueFrom(this.http.get<PageResponse<Project>>(`/app-api/v1/projects`, {
                    params: {page: `${page++}`}
                }));

                await db.projects.bulkPut(projectPageResponse.content)

                for (const project of projectPageResponse.content) {
                    orphanedIds.delete(project.id)
                }
            } while (!projectPageResponse.last)

            // Delete projects that are in local db but not returned from server (happens if user is removed from project team)
            db.projects.bulkDelete([...orphanedIds])

            this.lastSyncDate = new Date().getTime()
        } catch (e) {
            console.error('Project sync failed', e)
        } finally {
            this.syncInProgress$.next(false)
        }
    }
}
