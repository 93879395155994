import {Pipe, PipeTransform} from '@angular/core';
import {Questions} from '../utils/questions';
import {ProjectJobFormChapter} from "../models/project-job-form-chapter";

@Pipe({
    name: 'chapterTitle',
    standalone: true
})
export class ChapterTitlePipe implements PipeTransform {
    /**
     * Takes a value and formats date
     */
    transform(chapter: ProjectJobFormChapter) {
        if (!chapter) {
            return '';
        }

        return Questions.getChapterTitle(chapter);
    }
}
