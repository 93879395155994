import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {Subject, Subscription} from 'rxjs';
import {parse} from 'date-fns';
import {nl} from 'date-fns/locale/nl';
import {DatetimeQuestion} from '../../../models/question/datetime-question';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormUtils} from '../../../utils/form-utils';
import {ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {controlValueChanges} from '../../../utils/form-control-value';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';
import {isValid} from 'date-fns/isValid';

interface DateValue {
    date: string | null;
    time: string | null;
}

@Component({
    selector: 'app-question-v2-datetime',
    templateUrl: './question-v2-datetime.component.html',
    standalone: false
})
export class QuestionV2DatetimeComponent implements OnInit, OnDestroy {
    private currentForm: AnyProjectJobForm | null = null;
    public currentQuestion: DatetimeQuestion | null = null;
    public currentNode: AnyLayeredFormNode | undefined = undefined;
    public doGoForward = new Subject<void>();

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.setDateGroupValue();
    }
    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNode = node;
        this.setDateGroupValue();
    }
    @Input({required: true}) set question(question: DatetimeQuestion) {
        this.currentQuestion = question;

        if (this.currentQuestion.required) {
            this.control.addValidators(Validators.required);
        } else {
            this.control.removeValidators(Validators.required);
        }

        this.setDateGroupValue();
    }

    public control = new FormControl<string>({ value: '', disabled: false }, { nonNullable: true });

    dateGroup = new FormGroup({
        date: new FormControl<string | null>(this.formatDateValue(new Date())),
        time: new FormControl<string | null>(this.formatTimeValue(new Date()))
    });
    dateGroupSubscription: Subscription | null = null;

    constructor(private datePipe: DatePipe) {
    }

    get currentValue(): ProjectJobAnswerValue {
        return {
            value: this.control.value,
            remarkText: null,
            remarkImage: null,
        }
    }

    ngOnInit(): void {
        // Listen for valueChanges and update the question value if values are changed
        this.dateGroupSubscription = controlValueChanges(this.dateGroup).pipe(
            distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
            // Prevents bug described in VP-1148, because intermediate values like 05-00-2020 gives empty value
            filter(({date, time}) => date !== '' && time !== '')
        ).subscribe(
            () => this.updateAnswer(this.dateGroup.getRawValue())
        );
    }

    submit() {
        this.doGoForward.next();
    }

    ngOnDestroy(): void {
        if (this.dateGroupSubscription) {
            this.dateGroupSubscription.unsubscribe();
        }
    }

    private formatDateValue(date: Date): string | null {
        return this.datePipe.transform(date, 'yyyy-MM-dd') || null;
    }

    private formatTimeValue(date: Date): string | null {
        return this.datePipe.transform(date, 'HH:mm:ss') || null;
    }

    private setDateGroupValue() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const latest = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);

        if (latest === null || latest.value === null) {
            this.dateGroup.reset({
                date: this.formatDateValue(new Date()),
                time: this.formatTimeValue(new Date())
            });
            return;
        }
        const dateValue = new Date(latest.value);
        if (isNaN(dateValue.getTime())) {
            console.error(`LatestAnswer value is not a valid date: '${latest.value}'`);
            return;
        }

        this.dateGroup.setValue({
            date: this.formatDateValue(dateValue),
            time: this.formatTimeValue(dateValue)
        });
    }

    private updateAnswer(value: DateValue) {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const { date, time } = value;

        // Set dateValue to given date or to current date if empty and format it
        const dateValue = date !== null && date !== '' ? parse(date, 'yyyy-MM-dd', new Date(), {locale: nl}) : new Date();
        if (!isValid(dateValue)) {
            throw new Error(`Invalid date: ${date}`);
        }
        const dateFormatted = this.formatDateValue(dateValue);

        // Set timeValue to given time or to current time if empty and format it
        const timeValue = this.parseTime(time, dateValue);
        const timeFormatted = this.formatTimeValue(timeValue);

        this.dateGroup.setValue({
            date: dateFormatted,
            time: timeFormatted
        });

        this.control.setValue(timeValue.toISOString());
    }

    private parseTime(time: string | null, dateValue: Date) {
        if (time === null || time === '') {
            return dateValue;
        }

        const timeValue = parse(time, 'HH:mm', dateValue, {locale: nl});
        if (isValid(timeValue)) {
            return timeValue;
        }

        // Try to parse with seconds
        const timeWithSeconds = parse(time, 'HH:mm:ss', dateValue, {locale: nl});
        if (isValid(timeWithSeconds)) {
            return timeWithSeconds;
        }

        throw new Error(`Invalid time: ${time}`);
    }
}
