import {Component} from '@angular/core';
import {PopupRef} from '../../helpers/popup-ref';
import {FormControl, Validators} from '@angular/forms';

@Component({
    selector: 'app-image-annotation-text-popup',
    templateUrl: './image-annotation-text-popup.component.html',
    standalone: false
})
export class ImageAnnotationTextPopupComponent {
    public text = new FormControl<string | null>(null, [Validators.maxLength(60), Validators.required])

    constructor(private popupRef: PopupRef) {
    }

    async confirm() {
        if (this.text.invalid) {
            return;
        }

        this.popupRef.close(this.text.value);
    }

    cancel() {
        this.popupRef.close(false);
    }
}
