import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconComponent {
    @Input() iconName: string | undefined;

    get icon() {
        return this.iconName !== undefined
            ? `assets/svg-icons/icons.svg#icon-${this.iconName}`
            : undefined;
    }

}
