import { Component } from '@angular/core';

@Component({
    selector: 'app-action-sheet-button',
    templateUrl: './action-sheet-button.component.html',
    standalone: false
})
export class ActionSheetButtonComponent {

}
