/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import i from "../../Viewpoint.js";
import e from "../../core/Accessor.js";
import "../../core/has.js";
import { removeMaybe as n } from "../../core/maybe.js";
import { addFrameTask as s } from "../../core/scheduling.js";
import { createScreenPoint as o } from "../../core/screenUtils.js";
import { Milliseconds as a } from "../../core/time.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as m } from "../../core/accessorSupport/decorators/subclass.js";
import { create as p } from "../../core/libs/gl-matrix-2/factories/vec2f64.js";
import h from "../../geometry/Point.js";
import u from "../ViewAnimation.js";
import c from "./PaddedViewState.js";
import { copy as d } from "./viewpointUtils.js";
import { Camera as w } from "./pointToPoint/Camera.js";
import { EasingFunctions as _ } from "../animation/easing.js";
import { Animation as l } from "../animation/pointToPoint/Animation.js";
import { defaultSettings2D as v } from "../animation/pointToPoint/Settings.js";
const f = 2e3,
  g = 64;
class j {
  constructor(t) {
    this._view = t, this._animation = new l(() => new w(this._view)), this._current = new w(this._view);
  }
  get _source() {
    return this._animation.definition.source;
  }
  get _target() {
    return this._animation.definition.target;
  }
  get duration() {
    return this._animation.time;
  }
  get animation() {
    return this._animation;
  }
  update(t, i, e = {}) {
    d(this._current.viewpoint, t), d(this._source.viewpoint, t), d(this._target.viewpoint, i), this._animation.update(this._source, this._target, e);
  }
  applyRatio(t, i) {
    this._animation.cameraAt(i, this._current), d(t, this._current.viewpoint);
  }
}
let y = class extends e {
  constructor(t) {
    super(t), this._animation = null, this._destinationViewState = new c(), this.updateFunction = null, this.easing = _.ease, this.viewpoint = new i({
      targetGeometry: new h(),
      scale: 0,
      rotation: 0
    }), this._updateTask = s({
      postRender: this._postRender.bind(this)
    }), this._updateTask.pause(), this._transition = new j(t.view);
  }
  destroy() {
    this._updateTask = n(this._updateTask);
  }
  get animation() {
    return this._animation;
  }
  set animation(t) {
    this._animation = t, this.view.animation = t;
  }
  animate(t, i, e) {
    this.stop();
    const n = this.viewpoint;
    d(n, i);
    const s = t.target;
    this._transition.update(this.viewpoint, s, {
      apex: {
        maximumDistance: Math.min(Math.min(i.scale, s.scale) * g, this.view.constraints.effectiveMinScale),
        desiredSlope: 5e-8
      },
      duration: e?.duration,
      maxDuration: "auto" === e?.animationMode ? a(1 / 0) : e?.maxDuration ?? v.maxDuration,
      speedFactor: e?.speedFactor,
      easing: ("string" == typeof e?.easing ? _[e.easing] : e?.easing) || this.easing
    }), "auto" === e?.animationMode && (this._destinationViewState.copy(this.view.state), this._destinationViewState.viewpoint = s, T(this._transition.animation, e, this.view.state, this._destinationViewState) || this._transition.update(this.viewpoint, s, {
      duration: a(0)
    }));
    const o = () => {
      this.animation === t && this._updateTask && ("finished" === t.state && (this._transition.applyRatio(this.viewpoint, 1), this.view.state && (this.view.state.viewpoint = this.viewpoint.clone())), this.animation = null, this.updateFunction = null);
    };
    return t.when(o, o), this._startTime = performance.now(), this._updateTask.resume(), this.animation = t, t;
  }
  animateContinuous(t, i) {
    this.stop(), this.updateFunction = i, this.viewpoint = t;
    const e = new u({
        target: t.clone()
      }),
      n = () => {
        this.animation === e && this._updateTask && (this.animation = null, this.updateFunction = null);
      };
    return e.when(n, n), this._startTime = performance.now(), this._updateTask.resume(), this.animation = e, e;
  }
  stop() {
    this.animation && (this.animation.stop(), this.animation = null, this.updateFunction = null);
  }
  _postRender(t) {
    const i = this.animation;
    if (i && i.state !== u.State.STOPPED) {
      if (this.updateFunction) this.updateFunction(this.viewpoint, t.deltaTime), this.animation?.update(this.viewpoint);else {
        const t = performance.now() - this._startTime,
          i = this._transition.duration,
          e = i > 0 ? t / i : 1,
          n = e >= 1;
        this._transition.applyRatio(this.viewpoint, e), n && this.animation?.finish();
      }
      this.view.state && (this.view.state.viewpoint = this.viewpoint.clone());
    } else this._updateTask.pause();
  }
};
function T(t, i, e, n) {
  if (null != i?.duration) return !0;
  const {
      time: s,
      isLinear: a
    } = t,
    r = i?.speedFactor || 1;
  if (s > (i?.maxDuration ?? v.maxDuration / r)) return !1;
  if (a) {
    const t = 1.5,
      i = p(),
      a = o(...n.toScreen(i, ...e.center)),
      m = o(...e.toScreen(i, ...n.center)),
      h = null != m && m.x > -1 * e.size[0] && m.x < (t + .5) * e.size[0] && m.y > -1 * e.size[1] && m.y < (t + .5) * e.size[1],
      u = null != a && a.x > -1 * n.size[0] && a.x < (t + .5) * n.size[0] && a.y > -1 * n.size[1] && a.y < (t + .5) * n.size[1];
    if (s > f / r && !h && !u) return !1;
  }
  return !0;
}
t([r()], y.prototype, "easing", void 0), t([r()], y.prototype, "view", void 0), t([r()], y.prototype, "viewpoint", void 0), y = t([m("esri.views.2d.AnimationManager")], y);
const x = y;
export { x as default };