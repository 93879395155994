import {Component, Input} from '@angular/core';
import {FormControl, FormRecord, UntypedFormControl, Validators} from '@angular/forms';
import {ProjectJobAnswer, ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormUtils} from '../../../utils/form-utils';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {AnyBundledQuestionChild, BundledQuestion} from '../../../models/question/bundled-question';
import {numberQuestionValidator} from '../../../validators/number-question.validator';

@Component({
    selector: 'app-question-v2-bundled',
    templateUrl: './question-v2-bundled.component.html',
    standalone: false
})
export class QuestionV2BundledComponent {
    private currentForm: AnyProjectJobForm | null = null;
    public currentQuestion: BundledQuestion | null = null;
    public currentNode: AnyLayeredFormNode | undefined = undefined;
    public doGoForward = new Subject<void>();

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.updateAnswer();
    }
    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNode = node;
        this.updateAnswer();
    }
    @Input({required: true}) set question(question: BundledQuestion) {
        this.currentQuestion = question;

        this.updateAnswer();
    }
    public disabled = false;
    public formGroup: FormRecord<UntypedFormControl> | null = null;

    constructor(private toastr: ToastrService) {
    }

    get isValid() {
        return this.formGroup?.valid || (this.isFormGroupEmpty() && this.currentQuestion?.required === false)
    }

    get currentValue(): ProjectJobAnswerValue {
        return {
            value: this.isFormGroupEmpty() ? '' : JSON.stringify(this.formGroup?.value),
            remarkText: null,
            remarkImage: null,
        }
    }

    showErrorToast() {
        this.toastr.error('Het antwoord van een verplichte vraag mag niet leeg zijn');
    }

    submit() {
        this.doGoForward.next();
    }

    private updateAnswer() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const latest = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);

        this.formGroup = this.buildFormGroup(this.currentQuestion, latest);
    }

    private buildFormGroup(bundledQuestion: BundledQuestion, answer: ProjectJobAnswer | null) {
        const formGroup = new FormRecord<UntypedFormControl>({});
        for (const question of bundledQuestion.config.questions) {
            const validators = question.required ? [Validators.required] : [];
            if (question.type === 'number') {
                validators.push(numberQuestionValidator(question.totalDecimal));
            }

            formGroup.registerControl(question.fieldName,
                new FormControl(
                    this.getInitialValue(question),
                    {
                        nonNullable: question.type !== 'choice',
                        validators,
                    }
                )
            );
        }

        if (answer && answer.value) {
            formGroup.patchValue(JSON.parse(answer.value));
        }

        return formGroup;
    }

    private getInitialValue(question: AnyBundledQuestionChild): unknown {
        if (question.type === 'text') {
            return '';
        } else if (question.type === 'number') {
            return '';
        } else if (question.type === 'choice') {
            return null;
        }

    }

    private isFormGroupEmpty() {
        const formValue = this.formGroup?.value || {};
        for (const key in formValue) {
            if (formValue[key] !== null && formValue[key] !== '') {
                return false;
            }
        }
        return true;
    }

    getSelectItems(question: AnyBundledQuestionChild) {
        if (question.type !== 'choice') {
            throw new Error('Only choice questions have select items');
        }

        return question.choices
    }
}
