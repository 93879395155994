import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {QUESTION_TEXT_MAX_LENGTH, QUESTION_TEXT_MULTILINE_MAX_LENGTH, TextQuestion} from '../../../models/question/text-question';
import {ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormUtils} from '../../../utils/form-utils';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-question-v2-text',
    templateUrl: './question-v2-text.component.html',
    standalone: false
})
export class QuestionV2TextComponent {
    private currentForm: AnyProjectJobForm | null = null;
    public currentQuestion: TextQuestion | null = null;
    public currentNode: AnyLayeredFormNode | undefined = undefined;
    public doGoForward = new Subject<void>();

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.updateAnswer();
    }
    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNode = node;
        this.updateAnswer();
    }
    @Input({required: true}) set question(question: TextQuestion) {
        this.currentQuestion = question;

        this.updateAnswer();
    }
    public disabled = false;
    public control = new FormControl('', {nonNullable: true});

    constructor(private toastr: ToastrService) {
    }

    get isValid() {
        return !this.currentQuestion?.required || (!!this.control.value && this.control.value.length <= this.maxLength);
    }

    get maxLength() {
        // 4500 characters for textarea field
        // 100 characters for input field
        return this.currentQuestion?.multiline ? QUESTION_TEXT_MULTILINE_MAX_LENGTH : QUESTION_TEXT_MAX_LENGTH;
    }

    get currentValue(): ProjectJobAnswerValue {
        return {
            value: this.control.value,
            remarkText: null,
            remarkImage: null,
        }
    }

    showErrorToast() {
        this.toastr.error('Het antwoord van een verplichte vraag mag niet leeg zijn');
    }

    submit() {
        this.doGoForward.next();
    }

    private updateAnswer() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const latest = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);
        if (latest) {
            this.control.setValue(latest?.value ?? '');
        } else if (this.currentQuestion.preFillEnabled) {
            this.control.setValue(this.currentQuestion.preFillValue);
        } else {
            this.control.reset();
        }
    }
}
