/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { clock as e } from "../../../core/clock.js";
import { removeMaybe as t } from "../../../core/maybe.js";
import { InputHandler as i } from "../InputHandler.js";
import { DefaultParameters as o, manhattanDistance as n, getPointerId as s } from "./support.js";
class l extends i {
  constructor(t = o.maximumDoubleClickDelay, i = o.maximumDoubleClickDistance, n = o.maximumDoubleTouchDelay, s = o.maximumDoubleTouchDistance, l = e) {
    super(!1), this._maximumDoubleClickDelay = t, this._maximumDoubleClickDistance = i, this._maximumDoubleTouchDelay = n, this._maximumDoubleTouchDistance = s, this._clock = l, this._pointerState = new Map(), this._click = this.registerOutgoing("click"), this._doubleClick = this.registerOutgoing("double-click"), this.registerIncoming("immediate-click", e => this._handleImmediateClick(e)), this.registerIncoming("pointer-down", e => this._handlePointerDown(e));
  }
  onUninstall() {
    this._pointerState.forEach(e => e.doubleClickTimer = t(e.doubleClickTimer));
  }
  get hasPendingInputs() {
    for (const e of this._pointerState.values()) if (null != e.doubleClickTimer) return !0;
    return !1;
  }
  _clearDoubleClickTimer(e, i) {
    const o = this._pointerState.get(e);
    o && (o.doubleClickTimer = t(o.doubleClickTimer), i && this._click.emit(o.event.data, void 0, o.event.modifiers), this._pointerState.delete(e), this.refreshHasPendingInputs());
  }
  _doubleClickTimeoutExceeded(e) {
    const t = this._pointerState.get(e);
    1 === t.pointerDownCount && this._click.emit(t.event.data, void 0, t.event.modifiers), t.doubleClickTimer = null, this._pointerState.delete(e), this.refreshHasPendingInputs();
  }
  _handleImmediateClick(e) {
    const t = e.data,
      {
        pointerType: i
      } = t.native,
      o = r(t);
    if (!this._pointerState.has(o)) return void this._startClick(e);
    const s = this._pointerState.get(o),
      {
        data: l,
        modifiers: c
      } = s.event,
      a = "touch" === i ? this._maximumDoubleTouchDistance : this._maximumDoubleClickDistance;
    n(l, t) > a ? (this._clearDoubleClickTimer(o, !0), this._startClick(e)) : (this._clearDoubleClickTimer(o, !1), 2 === s.pointerDownCount && this._doubleClick.emit(l, void 0, c));
  }
  _handlePointerDown(e) {
    const t = s(e.data),
      i = this._pointerState.get(t);
    i && (i.pointerDownCount += 1);
  }
  _startClick(e) {
    const {
        data: t
      } = e,
      {
        native: {
          pointerType: i
        }
      } = t,
      o = s(t),
      n = "touch" === i ? this._maximumDoubleTouchDelay : this._maximumDoubleClickDelay,
      l = this._clock.setTimeout(() => this._doubleClickTimeoutExceeded(o), n),
      r = 1;
    this._pointerState.set(o, {
      event: e,
      doubleClickTimer: l,
      pointerDownCount: r
    }), this.refreshHasPendingInputs();
  }
}
function r(e) {
  const {
    pointerId: t,
    pointerType: i,
    button: o
  } = e.native;
  return "mouse" === i ? `${t}:${o}` : `${i}`;
}
export { l as SingleAndDoubleClick };