/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import o from "../../../core/Collection.js";
import { defaultHighlightName as r, temporaryHighlightName as t, temporaryHighlightColor as e } from "../../support/HighlightDefaults.js";
import i from "../../support/HighlightOptions.js";
function n() {
  return new (o.ofType(i))([new i({
    name: r
  }), new i({
    name: t,
    color: e
  })]);
}
export { n as makeDefaultHighlightOptions };