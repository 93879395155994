import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {firstValueFrom, Observable} from 'rxjs';
import {temporaryDb} from '../../db/temporary-db';
import {db} from '../../db/db';
import type JSZip from 'jszip';
import {AppInsightsService} from './app-insights.service';
import {AuthenticationService} from '../authentication.service';
import {App} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';
import {Device} from '@capacitor/device';

@Injectable({
    providedIn: 'root'
})
export class AppStateUploader {
    constructor(
        private client: HttpClient,
        private applicationInsightsService: AppInsightsService,
        private authenticationService: AuthenticationService
    ) {
    }

    async automaticUploadState() {
        try {
            // Upload state to App Insights, throttled to at most once every day
            const lastUpload = localStorage.getItem('lastUpload');
            if (lastUpload) {
                const lastUploadDate = new Date(lastUpload);
                const now = new Date();
                if (lastUploadDate.getDate() === now.getDate() && lastUploadDate.getMonth() === now.getMonth() && lastUploadDate.getFullYear() === now.getFullYear()) {
                    // Already uploaded today
                    return;
                }
            }

            const stateFileUrl = await this.uploadState();
            this.applicationInsightsService.logEvent('AutomaticBugReported', {
                stateBlobUrl: stateFileUrl,
                userEmail: this.authenticationService.loggedInEmail$.value,
            })

            localStorage.setItem('lastUpload', new Date().toISOString());
        } catch (e) {
            console.error('Failed to automatically upload state to App Insights', e);
        }
    }

    async reportBug(description: string) {
        try {
            const userEmail = this.authenticationService.loggedInEmail$.value
            const stateFileUrl = await this.uploadState();

            const bugReport = {
                stateBlobUrl: stateFileUrl,
                description,
                userEmail,
                appInfo: await App.getInfo(),
                deviceInfo: await Device.getInfo(),
            }

            this.applicationInsightsService.logEvent('BugReported', bugReport);

            await firstValueFrom(this.client.post('/app-api/v1/bug-report', bugReport));
        } catch (e) {
            console.error('Failed to report bug', e);
        }
    }

    async uploadState(): Promise<string | null> {
        try {
            const startTime = new Date();
            console.warn('Uploading app state to App Insights');
            const debugSas = await firstValueFrom(this.getDebugSas());
            // Build zip file with state
            const zip = new (await import('jszip')).default;
            this.addLocalStorageState(zip);
            await this.addTemporaryAppDBState(zip);
            await this.addAppDBState(zip);
            await this.addInfoFile(zip);

            const blob = await zip.generateAsync({type: 'blob'});

            await firstValueFrom(this.client.put(debugSas, blob, {
                headers: {
                    'Content-Type': 'application/zip',
                    'x-ms-blob-type': 'BlockBlob'
                }
            }));

            const endTime = new Date();
            console.warn(`Uploaded app state to App Insights in ${endTime.getTime() - startTime.getTime()}ms`);

            // Return the debug file path without the query string containing SAS
            return debugSas.split('?')[0];
        } catch (e) {
            console.error('Failed to upload app state to App Insights', e);

            return null;
        }
    }

    private addLocalStorageState(zip: JSZip) {
        try {
            const localStorageFolder = zip.folder('localStorage');
            if (!localStorageFolder) {
                throw new Error('Failed to create folder');
            }

            for (const key of Object.keys(localStorage)) {
                if (key === 'refreshToken' || key === 'accessToken') {
                    // Skip sensitive data
                    continue;
                }

                localStorageFolder.file(`${key}.json`, localStorage.getItem(key) || '{null}');
            }
        } catch (e) {
            console.error('Failed to add localstorage state', e);
        }
    }

    private async addInfoFile(zip: JSZip) {
        const info = await App.getInfo();

        zip.file('info.json', JSON.stringify({
            ...info,
            platform: Capacitor.getPlatform(),
            userEmail: this.authenticationService.loggedInEmail$.value,
        }));
    }

    private async addTemporaryAppDBState(zip: JSZip) {
        try {
            for (const table of temporaryDb.tables) {
                const tableFolder = zip.folder(table.name);
                if (!tableFolder) {
                    throw new Error('Failed to create folder');
                }

                await table.each((item, cursor) => {
                    const key = cursor.primaryKey.toString();
                    tableFolder.file(`${key}.json`, JSON.stringify(item));
                })
            }

        } catch (e) {
            console.error('Failed to add app db state', e);
        }
    }

    private async addAppDBState(zip: JSZip) {
        try {
            for (const table of db.tables) {
                const tableFolder = zip.folder(table.name);
                if (!tableFolder) {
                    throw new Error('Failed to create folder');
                }

                await table.each((item, cursor) => {
                    const key = cursor.primaryKey.toString();
                    tableFolder.file(`${key}.json`, JSON.stringify(item));
                })
            }

        } catch (e) {
            console.error('Failed to add app db state', e);
        }
    }

    private getDebugSas(): Observable<string> {
        return this.client.post(`/app-api/v1/bug-report/generate-upload-sas`, null, {
            responseType: 'text'
        })
    }
}
