/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import { prefersReducedMotion as e } from "../../core/a11yUtils.js";
import t from "../../core/Accessor.js";
import { deprecatedProperty as r } from "../../core/deprecate.js";
import a from "../../core/Logger.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/RandomLCG.js";
import { subclass as m } from "../../core/accessorSupport/decorators/subclass.js";
import n from "./NavigationActionMap.js";
import p from "./gamepad/GamepadSettings.js";
let i = class extends t {
  constructor(o) {
    super(o), this.actionMap = new n(), this.browserTouchPanEnabled = !0, this.gamepad = new p(), this.momentumEnabled = !0;
  }
  get effectiveMomentumEnabled() {
    return this.momentumEnabled && !e();
  }
  get mouseWheelZoomEnabled() {
    return "zoom" === this.actionMap.mouseWheel;
  }
  set mouseWheelZoomEnabled(o) {
    r(a.getLogger(this), "mouseWheelZoomEnabled", {
      replacement: "actionMap.mouseWheel",
      version: "4.32",
      warnOnce: !0
    }), this.actionMap.mouseWheel = o ? "zoom" : "none";
  }
};
o([s({
  type: n,
  nonNullable: !0
})], i.prototype, "actionMap", void 0), o([s({
  type: Boolean
})], i.prototype, "browserTouchPanEnabled", void 0), o([s({
  type: p,
  nonNullable: !0
})], i.prototype, "gamepad", void 0), o([s({
  type: Boolean
})], i.prototype, "momentumEnabled", void 0), o([s({
  type: Boolean
})], i.prototype, "mouseWheelZoomEnabled", null), i = o([m("esri.views.navigation.Navigation")], i);
const l = i;
export { l as default };