import {Component} from '@angular/core';
import {LogoutConfirmationModalComponent} from '../logout-confirmation-modal/logout-confirmation-modal.component';
import {PopupService} from '../../services/popup.service';

@Component({
    selector: 'app-logout-button',
    templateUrl: './logout-button.component.html',
    standalone: false
})

export class LogoutButtonComponent {

    constructor(
        private popupService: PopupService
    ) {
    }

    confirmLogout() {
        this.popupService.open(LogoutConfirmationModalComponent, {});
    }
}
