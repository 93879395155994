/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import { prefersReducedMotion as o } from "../../core/a11yUtils.js";
import e from "../../core/Accessor.js";
import i from "../../core/Error.js";
import r from "../../core/Logger.js";
import { throwIfAborted as s, whenOrAbort as a } from "../../core/promiseUtils.js";
import { whenOnce as n } from "../../core/reactiveUtils.js";
import { property as c } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/RandomLCG.js";
import { subclass as m } from "../../core/accessorSupport/decorators/subclass.js";
import p from "../ViewAnimation.js";
import { create as l } from "./viewpointUtils.js";
let h = class extends e {
  constructor(t) {
    super(t), this._gotoTask = null;
  }
  destroy() {
    this._gotoTask = null;
  }
  async goTo(t, e) {
    if (!t) return void r.getLogger(this).error("#goTo()", "target cannot be null or undefined");
    const i = new p();
    this.view.animation = i, await n(() => this.view.ready, e);
    const s = {
        ...e,
        animate: e?.animate ?? !o(),
        animationMode: e?.animationMode ?? "auto"
      },
      {
        extent: a,
        spatialReference: c,
        size: m,
        viewpoint: h,
        constraints: g,
        padding: w,
        allLayerViews: d
      } = this.view,
      u = l(t, {
        extent: a,
        spatialReference: c,
        size: m,
        viewpoint: h,
        constraints: g,
        padding: w,
        allLayerViews: d,
        pickClosestTarget: e?.pickClosestTarget ?? !0
      });
    return i?.update(u), this._gotoTask = {}, s.animate ? this._gotoAnimated(u, s) : this._gotoImmediate(u, s);
  }
  _gotoImmediate(t, o) {
    const e = this._gotoTask,
      r = this.view.animation,
      a = t.then(t => {
        if (s(o), e !== this._gotoTask) throw new i("view:goto-interrupted", "Goto was interrupted");
        this.view.viewpoint = r.target = t, r.finish();
      });
    return this._cancellableGoTo(e, r, a, o);
  }
  _gotoAnimated(t, o) {
    const e = this._gotoTask,
      r = this.view.animation;
    if (!r) return Promise.resolve();
    const a = t.then(t => {
      if (s(o), e !== this._gotoTask) throw new i("view:goto-interrupted", "Goto was interrupted");
      return r.update(t), this.view.animationManager.animate(r, this.view.viewpoint, o), r.when().then(() => {}, () => {});
    });
    return this._cancellableGoTo(e, r, a, o);
  }
  _cancellableGoTo(t, o, e, i) {
    const r = () => t === this._gotoTask;
    return a(e, i).finally(() => {
      r() && (o.done || o.stop());
    });
  }
};
t([c({
  constructOnly: !0
})], h.prototype, "view", void 0), h = t([m("esri.views.2d.GoToManager")], h);
export { h as GoToManager };