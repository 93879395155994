/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { createScreenPoint as t } from "../../../core/screenUtils.js";
import { Milliseconds as e } from "../../../core/time.js";
import { InputHandler as i } from "../InputHandler.js";
import { fitCircleLSQ as n } from "./support.js";
class s extends i {
  constructor(t) {
    super(!1), this._navigationTouch = t, this._startStateModifiers = new Set(), this._activePointerMap = new Map(), this._isDragging = !1, this._isCurrentDragSuppressed = !1, this._drag = this.registerOutgoing("drag"), this.registerIncoming("pointer-drag", this._handlePointerDrag.bind(this)), this.registerIncoming("pointer-up", this._handlePointerUpAndPointerLost.bind(this)), this.registerIncoming("pointer-capture-lost", this._handlePointerUpAndPointerLost.bind(this)), this.registerIncoming("pointer-cancel", this._handlePointerUpAndPointerLost.bind(this));
  }
  _createPayload(t, e, i, n) {
    return {
      action: t,
      pointerType: this._pointerType,
      button: this._mouseButton,
      buttons: e.buttons,
      timestamp: n,
      pointers: o(this._activePointerMap),
      pointer: e,
      angle: i.angle,
      radius: i.radius,
      center: i.center
    };
  }
  _addPointer(t) {
    const e = t.native.pointerId,
      i = a(this._activePointerMap).angle,
      n = {
        event: t,
        initialAngle: 0,
        lastAngle: 0
      };
    this._activePointerMap.set(e, n);
    const s = h(n, r(this._activePointerMap));
    n.initialAngle = s, n.lastAngle = s, this._updatePointerAngles(i);
  }
  _updatePointer(t) {
    if (t && null == t.x && null == t.y) return;
    const e = t.native.pointerId,
      i = this._activePointerMap.get(e);
    i ? i.event = t : this._addPointer(t);
  }
  _removePointer(t) {
    const e = a(this._activePointerMap).angle;
    this._activePointerMap.delete(t), this._updatePointerAngles(e);
  }
  _updatePointerAngles(t) {
    const e = a(this._activePointerMap);
    this._activePointerMap.forEach(i => {
      i.initialAngle = h(i, e) - t, i.lastAngle = h(i, e) - t;
    });
  }
  _emitEvent(t, e, i) {
    const n = a(this._activePointerMap);
    this._drag.emit(this._createPayload(t, e, n, i), void 0, this._startStateModifiers);
  }
  _handlePointerUpAndPointerLost(t) {
    const i = t.data.native.pointerId,
      n = e(t.timestamp);
    this._activePointerMap.get(i) && (1 === this._activePointerMap.size ? (this._updatePointer(t.data), !this._isCurrentDragSuppressed && this._emitEvent("end", t.data, n), this._isDragging = !1, this._isCurrentDragSuppressed = !1, this._removePointer(i)) : (this._removePointer(i), this._emitEvent("removed", t.data, e(t.timestamp))));
  }
  _handlePointerDrag(t) {
    const i = t.data,
      n = i.currentEvent,
      s = e(t.timestamp);
    switch (i.action) {
      case "start":
      case "update":
        this._isDragging ? this._activePointerMap.has(n.native.pointerId) ? (this._updatePointer(n), !this._isCurrentDragSuppressed && this._emitEvent("update", n, s)) : (this._addPointer(n), this._emitEvent("added", n, s), this._isCurrentDragSuppressed = this._isSuppressed) : (this._updatePointer(n), this._pointerType = t.data.startEvent.pointerType, this._mouseButton = t.data.startEvent.button, this._startStateModifiers = t.modifiers, this._isDragging = !0, this._isCurrentDragSuppressed = this._isSuppressed, !this._isCurrentDragSuppressed && this._emitEvent("start", n, s));
    }
  }
  get _isSuppressed() {
    return !!this._navigationTouch && !this._navigationTouch.browserTouchPanEnabled && "touch" === this._pointerType && 1 === this._activePointerMap.size;
  }
}
function r(e) {
  const i = [];
  return e.forEach(e => {
    i.push(t(e.event.x, e.event.y));
  }), n(i);
}
function a(t) {
  const e = r(t);
  let i = 0;
  return t.forEach(t => {
    let n = h(t, e),
      s = n - t.lastAngle;
    for (; s > Math.PI;) s -= 2 * Math.PI;
    for (; s < -Math.PI;) s += 2 * Math.PI;
    n = t.lastAngle + s, t.lastAngle = n;
    const r = n - t.initialAngle;
    i += r;
  }), i /= t.size || 1, {
    angle: i,
    radius: e.radius,
    center: e.center
  };
}
function o(t) {
  const e = new Map();
  return t.forEach((t, i) => e.set(i, t.event)), e;
}
function h(t, e) {
  const i = t.event,
    n = i.x - e.center.x,
    s = i.y - e.center.y;
  return Math.atan2(s, n);
}
var p;
!function (t) {
  t[t.Left = 0] = "Left", t[t.Middle = 1] = "Middle", t[t.Right = 2] = "Right", t[t.Back = 3] = "Back", t[t.Forward = 4] = "Forward", t[t.Undefined = -1] = "Undefined";
}(p || (p = {}));
export { p as Button, s as Drag };