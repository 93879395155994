/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import e from "../Ground.js";
import r from "../core/Logger.js";
import { ensureType as o } from "../core/accessorSupport/ensureType.js";
const i = {
  "world-elevation": {
    id: "worldElevation",
    url: "//elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer",
    layerType: "ArcGISTiledElevationServiceLayer"
  },
  "world-topobathymetry": {
    id: "worldTopoBathymetry",
    url: "//elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/TopoBathy3D/ImageServer",
    layerType: "ArcGISTiledElevationServiceLayer"
  }
};
function t(t) {
  let a = null;
  if ("string" == typeof t) {
    if (t in i) {
      const r = i[t];
      a = new e({
        resourceInfo: {
          data: {
            layers: [r]
          }
        }
      });
    } else r.getLogger("esri.support.groundUtils").warn(`Unable to find ground definition for: ${t}. Try "world-elevation"`);
  } else a = o(e, t);
  return a;
}
export { t as ensureType, i as groundElevationLayers };