import {Component} from '@angular/core';
import {AnyLayeredFormNode} from '../../models/layered-form-node';
import {PopupRef} from '../../helpers/popup-ref';
import {PopupConfig} from '../../models/popup-config';
import {NodeService} from '../../services/node.service';
import {firstValueFrom} from 'rxjs';
import {createCriticalErrorOverlay} from '../../utils/create-critical-error-overlay';
import {FormService} from '../../services/form.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-delete-layered-node-popup',
    templateUrl: './delete-layered-node-popup.component.html',
    standalone: false
})
export class DeleteLayeredNodePopupComponent {
    public readonly node?: AnyLayeredFormNode;
    public readonly tree: AnyLayeredFormNode[] = [];
    public readonly form$ = this.formService.openForm$;
    public layerName$ = this.form$.pipe(
        map(form => {
            if (!form) {
                return '';
            }

            if (form?.type === 'jobForm') {
                throw new Error('Form is not a layered job form');
            }
            const node = this.node;
            if (node?.type === 'subtype') {
                const subtypeLayer = form.layers.find(layer =>
                    layer.type === 'subtypeLayer' && layer.subtypes.some(subtype => subtype.id === node.subtype.id)
                );
                if (!subtypeLayer) {
                    throw new Error(`Subtype layer not found for subtype id ${node.subtype.id}`);
                }
                return subtypeLayer.name;
            } else if (node?.type === 'database') {
                return form.layers.find(layer => layer.id === node.layer)?.name ?? '';
            }
        })
    );

    constructor(private popupRef: PopupRef, private formService: FormService, {
        data
    }: PopupConfig<{ node: AnyLayeredFormNode, tree: AnyLayeredFormNode[] }>, private nodeService: NodeService) {
        if (data) {
            this.node = data.node;
            this.tree = data.tree;
        }
    }

    async confirm() {
        if (!this.canDelete || !this.node) {
            return;
        }

        const form = await firstValueFrom(this.form$);
        if (!form) {
            createCriticalErrorOverlay('Form not found');
            return;
        }
        if (form.type !== 'layeredJobForm') {
            throw new Error('Form is not a layered job form');
        }

        await this.nodeService.deleteNode(form, this.node);

        this.popupRef.close(true);
    }

    cancel() {
        this.popupRef.close(false);
    }

    get canDelete(): boolean {
        return !this.tree.some(node => node.parent === this.node?.id);
    }
}
