/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import r from "../Color.js";
import e from "../core/Accessor.js";
import s from "../core/Clonable.js";
import { property as t } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as c } from "../core/accessorSupport/decorators/subclass.js";
let p = class extends s.ClonableMixin(e) {
  constructor(o) {
    super(o), this.accentColor = new r([255, 127, 0]), this.textColor = new r([255, 255, 255]);
  }
};
o([t({
  type: r,
  nonNullable: !0
})], p.prototype, "accentColor", void 0), o([t({
  type: r,
  nonNullable: !0
})], p.prototype, "textColor", void 0), p = o([c("esri.views.Theme")], p);
const l = p;
export { l as default };