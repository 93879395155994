<div class="question-type" [class.is-disabled]="disabled">
    @if (label) {
        <label [id]="inputId" class="input-label">
            {{ label }}
            @if (required) {
                <span class="text-danger">*</span>
            }
        </label>
    }
    @if (isIOS) {
        <input [id]="inputId"
               type="text"
               inputmode="decimal"
               [disabled]="disabled"
               [ngModel]="value"
               (ngModelChange)="value = doConvertToNumber($event)"
        />
    } @else {
        <input [id]="inputId"
               type="number"
               [disabled]="disabled"
               [ngModel]="value"
               (keydown)="validateAllowedKeys($event)"
               (input)="updateValueFromInputEvent($event)"
        />
    }
</div>
