/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import r from "../core/JSONSupport.js";
import { property as e } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { enumeration as s } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as t } from "../core/accessorSupport/decorators/subclass.js";
var p;
let c = p = class extends r {
  constructor(o) {
    super(o), this.type = "none";
  }
  clone() {
    return new p({
      type: this.type
    });
  }
};
o([s({
  none: "none",
  stayAbove: "stay-above"
}), e({
  json: {
    write: {
      isRequired: !0
    }
  }
})], c.prototype, "type", void 0), c = p = o([t("esri.ground.NavigationConstraint")], c);
export { c as NavigationConstraint };