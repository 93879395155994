/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { clock as t } from "../../../core/clock.js";
import { removeMaybe as e } from "../../../core/maybe.js";
import { InputHandler as i } from "../InputHandler.js";
import { DefaultParameters as n, euclideanDistance as o } from "./support.js";
class r extends i {
  constructor(e = n.maximumClickDelay, i = n.movementUntilMouseDrag, o = n.movementUntilPenDrag, r = n.movementUntilTouchDrag, s = n.holdDelay, a = t) {
    super(!1), this._maximumClickDelay = e, this._movementUntilMouseDrag = i, this._movementUntilPenDrag = o, this._movementUntilTouchDrag = r, this._holdDelay = s, this._clock = a, this._pointerState = new Map(), this._pointerDrag = this.registerOutgoing("pointer-drag"), this._immediateClick = this.registerOutgoing("immediate-click"), this._pointerHold = this.registerOutgoing("hold"), this.registerIncoming("pointer-down", t => this._handlePointerDown(t)), this.registerIncoming("pointer-up", t => this._handlePointerLoss(t, "pointer-up")), this.registerIncoming("pointer-capture-lost", t => this._handlePointerLoss(t, "pointer-capture-lost")), this.registerIncoming("pointer-cancel", t => this._handlePointerLoss(t, "pointer-cancel")), this._moveHandle = this.registerIncoming("pointer-move", t => this._handlePointerMove(t)), this._moveHandle.pause();
  }
  onUninstall() {
    this._pointerState.forEach(t => {
      t.holdTimeout = e(t.holdTimeout);
    }), super.onUninstall();
  }
  _handlePointerDown(t) {
    const e = t.data,
      i = e.native.pointerId;
    let n = null;
    0 === this._pointerState.size && (n = this._clock.setTimeout(() => {
      const e = this._pointerState.get(i);
      if (e) {
        if (!e.isDragging) {
          const i = e.previousEvent;
          this._pointerHold.emit(i, void 0, t.modifiers), e.holdEmitted = !0;
        }
        e.holdTimeout = null;
      }
    }, this._holdDelay));
    const o = {
      startEvent: e,
      previousEvent: e,
      startTimestamp: t.timestamp,
      isDragging: !1,
      downButton: e.native.button,
      holdTimeout: n,
      modifiers: new Set()
    };
    this._pointerState.set(i, o), this.startCapturingPointer(e.native), this._moveHandle.resume(), this._pointerState.size > 1 && this._startDragging(t);
  }
  _handlePointerMove(t) {
    const e = t.data,
      i = e.native.pointerId,
      n = this._pointerState.get(i);
    if (n) {
      if (n.isDragging) this._pointerDrag.emit(s("update", n, e), void 0, n.modifiers);else {
        o(e, n.startEvent) > this._getDragThreshold(e.native.pointerType) && this._startDragging(t);
      }
      n.previousEvent = e;
    }
  }
  _getDragThreshold(t) {
    switch (t) {
      case "touch":
        return this._movementUntilTouchDrag;
      case "pen":
        return this._movementUntilPenDrag;
      default:
        return this._movementUntilMouseDrag;
    }
  }
  _startDragging(t) {
    const e = t.data,
      i = e.native.pointerId;
    this._pointerState.forEach(n => {
      null != n.holdTimeout && (n.holdTimeout.remove(), n.holdTimeout = null), n.isDragging || (n.modifiers = t.modifiers, n.isDragging = !0, i === n.startEvent.native.pointerId ? this._pointerDrag.emit(s("start", n, e)) : this._pointerDrag.emit(s("start", n, n.previousEvent), t.timestamp));
    });
  }
  _handlePointerLoss(t, e) {
    const i = t.data,
      n = i.native.pointerId,
      o = this._pointerState.get(n);
    if (o) {
      if (null != o.holdTimeout && (o.holdTimeout.remove(), o.holdTimeout = null), o.isDragging) this._pointerDrag.emit(s("end", o, "pointer-up" === e ? i : o.previousEvent), void 0, o.modifiers);else if ("pointer-up" === e && o.downButton === i.native.button) {
        t.timestamp - o.startTimestamp <= this._maximumClickDelay && !o.holdEmitted && this._immediateClick.emit(i);
      }
      this._pointerState.delete(n), this.stopCapturingPointer(i.native), 0 === this._pointerState.size && this._moveHandle.pause();
    }
  }
}
function s(t, e, i) {
  return {
    action: t,
    startEvent: e.startEvent,
    previousEvent: e.previousEvent,
    currentEvent: i
  };
}
export { r as PointerClickHoldAndDrag };