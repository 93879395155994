import {
    Component,
    ElementRef, EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {v4 as uuid} from 'uuid';
import {Shape} from 'konva/lib/Shape';
import {Stage} from 'konva/lib/Stage';
import {Layer} from 'konva/lib/Layer';
import {Image as KonvaImage} from 'konva/lib/shapes/Image';
import {KonvaEventObject} from 'konva/lib/Node';
import {Line} from 'konva/lib/shapes/Line';
import {Rect} from 'konva/lib/shapes/Rect';
import {Circle} from 'konva/lib/shapes/Circle';
import {Arrow} from 'konva/lib/shapes/Arrow';

export type DrawingTools = 'pencil' | 'circle' | 'square' | 'arrow';
export interface HistoryItem {
    type: 'add';
    shape: Shape;
}

@Component({
    selector: 'app-image-annotation',
    templateUrl: './image-annotation.component.html',
    standalone: false
})
export class ImageAnnotationComponent implements OnChanges, OnDestroy {
    @ViewChild('stageContainer', {static: true}) stageContainer: ElementRef | null = null;
    @ViewChild('hostContainer', {static: true}) hostContainer: ElementRef | null = null;

    @Input() imageSrc: string | null = null;

    @Input() visible = true;

    @Output() cancelAnnotation = new EventEmitter<void>();
    @Output() save = new EventEmitter<string>();

    private stage: Stage | null = null;
    private shapeLayer: Layer | null = null;
    private drawLayer: Layer | null = null;

    private imageWidth: number | null = null;
    private imageHeight: number | null = null;

    palette = [
        '#ffde40',
        '#b7e52d',
        '#06cdd2',
        '#3398ef',
        '#ffb42b',
        '#ff5454',
        '#efefef',
        '#999999',
        '#434343'
    ];
    currentTool: DrawingTools = 'pencil';
    currentColor: string = this.palette[5]; // red is the default color

    private isDrawing = false;

    private currentShape: Shape | null = null;
    private currentShapeStart: { x: number, y: number } | null = null;

    history: HistoryItem[] = [];
    historyStep = 0;

    constructor(private zone: NgZone) {
    }

    ngOnDestroy(): void {
        this.destroyStage();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (this.stage) {
            this.fitStageIntoParentContainer();
        }
    }

    doCancel() {
        this.cancelAnnotation.emit();
    }

    doSave() {
        if (!this.stage) {
            throw new Error('Stage not initialized');
        }
        if (!this.imageWidth || !this.imageHeight) {
            throw new Error('Image dimensions not initialized');
        }

        const origStageSize = this.stage.size();
        const origStageScale = this.stage.scale();

        this.stage.size({width: this.imageWidth, height: this.imageHeight});
        this.stage.scale({x: 1, y: 1});
        this.stage.draw();

        this.save.emit(this.stage.toDataURL({
            mimeType: 'image/jpeg'
        }));

        this.stage.size(origStageSize);
        this.stage.scale(origStageScale);
        this.stage.draw();
    }

    undo() {
        if (this.historyStep === 0 || !this.shapeLayer) {
            console.warn('cant undo');
            return;
        }

        this.historyStep--;
        const item = this.history[this.historyStep];

        if (item.type === 'add' && item.shape.id()) {
            const shape = this.shapeLayer.findOne(`#${item.shape.id()}`);
            if (shape) {
                shape.remove();
            } else {
                console.warn('Couldnt find shape with id ' + item.shape.id());
            }
        } else {
            console.error('unknown history item', item);
        }
    }

    redo() {
        if (this.historyStep === this.history.length || !this.shapeLayer) {
            console.warn('Cant redo');
            return;
        }
        const item = this.history[this.historyStep];
        this.historyStep++;

        if (item.type === 'add' && item.shape.id()) {
            this.shapeLayer.add(item.shape);
        } else {
            console.error('unknown history item', item);
        }
    }

    pushHistory(item: HistoryItem) {
        this.zone.run(() => {
            const removed = this.history.splice(this.historyStep, this.history.length - this.historyStep, item);
            this.historyStep++;
            removed.forEach(it => it.shape.destroy());
        });
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (this.imageSrc) {
            if (this.stage) {
                this.destroyStage();
            }
            const image = await this.getLoadedImage();
            this.createStage(image.width, image.height);
            this.addImageLayer(image);
            this.addShapeLayer();
            this.addDrawLayer();
        }
    }

    private createStage(width: number, height: number) {
        this.zone.runOutsideAngular(() => {
            if (this.stage) {
                throw new Error('Stage already created');
            }
            if (!this.stageContainer) {
                throw new Error('Stage container not initialized');
            }

            this.imageWidth = width;
            this.imageHeight = height;

            this.stage = new Stage({
                container: this.stageContainer.nativeElement,
                width: this.imageWidth,
                height: this.imageHeight
            });

            this.fitStageIntoParentContainer();

            this.stage.on('mousedown touchstart', this.onTouchStart.bind(this));
            this.stage.on('mousemove touchmove', this.onTouchMove.bind(this));
            this.stage.on('mouseup touchend mouseleave', this.onTouchEnd.bind(this));
        });
    }

    private fitStageIntoParentContainer() {
        if (!this.stage || !this.stageContainer || !this.hostContainer) {
            console.warn('stage not initialized yet');
            return;
        }
        if (!this.imageWidth || !this.imageHeight) {
            console.warn('image dimensions not initialized yet');
            return;
        }

        const containerWidth = this.stageContainer.nativeElement.offsetWidth;
        let availableHeight = this.hostContainer.nativeElement.offsetHeight;
        const header = this.hostContainer.nativeElement.querySelector('.image-annotation__header');
        const footer = this.hostContainer.nativeElement.querySelector('.image-annotation__footer');
        if (header) {
            availableHeight -= header.offsetHeight;
        }
        if (footer) {
            availableHeight -= footer.offsetHeight;
        }

        const scale = Math.min(
            containerWidth / this.imageWidth,
            availableHeight / this.imageHeight
        );

        const newScale = this.stage.scale();
        const scaleChanged = newScale && newScale.x !== scale;
        this.stage.width(this.imageWidth * scale);
        this.stage.height(this.imageHeight * scale);
        this.stage.scale({x: scale, y: scale});
        this.stage.draw();
        if (scaleChanged) {
            setTimeout(() => this.fitStageIntoParentContainer());
        }
    }

    private addImageLayer(imageSrc: HTMLImageElement) {
        if (!this.stage) {
            throw new Error('Stage not initialized');
        }

        const layer = new Layer({
            listening: false
        });
        const image = new KonvaImage({
            x: 0,
            y: 0,
            width: imageSrc.width,
            height: imageSrc.height,
            image: imageSrc,
            perfectDrawEnabled: false,
            listening: false
        });

        // Add the image to the layer
        layer.add(image);

        // Add the layer to the stage
        this.stage.add(layer);
    }

    private addShapeLayer() {
        if (!this.stage) {
            throw new Error('Stage not initialized');
        }

        this.shapeLayer = new Layer({
            listening: false
        });
        this.stage.add(this.shapeLayer);
    }

    private addDrawLayer() {
        if (!this.stage) {
            throw new Error('Stage not initialized');
        }

        this.drawLayer = new Layer({
            listening: false
        });
        this.stage.add(this.drawLayer);
    }

    private destroyStage() {
        if (this.stage) {
            this.stage.off('mousedown touchstart');
            this.stage.off('mousemove touchmove');
            this.stage.off('mouseup touchend mouseleave');
            this.stage.destroy();
            this.stage = null;
        }
        if (this.history) {
            this.history.forEach(it => it.shape.destroy());
            this.history = [];
            this.historyStep = 0;
        }
    }

    private onTouchStart() {
        if (!this.drawLayer) {
            throw new Error('Draw layer not initialized');
        }

        try {
            this.currentShape = this.getNewShape();
            this.currentShapeStart = this.getPointerPosition();
            this.drawLayer.add(this.currentShape);
            this.isDrawing = true;
        } catch (e) {
            console.error('TouchStart failed', e);
        }
    }

    private onTouchMove(event: KonvaEventObject<TouchEvent>) {
        if (!this.drawLayer) {
            throw new Error('Draw layer not initialized');
        }

        event.evt.preventDefault()

        if (this.isDrawing) {
            try {
                this.updateShape();
            } catch (e) {
                console.error('TouchMove failed', e);
            }
        }
    }

    private onTouchEnd() {
        if (this.isDrawing && this.currentShape && this.shapeLayer && this.drawLayer) {
            this.isDrawing = false;
            this.currentShape.moveTo(this.shapeLayer);
            this.pushHistory({type: 'add', shape: this.currentShape});
        }
    }

    private getNewShape() {
        const {x, y} = this.getPointerPosition();
        switch (this.currentTool) {
            case 'pencil':
                return new Line({
                    id: uuid(),
                    points: [x, y],
                    stroke: this.currentColor,
                    strokeWidth: 5,
                    perfectDrawEnabled: false,
                });
            case 'square':
                return new Rect({
                    x, y, id: uuid(),
                    width: 1,
                    height: 1,
                    stroke: this.currentColor,
                    strokeWidth: 5,
                    perfectDrawEnabled: false,
                });
            case 'circle':
                return new Circle({
                    x, y, id: uuid(),
                    radius: 1,
                    stroke: this.currentColor,
                    strokeWidth: 5,
                    perfectDrawEnabled: false,
                });
            case 'arrow':
                return new Arrow({
                    id: uuid(),
                    fill: this.currentColor,
                    stroke: this.currentColor,
                    strokeWidth: 5,
                    points: [x, y],
                    pointerLength: 20,
                    pointerWidth: 20,
                    perfectDrawEnabled: false,
                });
            default:
                throw new Error(`Unsupported tool ${this.currentTool}`);
        }
    }
    private updateShape() {
        if (!this.currentShapeStart) {
            throw new Error('Current shape start not initialized');
        }

        const {x, y} = this.getPointerPosition();
        switch (this.currentTool) {
            case 'pencil': {
                const line = this.currentShape as Line;
                line.points(line.points().concat([x, y]));
                break;
            }
            case 'square': {
                const rect = this.currentShape as Rect;
                rect.x(Math.min(this.currentShapeStart.x, x));
                rect.y(Math.min(this.currentShapeStart.y, y));
                rect.width(Math.abs(this.currentShapeStart.x - x));
                rect.height(Math.abs(this.currentShapeStart.y - y));
                break;
            }
            case 'circle': {
                const circle = this.currentShape as Circle;
                circle.radius(Math.sqrt(
                    (Math.abs(this.currentShapeStart.x - x) ** 2)
                    + (Math.abs(this.currentShapeStart.y - y) ** 2))
                );
                break;
            }
            case 'arrow': {
                const arrow = this.currentShape as Arrow;
                arrow.points([this.currentShapeStart.x, this.currentShapeStart.y, x, y]);
                break;
            }
            default:
                throw new Error(`Unsupported tool ${this.currentTool}`);
        }
    }

    private getPointerPosition() {
        if (!this.stage) {
            throw new Error('Stage not initialized');
        }

        const transform = this.stage.getAbsoluteTransform().copy();

        transform.invert();

        const position = this.stage.getPointerPosition();

        if (!position) {
            throw new Error('Pointer position not initialized');
        }

        return transform.point(position);
    }

    private async getLoadedImage() {
        const imageSrc = this.imageSrc
        if (!imageSrc) {
            throw new Error('Image src not initialized');
        }

        return new Promise<HTMLImageElement>(((resolve, reject) => {
            const imageElement = new Image();
            imageElement.crossOrigin = 'anonymous';
            imageElement.onload = () => resolve(imageElement);
            imageElement.onerror = err => reject(err);
            imageElement.src = imageSrc;
        }));
    }
}
