import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy
} from '@angular/core';
import {PointMapCoords} from '../../point-map/point-map.component';
import {AbstractControl, AsyncValidatorFn, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, combineLatest, defer, firstValueFrom, merge, Observable, of, Subject, Subscription} from 'rxjs';
import {filter, map, startWith, take, timeout} from 'rxjs/operators';
import {Geolocation} from '@capacitor/geolocation';
import {Position as GeolocationPosition} from '@capacitor/geolocation/dist/esm/definitions';
import {ToastrService} from 'ngx-toastr';
import {FormUtils} from '../../../utils/form-utils';
import {FormService} from '../../../services/form.service';
import {extractLocationAnswer, ProjectJobLocationAnswer} from '../../../models/project-job-form-extra';
import {AnyProjectJobForm, FORM_START_LOCATION_QUESTION_POSITION} from '../../../models/project-job-form';
import {ActivatedRoute} from '@angular/router';
import {paramMapGetNumberOrFail} from '../../../utils/param-map-util';

@Component({
    selector: 'app-question-v2-job-location',
    templateUrl: './question-v2-job-location.component.html',
    standalone: false
})
export class QuestionV2JobLocationComponent implements AfterViewInit, OnDestroy  {
    public static DEFAULT_CENTER_LOCATION: PointMapCoords = {longitude: 5.3878266, latitude: 52.1561113};
    public doGoForward = new Subject<void>();

    form = new FormGroup({
        locationFrom: new FormControl<string>("", {
            nonNullable: true,
            validators: [Validators.maxLength(255)]
        }),
        locationTo: new FormControl<string>("", {
            nonNullable: true,
            validators: [Validators.maxLength(255)]
        }),
        gpsLatitudeFrom: new FormControl<number | null>(null),
        gpsLatitudeTo: new FormControl<number | null>(null),
        gpsLongitudeFrom: new FormControl<number | null>(null),
        gpsLongitudeTo: new FormControl<number | null>(null),
    }, { asyncValidators: this.createValidator() });

    showLocationOnMap$ = this.formService.openForm$.pipe(
        map(form => form && FormUtils.showLocationOnMap(form) || false)
    );

    objectLocationType$ = this.formService.openForm$.pipe(
        map(form => form && form.objectLocationType || null),
    );

    storedValue$ = this.formService.openForm$.pipe(
        filter((form): form is AnyProjectJobForm => !!form),
        map(form => extractLocationAnswer(form.extraFields)),
    );

    private subscriptions: Subscription[] = [];
    private gpsHandler?: string;
    private currentPositionSubject = new BehaviorSubject<PointMapCoords|undefined>(undefined);

    currentPosition$: Observable<PointMapCoords> = this.currentPositionSubject.asObservable().pipe(
        filter((val): val is PointMapCoords => !!val)
    );
    centerCoords$ = this.currentPosition$.pipe(
        take(1),
        startWith(QuestionV2JobLocationComponent.DEFAULT_CENTER_LOCATION)
    );
    coords$: Observable<PointMapCoords[]> = combineLatest([this.form.valueChanges, this.objectLocationType$]).pipe(
        map(([value, objectLocationType]) => {
            if (objectLocationType === 'Linear') {
                return [
                    {latitude: value.gpsLatitudeFrom ?? null, longitude: value.gpsLongitudeFrom ?? null},
                    {latitude: value.gpsLatitudeTo ?? null, longitude: value.gpsLongitudeTo ?? null}
                ];
            } else {
                return [
                    {latitude: value.gpsLatitudeFrom ?? null, longitude: value.gpsLongitudeFrom ?? null},
                ];
            }
        })
    );

    constructor(
        private ngZone: NgZone,
        private toastr: ToastrService,
        private formService: FormService,
        private route: ActivatedRoute,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {}

    ngAfterViewInit() {
        Geolocation.watchPosition({
            enableHighAccuracy: true,
        }, (position: GeolocationPosition | null) => {
            this.ngZone.run(() => {
                if (position) {
                    const {latitude, longitude} = position.coords;
                    this.currentPositionSubject.next({latitude, longitude});
                }
            });
        }).then(gpsHandler => this.gpsHandler = gpsHandler);

        this.subscriptions.push(
            this.storedValue$.subscribe(value => {
                this.form.patchValue(value);
            }),
        );

        this.changeDetectorRef.detectChanges();
    }

    submit() {
        this.doGoForward.next();
    }

    async ngOnDestroy(): Promise<void> {
        this.subscriptions.forEach(it => it.unsubscribe());

        if (this.gpsHandler) {
            await Geolocation.clearWatch({id: this.gpsHandler});
        }
    }

    async getGpsFrom() {
        try {
            const {latitude, longitude} = await firstValueFrom(this.getCurrentPosition());
            this.form.patchValue({
                gpsLatitudeFrom: latitude,
                gpsLongitudeFrom: longitude
            });
        } catch (error) {
            console.error(error);
            this.showPositionFetchError();
        }
    }

    async getGpsTo() {
        try {
            const {latitude, longitude} = await firstValueFrom(this.getCurrentPosition());
            this.form.patchValue({
                gpsLatitudeTo: latitude,
                gpsLongitudeTo: longitude
            });
        } catch (error) {
            console.error(error);
            this.showPositionFetchError();
        }
    }

    private showPositionFetchError() {
        this.toastr.error('Het ophalen van de GPS-locatie is mislukt.');
    }

    private getCurrentPosition(): Observable<PointMapCoords> {
        return this.currentPosition$.pipe(
            timeout(8000),
            filter(val => !!val),
            take(1)
        );
    }

    async showErrorToast() {
        const objectType = await firstValueFrom(this.objectLocationType$);
        const currentQuestionPosition = paramMapGetNumberOrFail(this.route.snapshot.paramMap, 'question');
        if (currentQuestionPosition == FORM_START_LOCATION_QUESTION_POSITION && objectType === 'Linear') {
            return this.toastr.error('Locatie is verplicht, vul minimaal het begin of eindpunt in.');
        }

        return this.toastr.error('Het antwoord van de locatievraag moet volledig ingevuld zijn.');
    }

    clearGpsFrom() {
        this.form.patchValue({gpsLatitudeFrom: null, gpsLongitudeFrom: null});
    }

    clearGpsTo() {
        this.form.patchValue({gpsLatitudeTo: null, gpsLongitudeTo: null});
    }

    onNewCoords(coords: PointMapCoords[]) {
        const [from, to] = coords;
        this.form.patchValue({
            gpsLatitudeFrom: from.latitude,
            gpsLongitudeFrom: from.longitude,
            gpsLatitudeTo: to.latitude,
            gpsLongitudeTo: to.longitude
        });
    }

    get isValid() {
        return this.form.valid;
    }

    get currentValue(): ProjectJobLocationAnswer | null {
        const value = this.form.getRawValue();

        if (!this.isValid) {
            return null;
        }

        return {
            locationFrom: value.locationFrom ?? null,
            locationTo: value.locationTo ?? null,
            gpsLatitudeFrom: value.gpsLatitudeFrom ?? null,
            gpsLatitudeTo: value.gpsLatitudeTo ?? null,
            gpsLongitudeFrom: value.gpsLongitudeFrom ?? null,
            gpsLongitudeTo: value.gpsLongitudeTo ?? null,
        }
    }

    private createValidator(): AsyncValidatorFn {
        return (control: AbstractControl) => {
            return combineLatest([
                this.formService.openForm$,
                merge(
                    control.valueChanges,
                    defer(() => of(control.value))
                )
            ]).pipe(
                map(([form, answer]) => {
                    const fromValid = answer.locationFrom != '' || (answer.gpsLatitudeFrom != null && answer.gpsLongitudeFrom != null);
                    const toValid = answer.locationTo != '' || (answer.gpsLatitudeTo != null && answer.gpsLongitudeTo != null)
                        || form?.objectLocationType === 'Point'; // Point location type only has from location so to is always valid

                    const currentPosition = +this.route.snapshot.params.question;

                    // Allow to continue with only one of to/from filled when on the initial location-question page
                    if (currentPosition === FORM_START_LOCATION_QUESTION_POSITION && form?.objectLocationType !== 'Point') {
                        return fromValid || toValid ? null : {invalidLocation: true}
                    } else {
                        return fromValid && toValid ? null : {invalidLocation: true};
                    }
                })
            );
        }
    }
}
