/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { deg2rad as t } from "../../../core/mathUtils.js";
import { Seconds as i } from "../../../core/time.js";
class o {
  get time() {
    return this._time;
  }
  constructor(t) {
    t && this.update(t);
  }
  update(t) {
    t && (this.definition ? this.definition.copyFrom(t) : this.definition = t.clone()), this._updatePrecomputedVariables(), this._updatePixelFlow();
  }
  _updatePrecomputedVariables() {
    const t = this.definition,
      i = t.compared,
      o = i.sourceZoom,
      e = i.targetZoom;
    this._zoomSign = o > e ? 1 : -1, this._panPixelsAtSource = i.pan * t.source.pixelsPerPanAtZoom(o);
    const n = (t.source.pixelsPerRotate() + t.target.pixelsPerRotate()) / 2;
    this._rotatePixels = i.rotate * n;
  }
  _updatePixelFlow() {
    const t = this.definition.compared.sourceZoom,
      o = this.definition.compared.targetZoom,
      {
        hasZoom: e,
        hasPan: n,
        hasRotate: s
      } = this.definition;
    let h = 0,
      a = 0;
    e && (n && (h = (o / t - 1) / (-1 / (this._zoomSign * this.definition.halfWindowSize) * Math.LN2 * this._panPixelsAtSource)), s && (a = this._zoomSign * (Math.log(t / o) / Math.LN2) * this.definition.halfWindowSize / this._rotatePixels)), this._zoomPixelFlow = 0, this._panPixelFlow = 0, this._rotatePixelFlow = 0;
    const r = this.definition.desiredPixelFlow;
    if (e && n && s) {
      const t = h + a + h * a;
      this._zoomPixelFlow = h * a / t * r, this._panPixelFlow = a / t * r, this._rotatePixelFlow = h / t * r;
    } else if (e && n) {
      const t = 1 + h;
      this._zoomPixelFlow = h / t * r, this._panPixelFlow = 1 / t * r;
    } else if (e && s) {
      const t = 1 + a;
      this._zoomPixelFlow = a / t * r, this._rotatePixelFlow = 1 / t * r;
    } else if (n && s) {
      const t = this._panPixelsAtSource / this._rotatePixels,
        i = 1 + t;
      this._panPixelFlow = t / i * r, this._rotatePixelFlow = 1 / i * r;
    } else n ? this._panPixelFlow = r : e ? this._zoomPixelFlow = r : s && (this._rotatePixelFlow = r);
    if (this._time = i(Math.max(this.rotateTime, this.zoomTime, this.panTime)), this.fovTime > this._time) {
      const t = this.fovTime / this._time;
      this._time = this.fovTime, this._zoomPixelFlow /= t, this._panPixelFlow /= t, this._rotatePixelFlow /= t;
    }
  }
  get rotateTime() {
    return this.definition.hasRotate ? i(this._rotatePixels / this._rotatePixelFlow) : i(0);
  }
  get zoomTime() {
    return this.definition.hasZoom ? i(this._zoomSign * (Math.log(this.definition.compared.sourceZoom / this.definition.compared.targetZoom) / Math.LN2) * this.definition.halfWindowSize / this._zoomPixelFlow) : i(0);
  }
  get fovTime() {
    return this.definition.hasFov ? i(Math.abs(this.definition.compared.fov) / e) : i(0);
  }
  get panTime() {
    if (!this.definition.hasPan) return i(0);
    if (this.definition.hasZoom) {
      const t = -1 / (this._zoomSign * this.definition.halfWindowSize) * Math.LN2,
        o = t * this._panPixelsAtSource;
      return i(Math.log(o * (this._zoomPixelFlow / this._panPixelFlow) + 1) / (t * this._zoomPixelFlow));
    }
    return i(this._panPixelsAtSource / this._panPixelFlow);
  }
  _interpolateComponentsZoom(t) {
    if (0 === t || 1 === t) return t;
    if (this.definition.hasZoom) {
      const i = this.definition.compared.sourceZoom,
        o = this.definition.compared.targetZoom;
      return (i * (i / o) ** -t - i) / (o - i);
    }
    return t;
  }
  _interpolateComponentsFov(t) {
    if (0 === t) return this.definition.segmentStart;
    if (1 === t) return this.definition.segmentEnd;
    if (this.definition.hasFov) {
      const {
        segmentStart: i,
        segmentEnd: o
      } = this.definition;
      return i + t * (o - i);
    }
    return this.definition.segmentStart;
  }
  _interpolateComponentsPan(t) {
    if (0 === t || 1 === t) return t;
    if (this.definition.hasPan && this.definition.hasZoom) {
      const i = -1 / (this._zoomSign * this.definition.halfWindowSize) * this._zoomPixelFlow;
      return 1 / this._panPixelsAtSource * (this._panPixelFlow * (2 ** (i * t * this._time) - 1)) / (i * Math.LN2);
    }
    return t;
  }
  _interpolateComponentsRotate(t) {
    return t;
  }
  interpolateComponentsAt(t, i) {
    t = Math.min(Math.max(t, 0), 1), i.zoom = this._interpolateComponentsZoom(t), i.pan = this._interpolateComponentsPan(t), i.rotate = this._interpolateComponentsRotate(t), i.zoomOffset = 0, i.fov = this._interpolateComponentsFov(t);
  }
}
const e = t(45);
export { o as Segment };