/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as e } from "../../../chunks/tslib.es6.js";
import r from "../../../core/Accessor.js";
import t from "../../../core/Identifiable.js";
import o from "../../../core/Logger.js";
import { property as s } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/RandomLCG.js";
import { subclass as i } from "../../../core/accessorSupport/decorators/subclass.js";
import { project as n } from "../../../geometry/projection.js";
import { extentContainsPoint as m, polygonContainsPoint as a } from "../../../geometry/support/contains.js";
import { nearestCoordinate as c } from "../../../geometry/support/near.js";
var l;
let p = l = class extends t.NumericIdentifiableMixin(r) {
  constructor(e) {
    super(e), this.geometry = null, this.spatialReference = null;
  }
  get normalizedGeometry() {
    if (null == this.geometry || !this.spatialReference) return null;
    if (!this.spatialReference.equals(this.geometry.spatialReference)) try {
      return n(this.geometry, this.spatialReference);
    } catch (e) {
      return o.getLogger(this).error("#constraints.geometry", "could not project the geometry to the view's spatial reference", {
        geometry: this.geometry,
        spatialReference: this.spatialReference,
        error: e
      }), null;
    }
    return this.geometry;
  }
  constrain(e, r) {
    if (null == this.normalizedGeometry) return e;
    const t = e.targetGeometry;
    if ("extent" === this.normalizedGeometry.type ? m(this.normalizedGeometry, t) : a(this.normalizedGeometry, t)) return e;
    const {
      coordinate: o
    } = c(this.normalizedGeometry, t);
    return o ? (e.targetGeometry = o, e) : e;
  }
  clone() {
    return new l({
      geometry: this.geometry?.clone(),
      spatialReference: this.spatialReference?.clone()
    });
  }
};
e([s({
  constructOnly: !0
})], p.prototype, "geometry", void 0), e([s({
  readOnly: !0
})], p.prototype, "normalizedGeometry", null), e([s({
  constructOnly: !0
})], p.prototype, "spatialReference", void 0), p = l = e([i("esri.views.2d.constraints.GeometryConstraint")], p);
export { p as GeometryConstraint };