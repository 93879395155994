import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'positionFormat',
    standalone: true
})
export class PositionFormatPipe implements PipeTransform {

    /**
     * Takes a value and formats position
     */
    transform(value: number) {
        const values = (value / 1000).toString().split('.');
        return `${values[0]}.${parseInt(values[1])}`;
    }
}
