/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import e from "../../core/Error.js";
import { getWebGLCapabilities as r } from "../webgl/capabilities.js";
function t(t) {
  const o = r();
  return o.available ? "3d" === t && o.majorPerformanceCaveat ? new e("webgl:major-performance-caveat-detected", `Your WebGL implementation (${o.unmaskedRenderer}) doesn't seem to support hardware accelerated rendering. Check your browser settings or if your GPU is in a blocklist.`) : o.supportsHighPrecisionFragment ? o.supportsVertexShaderSamplers ? null : new e("webgl:vertex-shader-samplers-required", "WebGL support for vertex shader samplers is required but not supported.") : new e("webgl:high-precision-fragment-required", "WebGL support for high precision fragment shaders is required but not supported.") : new e("webgl:required", "WebGL2 is required but not supported.", new Error().stack);
}
export { t as check };