import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, combineLatest, concatMap, firstValueFrom, from, Observable, of, Subscription} from 'rxjs';
import {catchError, debounceTime, shareReplay, switchMap, take, takeLast, tap, withLatestFrom} from 'rxjs/operators';
import {infiniteScrollObservable} from '../../helpers/pagination';
import {FormService} from '../../services/form.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectJobService} from '../../services/project-job.service';
import {Subscriptions} from '../../utils/subscriptions';
import {FormSyncService} from '../../services/form-sync.service';
import {FormImageService} from '../../services/form-image.service';
import {AnyProjectJobForm} from '../../models/project-job-form';
import {ProjectJobAnswerMetaService} from '../../services/project-job-answer-meta.service';
import {AnswerCopyService} from '../../services/answer-copy.service';
import {CopyAnswersModalComponent} from '../copy-answers-modal/copy-answers-modal.component';
import {PaulaDatabaseService} from '../../services/paula-database.service';
import {paramMapGetNumberOrFail} from '../../utils/param-map-util';
import {FormUtils} from '../../utils/form-utils';
import {ProjectJobSearchState} from '../project-jobs-search/project-jobs-search.component';
import {distinctUntilChangedStringify} from '../../utils/distinct-until-changed-stringify';
import {PopupService} from '../../services/popup.service';
import {RefreshEvent} from '../pull-to-refresh/pull-to-refresh.component';
import {AnnotateToolConfigService} from '../../services/annotate-tool-config.service';
import {WorkspaceConfigService} from '../../services/workspace-config.service';

export type JobStatusString = 'AvailableForInspection' | 'Rejected' | 'InProgress';

enum JobStatus {
    'AvailableForInspection' = 'voor uitvoering',
    'InProgress' = 'in uitvoering',
    'Rejected' = 'afgekeurd'
}

@Component({
    selector: 'app-project-jobs-list',
    templateUrl: './project-jobs-list.component.html',
    standalone: false
})
export class ProjectJobsListComponent implements OnInit, OnDestroy {
    @Input() viewLoaded: Observable<void> | null = null;
    @Input() status: JobStatusString = 'AvailableForInspection';
    @Input() set jobSearchState(state: ProjectJobSearchState | null) {
        this.jobSearchStateSubject.next(state);
    }

    @Output() totalNumber = new EventEmitter<number>();
    loading = false;
    allPagesLoaded = false;

    emptyStateMessage = '';

    jobSearchStateSubject = new BehaviorSubject<ProjectJobSearchState | null>(null)

    loadMore$ = new BehaviorSubject<unknown>(null);
    refresh$ = new BehaviorSubject<unknown>(null);
    search$ = new BehaviorSubject<string[]>([]);
    projectJobs$: Observable<AnyProjectJobForm[]> = combineLatest([
        this.route.paramMap,
        this.refresh$,
        this.jobSearchStateSubject.pipe(distinctUntilChangedStringify())
    ]).pipe(
        switchMap(([paramMap, _, searchState]) => infiniteScrollObservable(this.loadMore$, (page) => {
            const search = searchState?.searchTags || [];
            if (searchState?.search) {
                search.push(searchState.search);
            }
            const sort = [];
            if (searchState?.sort) {
                sort.push(searchState.sort);
            }

            this.allPagesLoaded = false;

            return this.projectJobService.list(paramMapGetNumberOrFail(paramMap,'project'), page, this.status, search, sort).pipe(
                tap(response => {
                    this.totalNumber.emit(response.totalElements);
                    this.allPagesLoaded = response.last;
                })
            )
        })),
        // dit is de pro-actieve  versie
        // de reactieve versie is de plek waar een vraag zou moeten worden ingevuld (dat is nog todo).
        tap(forms => this.formService.mergeForms(forms)),
        tap(forms => {
            if (forms) {
                this.loadImages$.next(forms);
            }
        }),
        tap(async forms => {
            const annotationVersion = await firstValueFrom(this.annotationToolConfigService.version());
            if (forms && annotationVersion === 2) {
                this.loadAnswerMetadata$.next(forms);
            }
        }),
        shareReplay(1)
    );
    loadImages$ = new BehaviorSubject<AnyProjectJobForm[]|null>(null);
    loadAnswerMetadata$ = new BehaviorSubject<AnyProjectJobForm[]|null>(null);

    readonly jobTitleTemplate$ = this.workspaceConfigService.getTextSetting('jobTitleTemplate', '%title%').pipe(shareReplay(1));
    readonly jobSubtitleTemplate$ = this.workspaceConfigService.getTextSetting(
        'jobSubtitleTemplate',
        '%objectOmschrijvingKort%\n%createdAt% door %createdBy%\nOpdrachtnummer: %code%'
    ).pipe(shareReplay(1));

    private subscriptions: Subscription[] = [];

    constructor(
        @Inject('ProjectJobService') private projectJobService: ProjectJobService,
        @Inject('ProjectJobAnswerMetaService') private projectJobAnswerMetaService: ProjectJobAnswerMetaService,
        @Inject('PaulaDatabaseService') private paulaDatabaseService: PaulaDatabaseService,
        private workspaceConfigService: WorkspaceConfigService,
        public formSyncService: FormSyncService,
        private formService: FormService,
        private annotationToolConfigService: AnnotateToolConfigService,
        private formImageService: FormImageService,
        private route: ActivatedRoute,
        private router: Router,
        private answerCopyService: AnswerCopyService,
        private popupService: PopupService,
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.projectJobs$.pipe(
                debounceTime(100),
                withLatestFrom(this.formService.getStoreChanges(), this.route.paramMap),
                switchMap(([, store, paramMap]) => {
                    const projectId = paramMap.has('project') ? paramMapGetNumberOrFail(paramMap, 'project') : null;
                    if (projectId !== null) {
                        return from((async () => {
                            // Get list of ids from both the formStore (forms) and the formSync store (queued answers and transitions)
                            const formStoreIds = store.filter(job => job.project == projectId).map(it => it.id);
                            const queuedAnswerIds = await this.formSyncService.getQueuedIds(projectId);
                            return new Set<number>([...formStoreIds, ...queuedAnswerIds]);
                        })()).pipe(
                            concatMap(combinedIds => {
                                if (combinedIds.size === 0) {
                                    return of([]);
                                }

                                const ids = Array.from(combinedIds);

                                // Split into chunks of 100
                                const chunks: number[][] = [];
                                const chunkSize = 100;
                                for (let i = 0; i < ids.length; i += chunkSize) {
                                    chunks.push(ids.slice(i, i + chunkSize));
                                }

                                return from(chunks);
                            }),
                            concatMap(chunk => this.projectJobService.nonExistingIds(projectId, chunk).pipe(
                                catchError(err => {
                                    console.error('fetching nonExistingIds failed', err);
                                    // Assume all ids exist if fetching fails to be on the safe side
                                    return of<number[]>([]);
                                })
                            ))
                        )
                    } else {
                        return of([]);
                    }
                })

            ).subscribe(async nonExistingIds => {
                await this.formService.removeFormsById(nonExistingIds);
                await this.formSyncService.removeFormsById(nonExistingIds);
            })
        );

        // Load images offline
        this.subscriptions.push(
            this.loadImages$.subscribe(forms => {
                if (forms) {
                    this.formImageService.downloadFormImages(forms);
                    this.paulaDatabaseService.downloadFormDatabases(forms)
                }
            })
        );

        // Load metadata offline and original images
        this.subscriptions.push(
            this.loadAnswerMetadata$.subscribe(forms => {
                if (forms) {
                    forms.forEach((form) => {
                        this.projectJobAnswerMetaService.getCompleteProjectJobAnswerMeta(form.id).then((projectJobAnswerMeta) => {
                            this.formImageService.downloadAnswerMetaImages(projectJobAnswerMeta);
                        });
                    });
                }
            })
        );

        this.emptyStateMessage = `Er zijn geen formulieren ${JobStatus[this.status]}`;
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    async loadMore(): Promise<void> {
        if (this.loading || this.allPagesLoaded) {
            return;
        }

        this.loading = true;
        this.projectJobs$.pipe(take(2), takeLast(1)).subscribe({
            error: err => {
                console.error('Failed to load more jobs', err);
                this.loading = false;
            },
            complete: () => {
                this.loading = false;
            }
        });
        this.loadMore$.next(null);
    }

    async doRefresh(event: RefreshEvent) {
        // We know loading has finished by skipping the first value (cached data) and taking the second value (new data)
        this.projectJobs$.pipe(take(2), takeLast(1)).subscribe({
            error: err => {
                console.error('Refresh failed', err);
                event.complete();
            },
            complete: () => event.complete()
        });
        await this.formSyncService.sync().catch(err => {
            console.error('Sync failed', err);
        });
        this.refresh$.next(null);
    }

    search(searchQueries: string[]) {
        this.search$.next(searchQueries);
    }

    async openCopyAnswersModal(source: AnyProjectJobForm, destination: AnyProjectJobForm): Promise<AnyProjectJobForm | undefined> {
        const popup = this.popupService.open(CopyAnswersModalComponent, {
            data: {
                source,
                destination
            }
        });

        return popup.result<AnyProjectJobForm>()
    }

    async open(destination: AnyProjectJobForm) {
        let source = null;
        if (destination.copyAnswers && destination.answers.length === 0 && destination.status === 'AvailableForInspection') {
            source = await firstValueFrom(this.answerCopyService.getSimilarJob(destination));
        }

        if (null !== source) {
            const copyAnswersResult = await this.openCopyAnswersModal(source, destination);

            if (copyAnswersResult !== undefined && copyAnswersResult !== null) {
                await this.formService.mergeForm(copyAnswersResult);
            }
        }

        await this.openForm(destination.project, destination);
    }

    openForm(projectId: number, projectJob: AnyProjectJobForm) {
        const isLayeredJob = FormUtils.isLayeredForm(projectJob);
        const route = isLayeredJob ? ['/projects', projectId, 'jobs', projectJob.id, 'layered', 'overview']
            :  ['/projects', projectId, 'jobs', projectJob.id, 'v2'];

        return this.router.navigate(route);
    }

    findLastStatusSubmitComment(projectJob: AnyProjectJobForm) {
        if (projectJob.submits.length > 0) {
            const sorted = projectJob.submits.sort(function (a, b) {
                return new Date(a.submittedAt).getTime() - new Date(b.submittedAt).getTime();
            });
            const comment = sorted[sorted.length - 1].comment;
            if (comment != null) {
                return comment;
            }
        }
        return '';
    }
}
