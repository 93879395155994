import {Component} from '@angular/core';
import {PopupRef} from '../../helpers/popup-ref';
import {PopupConfig} from '../../models/popup-config';
import {ProjectJobAttachment} from '../../models/project-job-attachment';
import sanitizeHtml from 'sanitize-html';

@Component({
    selector: 'app-view-html-attachment-modal',
    templateUrl: './view-html-attachment-modal.component.html',
    standalone: false
})
export class ViewHtmlAttachmentModalComponent {
    sanitizedHtml: string | null = null;
    attachment: ProjectJobAttachment | null = null;

    constructor(private popupRef: PopupRef, {
        data
    }: PopupConfig<{ attachment: ProjectJobAttachment, attachmentContent: string }>) {
        if (data) {
            this.attachment = data.attachment;
            this.sanitizedHtml  = sanitizeHtml(data.attachmentContent, {
                allowedTags: ['h1', 'h2', 'h3', 'h4', 'p', 'a', 'ul', 'ol', 'li', 'br', 'strong', 'i']
            });
        }
    }

    removeFileExtension(filename: string): string {
        return filename.split('.').slice(0, -1).join('.');
    }

    close() {
        this.popupRef.close()
    }
}
