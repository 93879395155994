import {Component, Input} from '@angular/core';
import {ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormUtils} from '../../../utils/form-utils';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';
import {Capacitor} from '@capacitor/core';
import RailStraightPlugin, {RailStraightMeasureResult} from '../../../plugins/rail-straight-plugin';
import {FormImageService} from '../../../services/form-image.service';
import {ToastrService} from 'ngx-toastr';
import {RailStraightQuestion} from '../../../models/question/rail-straight-question';

@Component({
    selector: 'app-question-v2-railstraight',
    templateUrl: './question-v2-railstraight.component.html',
    standalone: false
})
export class QuestionV2RailstraightComponent {
    readonly measureSupported = Capacitor.getPlatform() == 'android';

    public currentForm: AnyProjectJobForm | null = null;
    public currentQuestion: RailStraightQuestion | null = null;
    public currentNode: AnyLayeredFormNode | undefined = undefined;

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.updateAnswer();
    }

    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNode = node;
        this.updateAnswer();
    }
    @Input({required: true}) set question(question: RailStraightQuestion) {
        this.currentQuestion = question;

        this.updateAnswer();
    }
    public disabled = false;
    public parsedValue: RailStraightMeasureResult | null = null;

    constructor(private formImageService: FormImageService,
                private toastr: ToastrService) {
    }

    get isValid() {
        return !this.question?.required || !!this.parsedValue;
    }

    get currentValue(): ProjectJobAnswerValue {
        return {
            value: JSON.stringify(this.parsedValue),
            remarkText: null,
            remarkImage: null,
        }
    }

    async clearAnswer() {
        if (window.confirm('Weet u zeker dat u de ingevulde meetresultaten wilt verwijderen?')) {
            if (this.parsedValue !== null) {
                for (const qiImage of this.parsedValue.qi_images) {
                    await this.formImageService.removeQueuedImage(qiImage);
                }
            }
            this.parsedValue = null;
        }
    }

    async openRailStraight() {
        try {
            const result = await RailStraightPlugin.measure()

            // Remove previous queued images
            if (this.parsedValue !== null) {
                for (const qiImage of this.parsedValue.qi_images) {
                    await this.formImageService.removeQueuedImage(qiImage);
                }
            }

            // Queue new images
            for (const qiImage of result.qi_images) {
                await this.formImageService.queueStoredImage(qiImage);
            }

            this.parsedValue = result;
        } catch (e) {
            console.error('RailStraight measure failed', e);
            await this.toastr.error('RailStraight meting mislukt, probeer opnieuw');
        }
    }

    private updateAnswer() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const latest = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);
        const value = latest?.value ?? '';

        this.parsedValue = value ? JSON.parse(value) : null;
    }
}
