import {Injectable} from '@angular/core';
import {AnyProjectJobForm} from '../models/project-job-form';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AnswerCopyService {
    constructor(private readonly httpClient: HttpClient) {
    }

    getSimilarJob(form: AnyProjectJobForm): Observable<AnyProjectJobForm | null> {
        return this.httpClient.get<AnyProjectJobForm | null>(
            `/app-api/v1/projects/${form.project}/jobs/${form.id}/answers/similar-job`
        );
    }

    copyAnswers(source: AnyProjectJobForm, destination: AnyProjectJobForm): Observable<AnyProjectJobForm> {
        return this.httpClient.post<AnyProjectJobForm>(
            `/app-api/v1/projects/${source.project}/jobs/${source.id}/answers/copy/${destination.id}`,
            {}
        );
    }
}
