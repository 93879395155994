import {Injectable} from '@angular/core';
import {db} from '../db/db';
import {AnyLayeredFormNode, IndexedLayerNode} from '../models/layered-form-node';
import { HttpClient } from '@angular/common/http';
import {v4 as generateUuid} from 'uuid';
import {SyncOperation} from '../models/sync-operation';
import {liveQuery} from 'dexie';
import {firstValueFrom, from} from 'rxjs';
import {AnyProjectJobForm, LayeredProjectJobForm} from '../models/project-job-form';
import {NodeType} from '../models/node-type';
import {debounceTime, map} from 'rxjs/operators';
import {findAllNodeParents} from '../utils/node-util';

@Injectable({
  providedIn: 'root'
})
export class NodeService {
    constructor(private httpClient: HttpClient) { }

    async removeNodesForFormIds(formIds: number[]) {
        await db.nodes.where('jobFormId').anyOf(formIds).delete();
    }

    async mergeNodes(form: LayeredProjectJobForm) {
        // noinspection JSDeprecatedSymbols
        const nodes = form.nodes;

        // If a node is not in the database, add it
        for (const node of nodes) {
            const indexedNode = await db.nodes.get(node.id);

            if (!indexedNode) {
                await db.nodes.add(this.transformNodeToIndexed(form, node));
            } else {
                // If the node is newer than the one in the database, update it
                if (node.updatedAt > indexedNode.updatedAt) {
                    await db.nodes.update(node.id, node);
                }
            }
        }
    }

    async findNodeById(id: string) {
        return db.nodes.get(id);
    }

    async findNodesByJobFormId(jobFormId: number): Promise<IndexedLayerNode[]> {
        return db.nodes.where('jobFormId').equals(jobFormId).toArray();
    }

    async allNodesSynced(form: AnyProjectJobForm) {
        if (form.type !== 'layeredJobForm') {
            // If the form is not a layered form, it is always synced
            return true;
        }

        const nodesToSync = await firstValueFrom(this.jobFormSyncState$(form));

        return !nodesToSync;
    }

    async findAllParents(node?: IndexedLayerNode, includeCurrent = false): Promise<IndexedLayerNode[]> {
        const parents = await findAllNodeParents(node);
        if (includeCurrent && node) {
            parents.push(node);
        }

        return parents;
    }

    async createNode(jobForm: LayeredProjectJobForm, type: NodeType, node: AnyLayeredFormNode) {
        await db.nodes.add(this.transformNodeToIndexed(jobForm, node));

        await this.queueNodeSync(SyncOperation.Create, jobForm, node, type);
    }

    async updateNode(jobForm: LayeredProjectJobForm, node: AnyLayeredFormNode) {
        await db.nodes.update(node.id, node);

        await this.queueNodeSync(SyncOperation.Update, jobForm, node);
    }

    jobFormSyncState$(jobForm: LayeredProjectJobForm) {
        return from(
            liveQuery(() => db.nodeSyncQueue.where({ 'jobForm.id': jobForm.id }).count())
        ).pipe(
            map(count => count > 0),
            debounceTime(500)// Debounce syncState to prevent flickering sync icon
        );
    }

    async deleteNode(jobForm: LayeredProjectJobForm, node: AnyLayeredFormNode) {
        await db.nodes.delete(node.id);

        await this.queueNodeSync(SyncOperation.Delete, jobForm, node);
    }

    async queueNodeSync(operation: SyncOperation, jobForm: LayeredProjectJobForm, node: AnyLayeredFormNode, type?: NodeType) {
        await db.nodeSyncQueue.add({
            node,
            operation,
            jobForm,
            type,
            id: generateUuid(),
        });

        this.syncNodes();
    }

    async syncNodes() {
        // Check if queue is empty and return if it is
        // to work around a bug in Safari where uniqueKeys crashes if the table is empty
        // See https://github.com/dexie/Dexie.js/issues/1030
        if (await db.nodeSyncQueue.count() === 0) {
            return;
        }

        // Find distinct jobForm.id values
        const jobFormIds = await db.nodeSyncQueue.orderBy('jobForm.id').uniqueKeys();

        for (const jobFormId of jobFormIds) {
            try {
                const jobs = db.nodeSyncQueue.where({ 'jobForm.id': jobFormId }).toArray();

                for (const job of await jobs) {
                    switch (job.operation) {
                        case SyncOperation.Create:
                            await this.syncCreateNode(job.jobForm, job.node, job.type);
                            break;
                        case SyncOperation.Update:
                            await this.syncUpdateNode(job.jobForm, job.node);
                            break;
                        case SyncOperation.Delete:
                            await this.syncDeleteNode(job.jobForm, job.node);
                            break;
                        default:
                            throw new Error(`Unknown sync operation: ${job.operation}`);
                    }

                    await db.nodeSyncQueue.delete(job.id);
                }
            } catch (error) {
                console.warn('Unable to sync node for job', jobFormId, error);
                console.warn('Skipping for now, will retry later');
            }
        }
    }

    private transformNodeToIndexed(form: LayeredProjectJobForm, node: AnyLayeredFormNode): IndexedLayerNode {
        // Flatten the node parent to make sure IndexedDB can index it
        return {
            ...node,
            parent: node.parent ?? '',
            jobFormId: form.id,
        }
    }

    private syncCreateNode(job: LayeredProjectJobForm, node: AnyLayeredFormNode, type?: NodeType) {
        return firstValueFrom(this.httpClient.post<AnyLayeredFormNode>(`/app-api/v1/projects/${job.project}/jobs/${job.id}/nodes/${type}`, node));
    }

    private syncUpdateNode(job: LayeredProjectJobForm, node: AnyLayeredFormNode) {
        return firstValueFrom(this.httpClient.put<AnyLayeredFormNode>(`/app-api/v1/projects/${job.project}/jobs/${job.id}/nodes/${node.id}`, node));
    }

    private syncDeleteNode(job: LayeredProjectJobForm, node: AnyLayeredFormNode) {
        return firstValueFrom(this.httpClient.delete(`/app-api/v1/projects/${job.project}/jobs/${job.id}/nodes/${node.id}`));
    }
}
