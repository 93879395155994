/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import r from "../../core/Accessor.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as t } from "../../core/accessorSupport/decorators/subclass.js";
const s = () => e({
  type: ["pan", "rotate", "zoom", "none"],
  nonNullable: !0
});
let a = class extends r {
  constructor(o) {
    super(o), this.dragPrimary = "pan", this.dragSecondary = "rotate", this.dragTertiary = "zoom", this.mouseWheel = "zoom";
  }
};
o([s()], a.prototype, "dragPrimary", void 0), o([s()], a.prototype, "dragSecondary", void 0), o([s()], a.prototype, "dragTertiary", void 0), o([e({
  type: ["zoom", "none"],
  nonNullable: !0
})], a.prototype, "mouseWheel", void 0), a = o([t("esri.views.navigation.NavigationActionMap")], a);
const p = a;
export { p as default };