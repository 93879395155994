<ng-template cdkPortal>
    <ng-container *ngIf="(imageAnnotationVersion$ | async) !== 2 || canEdit || canRemove">
        <div class="image-viewer" [class.is-visible]="image() !== null">
            <div class="image-viewer__toolbar">
                <button class="image-viewer__btn" (click)="close()">
                    <app-icon class="text-white" iconName="chevron-left"></app-icon>
                </button>
                <ng-container *ifFeature="'imageDescription'">
                    <button class="image-viewer__btn" (click)="editDescription()" *ngIf="canEdit">
                        <app-icon class="text-white" [iconName]="image()?.description ? 'comment-filled' : 'comment'"></app-icon>
                    </button>
                </ng-container>
                <button class="image-viewer__btn" (click)="editImage()" *ngIf="canEdit">
                    <app-icon class="text-white" iconName="edit-2"></app-icon>
                </button>
                <button class="image-viewer__btn" (click)="removeImage()" *ngIf="canRemove">
                    <app-icon class="text-white" iconName="trash-2"></app-icon>
                </button>
            </div>
            <img #image *ngIf="imageUrl" class="image-viewer__image" [src]="memsafeImageUrl" alt=""
                 (pinch)="onPinch($any($event))" (wheel)="onWheelScroll($event)"
                 (pan)="onPan($any($event))" (panend)="onPanEnd()" (pinchend)="onPinchEnd()">
        </div>
    </ng-container>

    <app-image-annotation
        *ngIf="(imageAnnotationVersion$ | async) !== 2 && (imageAnnotationVersion$ | async) !== 3"
        [imageSrc]="imageUrl"
        [visible]="editing"
        (cancelAnnotation)="this.editing = false"
        (save)="saveEditedImage($event)"
    ></app-image-annotation>

    <app-image-annotation-v2
        *ngIf="(imageAnnotationVersion$ | async) === 2"
        [visible]="editing"
        [originalImage]="originalImage"
        [metaData]="metadata"
        [toolConfig]="toolConfigV2"
        [showRemove]="!canRemove && !!image()?.modifiedPhotoId"
        (cancelAnnotation)="cancelEditV2($event)"
        (save)="saveEditedImageV2($event)"
        (remove)="removeImage()"
    ></app-image-annotation-v2>

    <app-image-annotation-v3
        *ngIf="(imageAnnotationVersion$ | async) === 3"
        [image]="image()"
        [visible]="editing"
        [toolConfig]="annotateToolShapes$ | async"
        [showRemove]="!canRemove && !!image()?.modifiedPhotoId"
        (cancelAnnotation)="cancelEditV3()"
        (save)="saveEditedImageV3($event)"
        (remove)="removeImage()"
    ></app-image-annotation-v3>
</ng-template>
