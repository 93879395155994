import {
    Component,
    EventEmitter,
    Output,
    input, effect, computed
} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-create-comment',
    templateUrl: './create-comment.component.html',
    imports: [
        ReactiveFormsModule,
        TranslatePipe
    ],
    standalone: true
})
export class CreateCommentComponent {
    readonly visible = input(false);
    readonly comment = input<string>('');

    @Output() cancelComment = new EventEmitter<void>();
    @Output() save = new EventEmitter<string>();

    commentControl = computed(() => new FormControl<string>(this.comment(), {nonNullable: true}));

    constructor() {
        effect(() => {

        });
    }

    cancel() {
        this.cancelComment.emit();
    }

    saveComment() {
        this.save.emit(this.commentControl().value);
    }
}
