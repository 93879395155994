import {Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormImageService} from '../../services/form-image.service';
import {ProjectJobAnswerMetaService} from '../../services/project-job-answer-meta.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-image-thumbnail',
    templateUrl: './image-thumbnail.component.html',
    standalone: false
})
export class ImageThumbnailComponent implements OnChanges, OnInit, OnDestroy {
    @Input() jobId: number | undefined;
    @Input() questionPosition: number | undefined;
    @Input() uuid: string | undefined;
    @Input() comment = false;

    imageSrc: string | null = null;
    hasMetaComment = false;
    subscription: Subscription | undefined;

    constructor(
        private imageService: FormImageService,
        @Inject('ProjectJobAnswerMetaService') private projectJobAnswerMetaService: ProjectJobAnswerMetaService,
    ) {
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (this.uuid) {
            this.imageSrc = await this.imageService.getImageUrl(this.uuid);
            this.hasMetaComment = (this.jobId !== undefined && this.questionPosition !== undefined)
                ? await this.projectJobAnswerMetaService.answerHasCommentOnCurrentQuestion(this.jobId, this.questionPosition, this.uuid)
                : false;
        }
    }

    ngOnDestroy(): void {
        this.imageSrc = null;
        this.subscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.subscription = this.projectJobAnswerMetaService.metadataUpdated$.subscribe((updatedAnswerUuid) => {
            if (this.uuid === updatedAnswerUuid && this.jobId !== undefined && this.questionPosition !== undefined) {
                this.projectJobAnswerMetaService.answerHasCommentOnCurrentQuestion(this.jobId, this.questionPosition, this.uuid).then((hasComment) => {
                    this.hasMetaComment = hasComment;
                });
            }
        });
    }
}
