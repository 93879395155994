/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { Milliseconds as e } from "../../../core/time.js";
const o = {
    desiredScreenFlow: 2,
    minDuration: e(500),
    maxDuration: e(8e3)
  },
  r = {
    ...o,
    maxDuration: e(4e3)
  };
export { o as defaultSettings, r as defaultSettings2D };