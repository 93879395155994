import {Component, forwardRef, Inject, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {FormImageService} from '../../services/form-image.service';
import {PictureUtil} from '../../utils/picture';
import {ToastrService} from 'ngx-toastr';
import {v4 as uuid} from 'uuid';
import {ProjectJobAnswerMetaService} from '../../services/project-job-answer-meta.service';
import {Capacitor} from '@capacitor/core';
import {PdfReaderService} from '../../services/pdf-reader.service';
import {AnnotatableImage, ImageAnnotationResult} from '../image-annotation-v3/annotatable-image';
import {AnnotateToolConfigService} from '../../services/annotate-tool-config.service';

const DEFAULT_MAX_IMAGE_COUNT = 80;

@Component({
    selector: 'app-form-image-control',
    templateUrl: './form-image-control.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormImageControlComponent),
            multi: true
        }
    ],
    standalone: false
})
export class FormImageControlComponent implements ControlValueAccessor {
    private _maxImageCount = DEFAULT_MAX_IMAGE_COUNT;
    public loading = false;
    public loadingMessage: string | null = null;

    @Input()
    set maxImageCount(value: number) {
        this._maxImageCount = value || DEFAULT_MAX_IMAGE_COUNT;
    }

    get maxImageCount(): number {
        return this._maxImageCount;
    }

    @Input({required: true})
    jobId: number | undefined;
    @Input({required: true})
    questionPosition: number | undefined;

    uploadImageModalVisible$ = new BehaviorSubject(false);
    selectedImage$ = new BehaviorSubject<AnnotatableImage | null>(null);
    annotationVersion$ = this.annotateToolConfigService.version();
    imageSourceSelected = false;

    images: AnnotatableImage[] = [];

    // SortableJS options
    sortableJsOptions = {
        fallbackTolerance: 5,
        handle: '.drag-handle',
        ghostClass: 'drag-placeholder',
        onUpdate: () => {
            this.onChange(JSON.stringify(this.images));
        },
    };

    // ControlValueAccessor Properties
    disabled = false;
    onChange: (value: string) => void = () => {
    };

    constructor(
        private formImageService: FormImageService,
        private pdfReaderService: PdfReaderService,
        private annotateToolConfigService: AnnotateToolConfigService,
        @Inject('ProjectJobAnswerMetaService') private projectJobAnswerMetaService: ProjectJobAnswerMetaService,
        private toastr: ToastrService,
    ) {
    }

    writeValue(value: string): void {
        if (!value) {
            this.images = [];
        } else if (value.startsWith('[')) {
            this.images = JSON.parse(value);
        } else {
            this.images = value
                .split(',')
                .map((uuid) => ({originalPhotoId: uuid}));
        }
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        // Nothing
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    showImageUploadModal() {
        this.uploadImageModalVisible$.next(true);
    }

    showImageViewer(image: AnnotatableImage) {
        this.selectedImage$.next(image);
    }

    async addPhoto(fromGallery: boolean) {
        // We cant detect failed select on web so don't disable buttons there
        if (Capacitor.isNativePlatform()) {
            this.imageSourceSelected = true;
        }

        // let overlay: any | null = null;
        try {
            (await (fromGallery
                    ? PictureUtil.getPicturesFromGallery(this._maxImageCount - this.images.length, async () => {
                        this.loading = true;
                    })
                    : PictureUtil.getPictureFromCamera(async () => {
                        this.loading = true;
                    })
            )).map(async imageData => {
                const imageBlob = imageData.blob;
                if (imageBlob) {
                    const uniqueId = uuid();

                    // Add image to form image store
                    await this.formImageService.queueImage(uniqueId, imageBlob);
                    this.uploadImageModalVisible$.next(false);

                    // Add image uuid as answer value
                    this.images.push({originalPhotoId: uniqueId});
                    this.onChange(JSON.stringify(this.images));

                    // Add the necessary exif data for the image
                    const annotationVersion = await firstValueFrom(this.annotationVersion$);
                    if (annotationVersion === 2 && this.jobId && this.questionPosition && imageData.exif) {
                        await this.projectJobAnswerMetaService.addExifForUuid(this.jobId, this.questionPosition, uniqueId, imageData.exif);
                    }
                }
            });
        } catch (error) {
            if (error !== null && error !== undefined && error.toString() !== 'Cancelled File Upload') {
                console.error(error);
                if (error.toString() === 'Unsupported File Type') {
                    await this.toastr.error('Dit bestandstype wordt niet ondersteund');
                } else {
                    await this.toastr.error('Het toevoegen van een afbeelding is mislukt');
                }
            } else {
                console.error('addPhoto failed', error);
            }
        } finally {
            this.imageSourceSelected = false;
            this.loading = false;
        }
    }

    async addPdf(event: Event): Promise<void> {
        const input = event.target as HTMLInputElement;
        this.loadingMessage = 'PDF converteren';

        try {
            this.loading = true;

            for (const file of Array.from(input.files || [])) {
                const pdfImageBlobs = await this.pdfReaderService.toImageBlob(file);
                let maxUploadReached = false;

                for (const blob of pdfImageBlobs) {
                    if ((this.images.length + 1) > this._maxImageCount) {
                        maxUploadReached = true;
                        break;
                    }

                    const uniqueId = uuid();
                    // Add image to form image store
                    await this.formImageService.queueImage(uniqueId, blob);

                    // Add image uuid as answer value
                    this.images.push({originalPhotoId: uniqueId});
                }

                this.onChange(JSON.stringify(this.images));

                if (maxUploadReached) {
                    await this.toastr.error(`Niet alle PDF-pagina's konden worden toegevoegd omdat je de limiet van ${this._maxImageCount} afbeeldingen hebt bereikt`);
                }
            }
        } catch (error) {
            await this.toastr.error('PDF kon niet worden toegevoegd');
            console.error(error)
        } finally {
            input.value = '';
            this.uploadImageModalVisible$.next(false);
            this.loading = false;
            this.loadingMessage = null;
        }
    }

    async removeImage(image: AnnotatableImage) {
        if (!this.jobId) {
            throw new Error('Missing job id');
        }
        if (!this.questionPosition) {
            throw new Error('Missing question position');
        }

        await this.formImageService.removeQueuedImage(image.originalPhotoId);
        await this.projectJobAnswerMetaService.removeLocalMetadataFromCurrentQuestion(this.jobId, this.questionPosition, image.originalPhotoId);

        if (image.modifiedPhotoId) {
            await this.formImageService.removeQueuedImage(image.modifiedPhotoId);
            await this.projectJobAnswerMetaService.removeLocalMetadataFromCurrentQuestion(this.jobId, this.questionPosition, image.modifiedPhotoId);
        }

        // Remove image uuid from answer value
        this.images = this.images.filter(it => it.originalPhotoId !== image.originalPhotoId);
        this.onChange(this.images.length > 0 ? JSON.stringify(this.images) : '');

        this.selectedImage$.next(null);
    }

    async replaceImage(data: ImageAnnotationResult) {
        if (!this.jobId) {
            throw new Error('Missing job id');
        }
        if (!this.questionPosition) {
            throw new Error('Missing question position');
        }

        const imageToReplace = this.images.find(it => it.originalPhotoId === data.image.originalPhotoId);

        if (data.blob) {
            const newId = uuid();
            // Add image to form image store
            await this.formImageService.queueImage(newId, data.blob);

            const annotationVersion = await firstValueFrom(this.annotationVersion$);
            if (annotationVersion === 1) {
                await this.formImageService.removeQueuedImage(data.image.originalPhotoId);

                // Remove original image uuid from answer value
                this.images = this.images.filter(image => image.originalPhotoId !== data.image.originalPhotoId);
                this.images.push({originalPhotoId: newId});
            } else {
                if (data.image.modifiedPhotoId) {
                    // Only delete the image if it is no longer referenced
                    if (await this.projectJobAnswerMetaService.isDanglingImage(this.jobId, this.questionPosition, data.image.modifiedPhotoId)) {
                        await this.formImageService.removeQueuedImage(data.image.modifiedPhotoId);
                    }
                }

                imageToReplace!.modifiedPhotoId = newId;

                await this.projectJobAnswerMetaService.replaceCurrentQuestionAnswerMetaId(this.jobId,
                    this.questionPosition,
                    data.image.modifiedPhotoId ?? data.image.originalPhotoId,
                    newId
                );
            }
        }

        if (data.shapes) {
            imageToReplace!.shapes = data.shapes;
        }

        this.onChange(JSON.stringify(this.images));
        this.selectedImage$.next(null);
    }

    get isUploadDisabled() {
        return (this.images.length >= this._maxImageCount || this.disabled);
    }

    get showAlert() {
        return (!this.disabled && this.images.length >= this._maxImageCount);
    }
}
