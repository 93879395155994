import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {FormSyncService} from '../services/form-sync.service';
import {BehaviorSubject, of, Subscription, switchMap} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

@Directive({
    selector: '[projectHasUnsyncedChanges]',
    standalone: true
})
export class ProjectHasUnsyncedChangesDirective implements OnInit, OnDestroy {
    private projectId$ = new BehaviorSubject<number | undefined>(undefined);
    private subscription: Subscription | null = null;

    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainerRef: ViewContainerRef,
        private formSyncService: FormSyncService
    ) {
    }

    ngOnInit() {
        this.subscription = this.projectId$.pipe(
            distinctUntilChanged(),
            switchMap(projectId => {
                return projectId === undefined
                    ? of(false)
                    : this.formSyncService.projectSyncStateChanges(projectId);
            }),
            distinctUntilChanged()
        ).subscribe(showView => {
            this.viewContainerRef.clear()
            if (showView) {
                this.viewContainerRef.createEmbeddedView(this.templateRef)
            }
        })
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    }

    @Input()
    set projectHasUnsyncedChanges(projectId: number) {
        this.projectId$.next(projectId);
    }

}
