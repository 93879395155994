import {Component} from '@angular/core';
import {combineLatestWith, map, switchMap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {FormService} from '../../services/form.service';
import {ActivatedRoute} from '@angular/router';
import {ProjectJobFormChapter} from '../../models/project-job-form-chapter';
import {NodeService} from '../../services/node.service';
import {FORM_END_LOCATION_QUESTION_POSITION, FORM_START_LOCATION_QUESTION_POSITION} from '../../models/project-job-form';
import {IndexedLayerNode} from '../../models/layered-form-node';

@Component({
    selector: 'app-question-header',
    templateUrl: './question-header.component.html',
    standalone: false
})
export class QuestionHeaderComponent {
    public current$ = combineLatest([this.route.params, this.route.parent?.params ?? []])
        .pipe(
            map(([{ question }, { job }]) => ({ question: +question, job: +job})),
        );

    public isLocationQuestion$ = this.route.params
        .pipe(
            map(({ question }) => +question),
            map((question) => {
                return question === FORM_START_LOCATION_QUESTION_POSITION
                    || question === FORM_END_LOCATION_QUESTION_POSITION;
            }),
        );

    public chapter$ = this.route
        .data
        .pipe(
            map(({ chapter }) => chapter as ProjectJobFormChapter),
        );

    public node$ = this.route
        .data
        .pipe(
            map(({ node }) => node as IndexedLayerNode),
        );

    public nodePath$ = this.node$
        .pipe(
            switchMap((node) => this.nodeService.findAllParents(node)),
        );

    public question$ = this.current$
        .pipe(
            combineLatestWith(this.node$),
            switchMap(([{ question, job }, node]) => this.formService.getQuestionAsObservable(job, question, node)),
        );

    constructor(private formService: FormService, private nodeService: NodeService, private route: ActivatedRoute) {
    }
}
