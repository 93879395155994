import {Component, Input} from '@angular/core';
import {ToleranceMessage} from "../../utils/question-tolerance";

@Component({
    selector: 'app-tolerance-message',
    templateUrl: './tolerance-message.component.html',
    standalone: false
})
export class ToleranceMessageComponent {
    @Input() tolerance: ToleranceMessage | null = null;


    get message(): string {
        if (this.tolerance?.tolerant === false) {
            return this.tolerance.message;
        }

        return '';
    }
}
