/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import e from "../../core/Error.js";
import { distance as n } from "../../core/libs/gl-matrix-2/math/vec2.js";
import t from "../Point.js";
import { projectPointOnLine as o } from "./coordsUtils.js";
function r(r, l) {
  const {
      spatialReference: s
    } = l,
    a = [l.x, l.y];
  let c, m, x, f;
  const p = [0, 0],
    u = i(r);
  for (let e = 0; e < u.length; e++) {
    const t = u[e];
    for (let r = 0; r < t.length - 1; r++) {
      o(p, a, t, r);
      const i = n(a, p);
      (null == c || i < c) && (c = i, m = [...p], x = e, f = r);
    }
  }
  if (null == c || !m || null == x || null == f) throw new e("nearest-coordinate:failed", "Failed to find the nearest coordinate");
  const [y, d] = m;
  return {
    coordinate: new t({
      x: y,
      y: d,
      spatialReference: s
    }),
    distance: c
  };
}
function i(e) {
  switch (e.type) {
    case "extent":
      return [[[e.xmin, e.ymin], [e.xmin, e.ymax], [e.xmax, e.ymax], [e.xmax, e.ymin], [e.xmin, e.ymin]]];
    case "polygon":
      return e.rings;
    case "polyline":
      return e.paths;
  }
}
export { r as nearestCoordinate };