import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, forwardRef, Input} from '@angular/core';
import {convertToNumber} from '../../utils/number-utils';
import {Capacitor} from "@capacitor/core";
import {v4 as uuid} from 'uuid';

@Component({
    selector: 'app-number-input',
    templateUrl: './number-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true
        }
    ],
    imports: [
        FormsModule
    ],
    standalone: true
})
export class NumberInputComponent implements ControlValueAccessor {
    @Input() label = 'Antwoord';
    @Input() required = false;

    private _value = '';
    private onTouched = () => {};
    private onChange = (_: string) => {};

    isIOS = Capacitor.getPlatform() === 'ios';
    disabled = false;
    inputId = uuid();

    get value() {
        return this._value;
    }

    set value(value: string) {
        this._value = value;
        this.onChange(this._value);
    }

    validateAllowedKeys(event: KeyboardEvent) {
        const key = event.key as string;
        const isFloatingPointCharacter = /([.,])+$/.test(key);
        const isAllowedKey = (['Enter', 'Backspace'].includes(key) || /([0-9-])+$/.test(key));

        return isFloatingPointCharacter || isAllowedKey;
    }

    updateValueFromInputEvent(inputEvent: Event) {
        const inputElement = (inputEvent.target as HTMLInputElement)
        const value = inputElement.value;
        if (value !== '') {
            // Only update value if raw value we got from the input is not empty
            // Value returns empty string while number is invalid
            this.value = this.doConvertToNumber(value)
        } else if (inputElement.validity.valid && value === '') {
            // If the input is empty and valid, we should set the value to empty string
            // this is to differentiate between empty number input and invalid input (e.g. 'e')
            this.value = value
        }
    }

    doConvertToNumber(value: string): string {
        if (this.onTouched) {
            this.onTouched()
        }

        return convertToNumber(value);
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn
    }

    writeValue(value: string | undefined): void {
        if (value !== undefined) {
            this.value = value;
        }
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }
}
