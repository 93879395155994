// @ts-strict-ignore
import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-empty-state-message',
    templateUrl: './empty-state-message.component.html',
    standalone: false
})
export class EmptyStateMessageComponent {
  @Input() imgSrc: string;
}
