/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import "../../intl.js";
import e from "../../PopupTemplate.js";
import o from "../../core/Error.js";
import { isAbortError as r } from "../../core/promiseUtils.js";
import { property as a } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as i } from "../../core/accessorSupport/decorators/subclass.js";
import { removeSelectedFeature as l } from "../Popup/actions.js";
import s from "../support/GeolocationPositioning.js";
import { getCurrentPosition as c } from "../support/geolocationUtils.js";
import { onLocaleChange as p } from "../../intl/locale.js";
import { fetchMessageBundle as n } from "../../intl/messages.js";
async function d() {
  const t = await n("esri/widgets/Locate/t9n/Locate");
  return new e({
    title: t.currentLocation,
    fieldInfos: [{
      fieldName: "timestamp",
      label: t.timestamp,
      format: {
        dateFormat: "short-date-short-time"
      }
    }, {
      fieldName: "latitude",
      label: t.latitude,
      format: {
        places: 4,
        digitSeparator: !0
      }
    }, {
      fieldName: "longitude",
      label: t.longitude,
      format: {
        places: 4,
        digitSeparator: !0
      }
    }, {
      fieldName: "accuracy",
      label: t.accuracy,
      format: {
        places: 0,
        digitSeparator: !0
      }
    }, {
      fieldName: "altitude",
      label: t.altitude,
      format: {
        places: 0,
        digitSeparator: !0
      }
    }, {
      fieldName: "altitudeAccuracy",
      label: t.altitudeAccuracy,
      format: {
        places: 0,
        digitSeparator: !0
      }
    }, {
      fieldName: "heading",
      label: t.heading,
      format: {
        places: 0,
        digitSeparator: !0
      }
    }, {
      fieldName: "speed",
      label: t.speed,
      format: {
        places: 0,
        digitSeparator: !0
      }
    }],
    actions: [l.clone()],
    content: [{
      type: "fields"
    }]
  });
}
let u = class extends s {
  constructor(t) {
    super(t), this._locateController = null, this.error = void 0, this.popupEnabled = !0, this.locate = this.locate.bind(this);
  }
  initialize() {
    this.addHandles([p(() => {
      const {
        graphic: t,
        view: e
      } = this;
      if (!t) return;
      const o = e?.graphics?.includes(t);
      o && this._updatePopupTemplate(t);
    })]);
  }
  destroy() {
    this.cancelLocate();
  }
  get state() {
    return this._geolocationUsable ? this.view?.ready ? this._locateController ? "locating" : null != this.error ? "error" : "ready" : "disabled" : "feature-unsupported";
  }
  async locate() {
    if (this.cancelLocate(), "disabled" === this.state) throw new o("locate:disabled-state", "Cannot locate when disabled.");
    if ("feature-unsupported" === this.state) throw new o("locate:feature-unsupported-state", "Cannot locate in unsecure domain.");
    const t = new AbortController();
    this._locateController = t, this.error = void 0;
    try {
      const e = await c(this.geolocationOptions);
      if (await this.updatePosition(e, t), this._locateController !== t) return null;
      const {
        graphic: o
      } = this;
      return o && (await this._updatePopupTemplate(o)), this._addGraphic(), this.emit("locate", {
        position: e
      }), this._locateController = null, e;
    } catch (e) {
      if (r(e)) return null;
      throw t === this._locateController && (this._locateController = null), this.error = e, this.emit("locate-error", {
        error: e
      }), e;
    }
  }
  cancelLocate() {
    this._clearGraphic(), this._locateController && this._locateController.abort(), this._locateController = null;
  }
  async _updatePopupTemplate(t) {
    if (!this.popupEnabled) return;
    const e = await d(),
      o = t !== this.graphic;
    this.destroyed || o || (t.popupTemplate = e);
  }
};
t([a()], u.prototype, "_locateController", void 0), t([a()], u.prototype, "error", void 0), t([a()], u.prototype, "popupEnabled", void 0), t([a({
  readOnly: !0
})], u.prototype, "state", null), t([a()], u.prototype, "locate", null), t([a()], u.prototype, "cancelLocate", null), u = t([i("esri.widgets.Locate.LocateViewModel")], u);
const m = u;
export { m as default };