import {Component} from '@angular/core';
import {AnyLayeredFormNode} from '../../models/layered-form-node';
import {PopupService} from '../../services/popup.service';
import {CreateNodeInLayerPopupComponent} from '../create-node-in-layer-popup/create-node-in-layer-popup.component';
import {combineLatest, firstValueFrom, map} from 'rxjs';
import {liveQuery} from 'dexie';
import {db} from '../../db/db';
import {filter, switchMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {FormService} from '../../services/form.service';
import {FormUtils} from '../../utils/form-utils';
import {NodeService} from '../../services/node.service';
import {LayeredProjectJobForm} from '../../models/project-job-form';

@Component({
    selector: 'app-add-node',
    templateUrl: './add-node.component.html',
    standalone: false
})
export class AddNodeComponent {
    form$ = this.formService.openForm$;
    currentNodes$ = combineLatest([this.form$, this.route.data])
        .pipe(
            switchMap(([form, { currentNode }]) => liveQuery(() => db.nodes.where({ 'parent': currentNode?.id ?? '', 'jobFormId': form?.id }).sortBy('name'))),
        );
    layers$ = this.form$.pipe(
        filter((form): form is LayeredProjectJobForm => FormUtils.isLayeredForm(form)),
        map(form => form.layers),
    );

    currentLayer$ = combineLatest([this.layers$, this.route.params])
        .pipe(
            map(([layers, { layer }]) => layers.find(it => +it.id === +layer)),
        );

    lastLayer$ = combineLatest([this.layers$, this.route.data])
        .pipe(
            map(([layers, { last }]) => {
                if (!last) {
                    return null;
                }

                return layers[layers.length - 1];
            }),
        );

    path$ = this.route.data
        .pipe(
            switchMap(({ currentNode }) => this.nodeService.findAllParents(currentNode, true)),
        );

    constructor(private popupService: PopupService, private router: Router, private formService: FormService, private route: ActivatedRoute, private nodeService: NodeService) {}

    async select(node: AnyLayeredFormNode) {
        const layers = await firstValueFrom(this.layers$);
        const path = await firstValueFrom(this.path$);
        const depth = path.length;
        const newDepth = depth + 1;

        const isLastLayer = newDepth === layers.length;

        // If we select something in the last layer, we want to navigate to the node questions
        if (isLastLayer) {
            await this.router.navigate(['nodes', node.id], {relativeTo: this.route.parent});
        } else {
            // If lastLayer$ is set, this means that we are in the flow of creating a new node in the last layer
            // This has implications on navigations, and determines where we will end up after answering questions
            const shouldNavigateUntilLastLayer = await firstValueFrom(this.lastLayer$);

            if (shouldNavigateUntilLastLayer !== null) {
                await this.router.navigate(['add-nodes', 'last', layers[newDepth].id, node?.id], {relativeTo: this.route.parent});
            } else {
                await this.router.navigate(['add-nodes', layers[newDepth].id, node?.id], {relativeTo: this.route.parent});
            }
        }
    }

    async openPopup() {
        const layer = await firstValueFrom(this.currentLayer$);

        const popup = this.popupService.open(
            CreateNodeInLayerPopupComponent,
            {
                data: {
                    layer,
                    parent: this.route.snapshot.data.currentNode,
                },
            }
        );
        const form = await firstValueFrom(this.form$);
        if (form?.type !== 'layeredJobForm') {
            throw new Error('Form is not a layered form');
        }

        const result = await popup.result<{ node: AnyLayeredFormNode }>();

        if (result?.node && form) {
            const node = result.node;
            const questions = FormUtils.questionsForNode(form, node);

            if (questions.length > 0) {
                const path = await firstValueFrom(this.path$);
                const depth = path.length;
                const layers = await firstValueFrom(this.layers$);
                const lastLayer = await firstValueFrom(this.lastLayer$);

                // Return to list, if the item created was not created in the last layer, and the user wanted to create a new item in the last layer
                const shouldReturn = (depth + 1 !== layers.length) && lastLayer !== null;
                const returnTo = shouldReturn ? this.router.url : undefined;

                await this.router.navigate(['projects', form.project, 'jobs', form.id, 'layered', 'nodes', node.id], {
                    queryParams: {
                        returnTo,
                    }
                });
            }
        }
    }

    async navigateTo(node: AnyLayeredFormNode) {
        const layers = await firstValueFrom(this.layers$);
        const lastLayer = await firstValueFrom(this.lastLayer$);
        const resultingNode = node.parent ? node.parent : null;

        const nodeLayerId = FormUtils.layerIdForNode(node, layers);

        if (lastLayer !== null) {
            await this.router.navigate(['add-nodes', 'last', nodeLayerId, resultingNode].filter(it => !!it), {relativeTo: this.route.parent});
        } else {
            await this.router.navigate(['add-nodes', nodeLayerId, resultingNode].filter(it => !!it), {relativeTo: this.route.parent});
        }
    }
}
