import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Html5Qrcode} from 'html5-qrcode';
import {ToastrService} from 'ngx-toastr';
import {PopupRef} from '../../helpers/popup-ref';
import {PopupConfig} from '../../models/popup-config';

@Component({
    selector: 'app-question-type-qr-code-scanner-modal',
    templateUrl: './qr-code-scanner-modal.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QrCodeScannerModalComponent),
            multi: true
        }
    ],
    standalone: false
})
export class QrCodeScannerModalComponent implements OnInit, OnDestroy {
    title: string | undefined;
    html5QrCode: Html5Qrcode | undefined;

    constructor(
        private toastr: ToastrService,
        private popupRef: PopupRef, {
            data
        }: PopupConfig<{ title: string }>) {
            if (data) {
                this.title = data.title;
            }
    }

    async startScanning() {
        this.html5QrCode = new Html5Qrcode("qr-code-scanner");

        const qrCodeSuccessCallback = (decodedText: string) => {
            this.popupRef.close(decodedText);
        };

        const config = {
            fps: 10,
            qrbox: this.qrBoxFunction,
        };

        try {
            await this.html5QrCode?.start(
                {
                    facingMode: "environment", // prefer back camera
                },
                config,
                qrCodeSuccessCallback,
                undefined
            );
        } catch (error) {
            console.error("Failed to start QR-Scanner", error);
            this.toastr.error("Cameratoegang geweigerd");
        }
    }

    ngOnInit() {
        this.startScanning();
    }

    qrBoxFunction(viewfinderWidth: number, viewfinderHeight: number) {
        const minEdgePercentage = 0.7; // 70%
        const minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
        const qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
        return {
            width: qrboxSize,
            height: qrboxSize
        };
    }

    stopScanning() {
        if (this.html5QrCode?.isScanning) {
            this.html5QrCode.stop();
        }
    }

    cancel() {
        this.popupRef.close();
    }

    ngOnDestroy() {
        this.stopScanning();
    }
}
