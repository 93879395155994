/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { isPromiseLike as e } from "../../core/promiseUtils.js";
import { when as t, watch as a } from "../../core/reactiveUtils.js";
import { addFrameTask as s } from "../../core/scheduling.js";
import i from "./PaddedViewState.js";
import { RenderState as r } from "../support/RenderState.js";
import { newScheduler as d } from "../support/Scheduler.js";
class h {
  constructor(a) {
    this.view = a, this._stationaryHandle = null, this._frameTaskHandle = null, this._updateParameters = null, this._updateRequested = !1, this._scheduler = d(), this._schedulerHandle = t(() => this._scheduler.updating, () => this.requestFrame()), this.stationary = !0, this.prepare = () => {
      this._updateParameters && (this._updateParameters.state = this.view.state, this._updateParameters.stationary = this.view.stationary, this._updateParameters.pixelRatio = window.devicePixelRatio, this._updateParameters.renderingOptions = this.view.renderingOptions, this._updateParameters.targetState.copy(this.view.state), null == this.view.animation?.target || e(this.view.animation.target) || (this._updateParameters.targetState.viewpoint = this.view.animation.target));
    }, this.update = e => {
      if (this._updateRequested = !1, this.view?.destroyed) return;
      const {
        allLayerViews: t,
        graphicsView: a,
        labelManager: s,
        state: {
          id: i
        }
      } = this.view;
      t?.forEach(this._updateLayerView, this), null != s && (s.lastUpdateId !== i && (s.viewChange(), s.lastUpdateId = i), s.updateRequested && s.processUpdate(this._updateParameters)), null != a && (a.lastUpdateId !== i && (a.viewChange(), a.lastUpdateId = i), a.updateRequested && a.processUpdate(this._updateParameters)), this.view.graphicsTileStore?.setViewState(this._updateParameters.state), this.view.animation ? this._scheduler.state = r.ANIMATING : this.view.interacting ? this._scheduler.state = r.INTERACTING : this._scheduler.state = r.IDLE;
      this._scheduler.frame(e) || this._updateRequested || this._scheduler.state !== r.IDLE || this._frameTaskHandle?.pause();
    };
  }
  destroy() {
    this.stop(), this._schedulerHandle.remove(), this._scheduler.destroy();
  }
  get scheduler() {
    return this._scheduler;
  }
  start() {
    if (this._frameTaskHandle) return;
    const e = this.view;
    this.stationary = e.stationary, this._updateParameters = {
      state: e.state,
      targetState: new i(),
      pixelRatio: window.devicePixelRatio,
      stationary: this.stationary,
      renderingOptions: e.renderingOptions
    }, this._stationaryHandle = a(() => e.stationary, e => {
      this.stationary = e, this.requestFrame();
    }), this._frameTaskHandle = s(this), this.requestUpdate();
  }
  stop() {
    this._frameTaskHandle && (this._updateRequested = !1, this._stationaryHandle?.remove(), this._frameTaskHandle.remove(), this._updateParameters = this._stationaryHandle = this._frameTaskHandle = null, this.stationary = !0);
  }
  requestUpdate() {
    this._updateRequested || (this._updateRequested = !0, this.requestFrame());
  }
  requestFrame() {
    this._frameTaskHandle && this._frameTaskHandle.resume();
  }
  _updateLayerView(e) {
    if (!e.attached) return void this.requestUpdate();
    const t = this.view.state,
      a = e.lastUpdateId;
    null != a && (this.stationary || e.moving) || (e.moving = !0), a !== t.id && e.viewChange(), this.stationary && e.moving && (e.moving = !1, e.moveEnd()), e.lastUpdateId = t.id, e.updateRequested && e.processUpdate(this._updateParameters), "layerViews" in e && e.layerViews?.forEach(this._updateLayerView, this);
  }
}
export { h as default };