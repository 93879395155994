import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {AnyLayeredFormNode} from '../../models/layered-form-node';
import {TreeComponent} from '../tree/tree.component';
import {Question} from '../../models/question/question';
import {ActivatedRoute, Router} from '@angular/router';
import {AnyFormLayer} from '../../models/form-layer';
import {FormUtils} from '../../utils/form-utils';
import {LayeredProjectJobForm} from '../../models/project-job-form';
import {NodeStatus} from '../../utils/form-node-status';

@Component({
    selector: 'app-tree-level',
    templateUrl: './tree-level.component.html',
    standalone: false
})
export class TreeLevelComponent {

    @Input({required: true}) public set node(value: AnyLayeredFormNode | undefined) {
        this.currentNode = value;

        this.updateQuestions();
        this.checkExpanded();
        this.findChildren();
    }
    @Input({required: true}) public set tree(value: AnyLayeredFormNode[]) {
        this.fullTree = value;

        this.findChildren();
    }
    @Input({required: true}) public set form(value: LayeredProjectJobForm | null) {
        this.currentForm = value;

        this.updateQuestions();
    }

    @Input({required: true}) public nodeStatuses: {[nodeId: string]: NodeStatus} = {};

    @Input({required: true}) public layers: AnyFormLayer[] = [];
    @Input() public levelNumber = 1;
    @Output() public leafTapped = new EventEmitter<AnyLayeredFormNode>();
    @Output() public contextMenuTapped = new EventEmitter<AnyLayeredFormNode>();
    @ViewChildren(TreeLevelComponent) public levels!: QueryList<TreeLevelComponent>;
    public expanded = false;
    public expandedGeneralQuestions = false;
    public children: AnyLayeredFormNode[] = [];
    public currentQuestions: Question[] = [];
    public currentNode?: AnyLayeredFormNode;
    public fullTree: AnyLayeredFormNode[] = [];
    public valid = false;
    public currentForm: LayeredProjectJobForm | null = null;

    private treeComponent?: TreeComponent;

    constructor(private elementRef: ElementRef, private router: Router, private route: ActivatedRoute) {
    }

    handleContextMenuTap(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.contextMenuTapped.emit(this.currentNode);
    }

    attachToTreeComponent(tree: TreeComponent) {
        this.treeComponent = tree;
        this.checkExpanded();

        this.levels.forEach(level => level.attachToTreeComponent(tree));
    }

    checkExpanded() {
        if (!this.treeComponent || !this.currentNode) {
            return;
        }

        this.expanded = this.treeComponent?.isExpanded(this.currentNode.id);
        this.expandedGeneralQuestions = this.treeComponent?.isExpanded(this.generalQuestionsNodeId);
    }

    tapHeader() {
        if (this.hasChildren) {
            if (!this.treeComponent || !this.currentNode) {
                return;
            }

            this.expanded = this.treeComponent.toggleExpanded(this.currentNode.id);
        } else {
            this.leafTapped.emit(this.currentNode);
        }
    }

    toggleGeneralQuestions() {
        if (!this.treeComponent || !this.currentNode) {
            return;
        }

        this.expandedGeneralQuestions = this.treeComponent.toggleExpanded(this.generalQuestionsNodeId);
    }

    async openQuestion(node: AnyLayeredFormNode, question: NodeStatus['questions'][0]) {
        await this.router.navigate(['..', 'nodes', node.id, 'questions', question.position], { relativeTo: this.route });
    }

    get height(): number {
        return this.elementRef.nativeElement.offsetHeight;
    }

    private findChildren() {
        if (!this.currentNode) {
            return;
        }

        this.children = this.fullTree.filter(node => node.parent === this.currentNode?.id);
    }

    get generalQuestionsNodeId(): string {
        return this.currentNode ? this.currentNode.id + '-general-questions' : '';
    }

    get isLastLevel(): boolean {
        return this.layers?.length === this.levelNumber;
    }

    get hasChildren() {
        return this.children.length > 0 || this.currentQuestions.length;
    }

    private updateQuestions() {
        if (!this.currentForm || !this.currentNode) {
            return;
        }

        this.currentQuestions = this.currentNode ? FormUtils.questionsForNode(this.currentForm, this.currentNode) : [];
    }
}
