import {Component, Input} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormUtils} from '../../../utils/form-utils';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';
import {ChoiceMatrixQuestion} from '../../../models/question/choice-matrix-question';

@Component({
    selector: 'app-question-v2-choice-matrix',
    templateUrl: './question-v2-choice-matrix.component.html',
    standalone: false
})
export class QuestionV2ChoiceMatrixComponent {
    private currentForm: AnyProjectJobForm | null = null;
    public currentQuestion: ChoiceMatrixQuestion | null = null;
    public currentNode: AnyLayeredFormNode | undefined = undefined;

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.updateAnswer();
    }

    @Input({required: true}) set node(node: AnyLayeredFormNode) {
        this.currentNode = node;
        this.updateAnswer();
    }
    @Input({required: true}) set question(question: ChoiceMatrixQuestion) {
        this.currentQuestion = question;

        this.updateAnswer();
    }
    public formGroup = new FormGroup({
        firstChoice: new FormControl<string | null>(null),
        secondChoice: new FormControl<string | null>(null)
    }, this.answerValidator());

    get isValid() {
        return this.formGroup.valid;
    }

    get currentValue(): ProjectJobAnswerValue {
        const value = this.formGroup.getRawValue();

        // Value is empty string if both choice fields are empty
        return {
            value: (value.firstChoice === null && value.secondChoice === null) ? '' : JSON.stringify({
                firstLabel: this.currentQuestion?.config.firstLabel,
                firstChoice: value.firstChoice ?? null,
                secondLabel: this.currentQuestion?.config.secondLabel,
                secondChoice: value.secondChoice ?? null
            }),
            remarkText: null,
            remarkImage: null,
        }
    }

    private updateAnswer() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const latest = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);
        const value = latest?.value;

        if (value) {
            const parsed = JSON.parse(value);
            this.formGroup.patchValue(parsed);
        } else {
            this.formGroup.reset({firstChoice: null, secondChoice: null});
        }

        const validators = this.currentQuestion.required ? [Validators.required] : [];
        this.formGroup.get('firstChoice')?.setValidators(validators);
        this.formGroup.get('secondChoice')?.setValidators(validators);
    }

    private answerValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            const value = control.value;
            const required = this.currentQuestion?.required ?? false;

            const firstChoiceValue = value?.firstChoice ?? null;
            const secondChoiceValue = value?.secondChoice ?? null;

            if ((!firstChoiceValue && secondChoiceValue) || (firstChoiceValue && !secondChoiceValue)) {
                return { bothEmptyOrFilled: true };
            } else if (required && !firstChoiceValue && !secondChoiceValue) {
                return { required: true };
            } else {
                return null;
            }
        }
    }
}
