import { Component } from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {PopupRef} from '../../helpers/popup-ref';
import {Router} from '@angular/router';

@Component({
    selector: 'app-logout-confirmation-modal',
    templateUrl: './logout-confirmation-modal.component.html',
    standalone: false
})
export class LogoutConfirmationModalComponent {
    loading = false;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private popupRef: PopupRef) {
    }

    close() {
        this.popupRef.close();
    }

    async logout(): Promise<void> {
        try {
            this.loading = true;
            await this.authenticationService.logout();
            await this.router.navigate(['/workspace-select']);
            this.popupRef.close();
        } catch (error) {
            console.error('error while logging out', error);
        } finally {
            this.loading = false;
        }
    }
}
