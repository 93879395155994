/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import r from "../../core/Accessor.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as e } from "../../core/accessorSupport/decorators/subclass.js";
import t from "./gamepad/GamepadSettings.js";
let p = class extends r {
  constructor() {
    super(...arguments), this.gamepad = new t();
  }
};
o([s({
  readOnly: !0
})], p.prototype, "gamepad", void 0), p = o([e("esri.views.input.Input")], p);
const a = p;
export { a as default };