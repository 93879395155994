/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
function r(r, e) {
  switch (e) {
    case "primary":
      return "touch" === r.pointerType || 0 === r.button;
    case "secondary":
      return "touch" !== r.pointerType && 2 === r.button;
    case "tertiary":
      return "touch" !== r.pointerType && 1 === r.button;
  }
}
function e(e, t) {
  return t.some(t => r(e, t));
}
function t(r, e) {
  const {
    button: t,
    pointerType: n
  } = r;
  return "touch" !== n && e.some(r => "primary" === r && 0 === t || "secondary" === r && 2 === t || "tertiary" === r && 1 === t);
}
function n(r, {
  dragPrimary: e,
  dragSecondary: t,
  dragTertiary: n
}) {
  return [{
    key: "primary",
    value: e
  }, {
    key: "secondary",
    value: t
  }, {
    key: "tertiary",
    value: n
  }].filter(e => e.value === r).map(r => r.key);
}
export { t as eventMatchesMousePointerActions, r as eventMatchesPointerAction, e as eventMatchesPointerActions, n as getPointerActions };