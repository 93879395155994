/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
class t {
  constructor(t) {
    this._callbacks = t, this._currentCount = 0, this._callbacks.condition || (this._callbacks.condition = () => !0);
  }
  handle(t) {
    const s = t.data,
      i = s.pointers.size;
    switch (s.action) {
      case "start":
        this._currentCount = i, this._emitStart(t);
        break;
      case "added":
        this._emitEnd(this._previousEvent), this._currentCount = i, this._emitStart(t);
        break;
      case "update":
        this._emitUpdate(t);
        break;
      case "removed":
        this._startEvent && this._emitEnd(this._previousEvent), this._currentCount = i, this._emitStart(t);
        break;
      case "end":
        this._emitEnd(t), this._currentCount = 0;
    }
    this._previousEvent = t;
  }
  _emitStart(t) {
    this._startEvent = t, this._callbacks.condition?.(this._currentCount, t) && this._callbacks.start(this._currentCount, t, this._startEvent);
  }
  _emitUpdate(t) {
    this._callbacks.condition?.(this._currentCount, t) && this._callbacks.update(this._currentCount, t, this._startEvent);
  }
  _emitEnd(t) {
    this._callbacks.condition?.(this._currentCount, t) && this._callbacks.end(this._currentCount, t, this._startEvent), this._startEvent = null;
  }
}
export { t as DragEventSeparator };