/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import t from "../Viewpoint.js";
import i from "../core/Collection.js";
import r from "../core/CollectionFlattener.js";
import s from "../core/Error.js";
import has from "../core/has.js";
import a from "../core/Logger.js";
import { destroyMaybe as n } from "../core/maybe.js";
import { watch as o, syncAndInitial as l } from "../core/reactiveUtils.js";
import { initialize as p } from "../core/workers/workers.js";
import { property as h } from "../core/accessorSupport/decorators/property.js";
import { cast as g } from "../core/accessorSupport/decorators/cast.js";
import "../core/RandomLCG.js";
import { subclass as d } from "../core/accessorSupport/decorators/subclass.js";
import { ensureClass as c } from "../core/accessorSupport/ensureType.js";
import u from "../geometry/Polygon.js";
import { isLoadedOrLoadFor as m } from "../geometry/projection.js";
import y from "../layers/support/TileInfo.js";
import { BreakpointsOwner as f } from "./BreakpointsOwner.js";
import { DOMContainer as w } from "./DOMContainer.js";
import { PopupView as V } from "./PopupView.js";
import M from "./View.js";
import { Viewport2DMixin as v } from "./Viewport2DMixin.js";
import b from "./2d/AnimationManager.js";
import { layerView2DImporter as _ } from "./2d/layerViewModuleImportUtils.js";
import j from "./2d/tiling/TileInfoView.js";
import "./2d/tiling/TileKey.js";
import "./2d/tiling/TileQueue.js";
import "./2d/tiling/TileStrategy.js";
import { extentToScale as S } from "./2d/viewpointUtils.js";
import O from "./2d/layers/features/support/TileStore.js";
import { createOrReuseHighlightGradient as L } from "./2d/layers/support/util.js";
import { clipContainsPoint as R } from "./2d/support/clippingUtils.js";
import { getDefaultHighlightOptions as T, setDefaultHighlightOptions as k } from "./2d/support/highlightOptionsUtils.js";
import { hitTest as C } from "./2d/support/hitTestUtils.js";
import { takeRawScreenshot as D, takeScreenshot as x } from "./2d/support/screenshotUtils.js";
import { Timeline as P } from "./2d/support/Timeline.js";
import G from "./navigation/Navigation.js";
import E from "./navigation/NavigationActionMap.js";
import U from "./support/HighlightOptions.js";
import { isSupportedScreenPointEvent as z, createScreenPointFromSupportedEvent as I } from "./support/screenUtils.js";
import { check as q } from "./support/WebGLRequirements.js";
import A from "./ui/DefaultUI.js";
import N from "./ui/2d/DefaultUI2D.js";
import { isWebMap as W } from "../webmap/utils.js";
import $ from "../webmap/background/ColorBackground.js";
let F, H, B, Z, J, K, Q;
async function X() {
  const [, {
    GraphicsView2D: e,
    GraphicContainer: t,
    LabelManager: i,
    MapViewNavigation: r,
    MagnifierView2D: s,
    GridView2D: a,
    Stage: n
  }] = await Promise.all([import("./2d/webglDeps.js"), import("./2d/mapViewDeps.js")]);
  H = e, B = t, Z = i, J = r, K = s, Q = a, F = n;
}
let Y = class extends v(f(V(w(M)))) {
  constructor(e) {
    super(e), this._magnifierView = null, this._gridView = null, this.stage = null, this._resolveWhenReady = [], this.rootLayerViews = new r({
      getCollections: () => [this.basemapView?.baseLayerViews, this.layerViews, this.basemapView?.referenceLayerViews],
      getChildrenFunction: () => null
    }), this.featuresTilingScheme = null, this.graphicsView = null, this.labelManager = null, this.navigation = new G({
      actionMap: new E({
        dragTertiary: "none"
      })
    }), this.renderingOptions = {
      samplingMode: "dynamic",
      edgeLabelsVisible: !0,
      labelsAnimationTime: 125,
      labelCollisionsEnabled: !0
    }, this.supersampleScreenshotsEnabled = !0, this.supportsGround = !1, this.floors = new i(), this.grid = null, this.map = null, this.spatialReferenceLocked = !1, this.timeline = new P(), this.type = "2d", this.view2dType = null, this.ui = new N(), this.test = {
      takeScreenshot: async e => D(this._getScreenshotView(e), e)
    }, this.padding = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }, p();
  }
  destroy() {
    this.layerViewManager.clear(), this._set("preconditionsReady", !1), this.frameTask = n(this.frameTask), this.goToManager.destroy(), this.rootLayerViews.destroy(), this.inputManager.destroy(), this._set("inputManager", null);
  }
  get graphicsTileStore() {
    return new O(this.featuresTilingScheme);
  }
  get constraintsInfo() {
    const e = this.defaultsFromMap?.tileInfo,
      t = this.spatialReference;
    return {
      lods: e?.spatialReference?.equals(t) ? e.lods : null,
      spatialReference: t
    };
  }
  get initialExtentRequired() {
    if (!this.stateManager) return !1;
    const {
      scale: e,
      constraints: t,
      center: i,
      viewpoint: r,
      extent: s
    } = this;
    let a = this.zoom;
    return !(this.map && "initialViewProperties" in this.map && this.map.initialViewProperties?.viewpoint) && !s && (t?.effectiveLODs || (a = -1), (!i || 0 === e && -1 === a) && (null == r?.targetGeometry || "extent" !== r.targetGeometry.type && !r.scale));
  }
  get defaultsFromMapSettings() {
    return {
      required: {
        extent: !1,
        heightModelInfo: !1,
        tileInfo: !0
      },
      requiresExtentInSpatialReference: this.spatialReferenceLocked
    };
  }
  get scheduler() {
    return this.frameTask.scheduler;
  }
  get typeSpecificPreconditionsReady() {
    const e = this._getDefaultViewpoint();
    if (!e) return !1;
    const t = e.targetGeometry,
      i = this.spatialReference;
    return m(t.spatialReference, i);
  }
  get background() {
    return W(this.map) ? this.map.initialViewProperties.background : null;
  }
  set background(e) {
    this._override("background", e);
  }
  get center() {
    return this.stateManager?.center ?? null;
  }
  set center(e) {
    this.stateManager.center = e;
  }
  get highlightOptions() {
    return T(this);
  }
  set highlightOptions(e) {
    k(this, e);
  }
  get padding() {
    return this.stateManager?.padding;
  }
  set padding(e) {
    this.stateManager && (this.stateManager.padding = e);
  }
  get rendering() {
    return this.stage?.renderRequested ?? !1;
  }
  get resolution() {
    return this.stateManager.resolution ?? 0;
  }
  get scale() {
    return this.stateManager?.scale ?? 0;
  }
  set scale(e) {
    this.stateManager && (this.stateManager.scale = e);
  }
  get tileInfo() {
    return this.featuresTilingScheme?.tileInfo;
  }
  get updating() {
    const e = !(!this.magnifier.visible || null === this.magnifier.position || !this._magnifierView?.updatingHandles.updating),
      t = !this.destroyed && (!this.layerViewManager || !this.labelManager || !this.graphicsView || !0 === this.layerViewManager.updating || !0 === this.labelManager.updating || !0 === this.graphicsView.updating || this.allLayerViews.some(e => !e.destroyed && !("layerViews" in e) && !0 === e.updating) || e);
    if (has("esri-2d-log-updating")) {
      const i = this.allLayerViews.reduce((e, t) => ({
        ...e,
        [`${t.layer.id}(${t.layer.type})`]: !t.destroyed && !("layerViews" in t) && t.updating
      }), {});
      console.log(`Updating MapView: ${t}\n-> Null LayerViewManager: ${!this.layerViewManager}\n-> Null LabelManager: ${!this.labelManager}\n-> Null GraphicsView: ${!this.graphicsView}\n-> layerViewManager.updating: ${this.layerViewManager?.updating}\n-> labelManager.updating: ${this.labelManager?.updating}\n-> graphicsView.updating: ${this.graphicsView?.updating}\n-> allLayerViews: ${JSON.stringify(i)}\n-> updatingMagnifier: ${e}\n`);
    }
    return t;
  }
  get visibleArea() {
    const e = this.stateManager.visibleArea;
    return e ? new u({
      rings: [e.map(e => [e[0], e[1]])],
      spatialReference: this.spatialReference
    }) : e;
  }
  get zoom() {
    return this.stateManager.zoom ?? -1;
  }
  set zoom(e) {
    this.stateManager.zoom = e;
  }
  get navigating() {
    return this.mapViewNavigation?.interacting ?? !1;
  }
  async hitTest(e, t) {
    return C(this, e, t);
  }
  async takeScreenshot(e) {
    return x(this._getScreenshotView(e), e);
  }
  toMap(e) {
    if (!this.ready) return null;
    const t = z(e) ? I(this, e) : e;
    return this.stateManager.toMap(t);
  }
  toScreen(e, t) {
    return this.stateManager.toScreen(e, t);
  }
  whenLayerView(e) {
    return super.whenLayerView(e);
  }
  graphicChanged(e) {
    if (this.graphicsView) {
      this.graphicsView.graphicUpdateHandler(e);
    }
  }
  whenReady() {
    return new Promise(e => {
      this.ready ? e(this) : this._resolveWhenReady.push(e);
    });
  }
  forceDOMReadyCycle() {
    this.forceReadyCycle();
  }
  getDefaultSpatialReference() {
    return this.map && "initialViewProperties" in this.map && this.map.initialViewProperties.spatialReference || this.defaultsFromMap?.spatialReference || null;
  }
  getDefaultTimeZone() {
    return W(this.map) ? this.map.initialViewProperties.timeZone : null;
  }
  getDefaultTimeExtent() {
    return W(this.map) ? this.map.initialViewProperties.timeExtent : null;
  }
  hasLayerViewModule(e) {
    return _.hasLayerViewModule(e);
  }
  importLayerView(e) {
    return _.importLayerView(e);
  }
  pixelSizeAt() {
    return this.ready ? this.resolution : (a.getLogger(this).error("#pixelSizeAt()", "Map view cannot be used before it is ready"), null);
  }
  async popupHitTest(e) {
    const t = this.toMap(e),
      i = await this.hitTest(e),
      r = this.allLayerViews.toArray().filter(i => i.clips.every(i => R(this, i, e, t))).reverse(),
      s = new globalThis.Map(r.map(e => [e.layer.uid, e])),
      a = [];
    let n = 0,
      o = 0;
    for (; n < i.results.length || o < r.length;) {
      const e = i.results.at(n);
      if (e && "graphic" !== e.type) {
        ++n;
        continue;
      }
      const l = s.get((e?.layer ?? e?.graphic.layer)?.uid);
      if ((!e || l) && o < r.length && r.at(o) !== l) {
        const e = r.at(o);
        "fetchPopupFeaturesAtLocation" in e && a.push({
          mapPoint: t,
          layerView: e
        }), ++o;
      } else e && (a.push({
        graphic: e.graphic,
        layerView: l
      }), ++n);
    }
    return {
      hits: a,
      location: t
    };
  }
  requestUpdate() {
    this.ready && this.frameTask.requestUpdate();
  }
  async validate() {
    let e = q(this.type);
    if (has("safari") && has("safari") < 9 && (e = new s("mapview:browser-not-supported", "This browser is not supported by MapView (Safari < 9)", {
      type: "safari",
      requiredVersion: 9,
      detectedVersion: has("safari")
    })), null != e) throw a.getLogger(this).warn("#validate()", e.message), e;
  }
  loadAsyncDependencies() {
    return X();
  }
  _getDefaultViewpoint() {
    const {
      constraints: e,
      initialExtent: i,
      map: r,
      padding: s,
      size: a
    } = this;
    if (!e) return null;
    const n = r && "initialViewProperties" in r ? r.initialViewProperties : void 0,
      o = this.stateManager.getUserStartupOptions(this.size),
      l = n?.viewpoint,
      p = l?.targetGeometry?.extent ?? i,
      h = p?.center,
      g = l?.rotation ?? 0,
      d = l?.scale || p && S(p, [a[0] - s.left - s.right, a[1] - s.top - s.bottom]),
      c = o.center ?? h,
      u = o.rotation ?? g,
      m = o.scale ?? d;
    return c && m ? new t({
      targetGeometry: c,
      scale: m,
      rotation: u
    }) : null;
  }
  _startup() {
    this.timeline.begin("MapView Startup");
    const e = this._getDefaultViewpoint();
    this.stateManager.startup(e, this.size, this.spatialReference, this.defaultsFromMap.extent?.center), this.graphics.owner = this;
    const t = new F(this.surface, {
      canvas: this.renderCanvas,
      contextOptions: {
        disabledExtensions: this.deactivatedWebGLExtensions,
        debugWebGLExtensions: this.debugWebGLExtensions
      },
      renderingOptions: this.renderingOptions,
      timeline: this.timeline
    });
    this.stage = t, this._magnifierView = new K(), this._magnifierView.magnifier = this.magnifier, this._gridView = new Q();
    const i = new Z({
      view: this
    });
    this._set("labelManager", i);
    const r = new b({
      view: this
    });
    this._set("animationManager", r);
    const s = new J({
      view: this,
      animationManager: r
    });
    this._set("mapViewNavigation", s), this._setupSpatialReferenceDependentProperties(), this.addHandles([this.rootLayerViews.on("change", () => this._updateStageChildren()), t.on("webgl-error", e => this.fatalError = e.error), o(() => this.stationary, e => t.stationary = e, l), o(() => this.background, e => {
      t.backgroundColor = e?.color, this._magnifierView.backgroundColor = e?.color;
    }, l), o(() => this.magnifier, e => this._magnifierView.magnifier = e, l), o(() => this.grid, e => this._gridView.grid = e, l), o(() => this.renderingOptions, e => t.renderingOptions = e, l), o(() => this.highlights.items.map(e => ({
      name: e.name,
      options: {
        fillColor: e.color,
        haloColor: e.haloColor,
        fillOpacity: e.fillOpacity,
        haloOpacity: e.haloOpacity,
        haloWidth: e.haloWidth,
        haloBlur: e.haloBlur
      }
    })), () => {
      t.highlightGradient = L(t.highlightGradient, this.highlights.items);
    }, l), o(() => this.state.id, () => t.state = this.state, l)], "map-view"), this._updateStageChildren();
    const a = this._resolveWhenReady;
    this._resolveWhenReady = [], a.forEach(e => e(this)), this.timeline.end("MapView Startup"), this.frameTask.start(), this._set("ready", !0);
  }
  _teardown() {
    this._destroySpatialReferenceDependentProperties(), this.removeHandles("map-view"), this.mapViewNavigation.destroy(), this._set("mapViewNavigation", null), this.animation = null, this.animationManager.destroy(), this._set("animationManager", null), this.layerViewManager.clear(), this.labelManager.destroy(), this._magnifierView.destroy(), this._gridView.destroy(), this.stage.destroy(), this.stage = null, this._set("graphicsView", null), this._magnifierView = null, this._gridView = null, this._set("labelManager", null), this._set("mapViewNavigation", null), this.graphics.owner = null, this.frameTask.stop(), this.stationaryManager.clear(), this._set("ready", !1), this.stateManager.teardown();
  }
  _updateStageChildren() {
    this.stage.removeAllChildren(), this.rootLayerViews.forEach(e => {
      this.stage.addChild(e.container);
    });
    const e = this.graphicsView;
    this.stage.addChild(e.container), this.stage.addChild(this._magnifierView), this.stage.addChild(this._gridView);
  }
  _setupSpatialReferenceDependentProperties() {
    const e = new j(y.create({
      spatialReference: this.spatialReference,
      size: 512,
      numLODs: 36
    }));
    this._set("featuresTilingScheme", e);
    const t = new H({
      view: this,
      graphics: this.graphics,
      requestUpdateCallback: () => this.requestUpdate(),
      container: new B(e)
    });
    this._set("graphicsView", t);
  }
  _destroySpatialReferenceDependentProperties() {
    const e = this.graphicsView;
    this._set("graphicsView", null), e.destroy(), this._set("featuresTilingScheme", null);
  }
  _getScreenshotView(e) {
    const {
      allLayerViews: t,
      padding: i,
      size: r,
      stage: s
    } = this;
    return {
      allLayerViews: t,
      backgroundColor: e?.ignoreBackground ? null : this.background?.color,
      padding: i,
      size: r,
      stage: s
    };
  }
  _spatialReferenceChanged(e) {
    if (this.ready) {
      this.frameTask.stop();
      for (const e of this.allLayerViews) e.processDetach();
      this._destroySpatialReferenceDependentProperties(), this.stateManager.changeSpatialReference(e), this.stage.state = this.state, this._setupSpatialReferenceDependentProperties();
      for (const e of this.allLayerViews) e.processAttach();
      this.frameTask.requestFrame(), this.frameTask.start(), this._updateStageChildren();
    }
  }
};
Y.type = "2d", e([h({
  constructOnly: !0
})], Y.prototype, "deactivatedWebGLExtensions", void 0), e([h({
  constructOnly: !0
})], Y.prototype, "debugWebGLExtensions", void 0), e([h({
  readOnly: !0
})], Y.prototype, "featuresTilingScheme", void 0), e([h({
  readOnly: !0
})], Y.prototype, "graphicsTileStore", null), e([h()], Y.prototype, "graphicsView", void 0), e([h()], Y.prototype, "constraintsInfo", null), e([h()], Y.prototype, "initialExtentRequired", null), e([h()], Y.prototype, "labelManager", void 0), e([h({
  type: G,
  nonNullable: !0
})], Y.prototype, "navigation", void 0), e([h({
  constructOnly: !0
})], Y.prototype, "renderCanvas", void 0), e([h()], Y.prototype, "renderingOptions", void 0), e([h({
  constructOnly: !0
})], Y.prototype, "supersampleScreenshotsEnabled", void 0), e([h({
  readOnly: !0
})], Y.prototype, "supportsGround", void 0), e([h()], Y.prototype, "defaultsFromMapSettings", null), e([h({
  readOnly: !0
})], Y.prototype, "typeSpecificPreconditionsReady", null), e([h({
  type: $
})], Y.prototype, "background", null), e([h()], Y.prototype, "center", null), e([h({
  type: i
})], Y.prototype, "floors", void 0), e([h()], Y.prototype, "grid", void 0), e([h({
  type: U
})], Y.prototype, "highlightOptions", null), e([h()], Y.prototype, "map", void 0), e([h()], Y.prototype, "padding", null), e([h({
  readOnly: !0
})], Y.prototype, "rendering", null), e([h({
  readOnly: !0
})], Y.prototype, "resolution", null), e([h()], Y.prototype, "scale", null), e([h({
  constructOnly: !0
})], Y.prototype, "spatialReferenceLocked", void 0), e([h({
  readOnly: !0
})], Y.prototype, "tileInfo", null), e([h({
  type: P,
  readOnly: !0
})], Y.prototype, "timeline", void 0), e([h({
  readOnly: !0
})], Y.prototype, "type", void 0), e([h({
  readOnly: !0
})], Y.prototype, "updating", null), e([h({
  readOnly: !0
})], Y.prototype, "view2dType", void 0), e([h({
  readOnly: !0
})], Y.prototype, "visibleArea", null), e([h()], Y.prototype, "zoom", null), e([h({
  readOnly: !0
})], Y.prototype, "navigating", null), e([h(), g(e => e instanceof A ? e : c(N, e))], Y.prototype, "ui", void 0), Y = e([d("esri.views.View2D")], Y);
const ee = Y;
export { ee as default };