import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, shareReplay, switchMap, take, takeLast, tap} from 'rxjs/operators';
import {infiniteScrollObservable} from '../../helpers/pagination';
import {ProjectObjectService} from '../../services/project-object.service';
import {PaulaObject} from '../../models/paula-object';
import {PaulaObjectType} from '../../models/paula-object-type';
import {ProjectJobsObjectSearchState} from '../../models/project-jobs-object-search';
import {ToastrService} from 'ngx-toastr';
import {ObjectQRSearchResponse} from '../../models/object-qr-search-response';

@Component({
    selector: 'app-object-list',
    templateUrl: './object-list.component.html',
    standalone: false
})
export class ObjectListComponent {
    loadNext$ = new BehaviorSubject<unknown>(null);
    refresh$ = new BehaviorSubject<unknown>(null);
    search$ = new BehaviorSubject<ProjectJobsObjectSearchState>({ type: 'text', value: '' });
    foundObjectsNotAvailableInProject = false;
    totalElements: number | null = null;

    resultState$: Observable<ObjectQRSearchResponse & ProjectJobsObjectSearchState> = this.search$.pipe(
        switchMap<ProjectJobsObjectSearchState, Observable<ObjectQRSearchResponse & ProjectJobsObjectSearchState>>(searchState => {
            const project = this.project;
            if (project === null) {
                throw new Error('Project is not set');
            }

            const objectTypes = (this.objectTypes || []).map(it => it.id).join(',')
            if (searchState.type === 'text') {
                // Normal text search
                return infiniteScrollObservable(this.loadNext$, (page) => {
                    return this.projectObjectService.search(
                        project,
                        page,
                        searchState.value || '*',
                        {objectTypes}
                    ).pipe(
                        tap(response => {
                            this.totalElements = response.totalElements;
                        })
                    );
                }).pipe(
                    map((hits) => ({
                        hits,
                        notFoundForObjectType: false,
                        ...searchState
                    }))
                )
            } else {
                // QR Search
                return this.projectObjectService.qr(
                    project,
                    searchState.value,
                    {objectTypes}
                ).pipe(
                    map(result => ({
                        hits: result.hits,
                        notFoundForObjectType: result.notFoundForObjectType,
                        type: searchState.type,
                        value: searchState.value,
                    }))
                );
            }
        }),
        tap((resultState: ObjectQRSearchResponse & ProjectJobsObjectSearchState) => {
            if (resultState.notFoundForObjectType) {
                this.toastr.error('De gevonden objecten zijn niet geldig voor het huidige formulier');
            }

            if (resultState.type === "qrCode" &&  resultState.hits.length === 1) {
                // auto select first object and continue job creation
                this.selected.emit(resultState.hits[0]);
            }
        }),
        shareReplay(1)
    );

    @Output() selected = new EventEmitter<PaulaObject>();
    @Output() objectCreateClicked = new EventEmitter<void>();

    @Input() project: number | null = null;
    @Input() objectTypes: PaulaObjectType[] = [];
    @Input() showFooter = false;
    @Input() allowObjectCreate = false;

    constructor(
        @Inject('ProjectObjectService') private projectObjectService: ProjectObjectService,
        private toastr: ToastrService
    ) {}

    select(projectObject: PaulaObject) {
        this.selected.emit(projectObject);
    }

    scrollEnd(event: Event) {
        const target = event.target as HTMLDivElement;
        if (target.clientHeight + target.scrollTop >= target.scrollHeight) {
            this.loadMore();
        }
    }

    loadMore() {
        this.resultState$.pipe(take(2), takeLast(1));
        this.loadNext$.next(null);
    }

    onSearchChange(search: ProjectJobsObjectSearchState) {
        this.foundObjectsNotAvailableInProject = false;
        this.search$.next(search);
    }
}

