// @ts-strict-ignore
import {Component, Input, OnDestroy} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {FileUtils} from '../../utils/file-utils';

/**
 * This element allows for the creation of memory safe URLs if we are dealing with a data url.
 * As there is a bug in the webkit engine that leaks memory in the case of using data URLs on img srcs.
 * This can be avoided by using Blob URIs, which do not have this issue
 * https://bugs.chromium.org/p/chromium/issues/detail?id=309543
 * https://stackoverflow.com/questions/9913765/rapidly-updating-image-with-data-uri-causes-caching-memory-leak/38624675#38624675
 */
@Component({
    selector: 'app-memsafe-img',
    templateUrl: './memsafe-img.component.html',
    standalone: false
})
export class MemsafeImgComponent implements OnDestroy {

    _src: string;
    _safeUrl: SafeResourceUrl;
    private usesBlobUrl = false;

    constructor(
        private sanitizer: DomSanitizer
    ) {
    }

    @Input() class: string;

    @Input() set src(sourceString: string) {
        if (sourceString !== this._src) {
            this.clearBlobMemory();
        }

        let url = sourceString;
        const isDataUri = FileUtils.isDataUri(sourceString);
        if (isDataUri) {
            url = FileUtils.dataURIToBlobURI(sourceString);
        }
        this.usesBlobUrl = FileUtils.isBlobUri(url);

        this._src = url;
        this._safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    ngOnDestroy(): void {
        this.clearBlobMemory();
    }

    private clearBlobMemory() {
        if (this._src && this.usesBlobUrl) {
            URL.revokeObjectURL(this._src);
        }
    }

}
