import {Component, Input} from '@angular/core';
import {PaulaObject} from '../../models/paula-object';

@Component({
    selector: 'app-object-detail',
    templateUrl: './object-detail.component.html',
    standalone: false
})
export class ObjectDetailComponent {
    @Input() paulaObject: PaulaObject | undefined | null;
}
