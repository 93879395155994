/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { DragEventSeparator as t } from "../../../input/DragEventSeparator.js";
import { InputHandler as a } from "../../../input/InputHandler.js";
import { eventMatchesPointerActions as i } from "../../../input/handlers/support.js";
class o extends a {
  constructor(a, o, r) {
    super(!0), this._view = a, this.pointerActions = o;
    const e = this._view.mapViewNavigation;
    this._dragEventSeparator = new t({
      start: (t, a) => {
        e.rotate.begin(this._view, a.data), a.stopPropagation();
      },
      update: (t, a) => {
        e.rotate.update(this._view, a.data), a.stopPropagation();
      },
      end: (t, a) => {
        e.rotate.end(), a.stopPropagation();
      },
      condition: (t, a) => 1 === t && i(a.data, this.pointerActions)
    }), this.registerIncoming("drag", r, t => this._dragEventSeparator.handle(t));
  }
}
export { o as DragRotate };