/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { clock as t } from "../../../core/clock.js";
import { InputHandler as e } from "../InputHandler.js";
import { DefaultParameters as i, getPointerId as o, manhattanDistance as a } from "./support.js";
class m extends e {
  constructor(e = i.maximumDoubleClickDelay, o = i.maximumDoubleClickDistance, a = i.maximumDoubleTouchDelay, m = i.maximumDoubleTouchDistance, n = t) {
    super(!1), this._maximumDoubleClickDelay = e, this._maximumDoubleClickDistance = o, this._maximumDoubleTouchDelay = a, this._maximumDoubleTouchDistance = m, this._clock = n, this._pointerState = new Map(), this._immediateDoubleClick = this.registerOutgoing("immediate-double-click"), this.registerIncoming("pointer-down", this._handlePointerDown.bind(this)), this.registerIncoming("pointer-up", this._handlePointerUp.bind(this));
  }
  onUninstall() {
    this._pointerState.forEach(t => {
      t.immediateDoubleClick && t.immediateDoubleClick.timeoutHandle.remove();
    }), super.onUninstall();
  }
  _handlePointerDown(t) {
    const e = t.data,
      i = o(e);
    if (!this._pointerState.has(i)) {
      const t = {
        downButton: e.native.button,
        x: e.x,
        y: e.y,
        immediateDoubleClick: null
      };
      this._pointerState.set(i, t), this.startCapturingPointer(e.native);
    }
  }
  _handlePointerUp(t) {
    const e = t.data,
      i = o(e),
      m = this._pointerState.get(i);
    if (m && m.downButton === e.native.button) {
      const i = m.immediateDoubleClick,
        o = "touch" === t.data.native.pointerType ? this._maximumDoubleTouchDistance : this._maximumDoubleClickDistance;
      i ? (i.timeoutHandle.remove(), a(i, t.data) > o ? this._startImmediateDoubleClick(t, m) : (this._immediateDoubleClick.emit(t.data, void 0, i.modifiers), this._removeState(e))) : a(m, t.data) > o ? this._removeState(e) : this._startImmediateDoubleClick(t, m);
    }
  }
  _startImmediateDoubleClick(t, e) {
    const i = "touch" === t.data.native.pointerType ? this._maximumDoubleTouchDelay : this._maximumDoubleClickDelay;
    e.immediateDoubleClick = {
      x: t.data.x,
      y: t.data.y,
      modifiers: t.modifiers,
      timeoutHandle: this._clock.setTimeout(() => this._removeState(t.data), i)
    };
  }
  _removeState(t) {
    const e = o(t);
    this._pointerState.delete(e), this.stopCapturingPointer(t.native), this.refreshHasPendingInputs();
  }
}
export { m as ImmediateDoubleClick };