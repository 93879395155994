/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import e from "../Viewpoint.js";
import { watch as i, sync as n } from "../core/reactiveUtils.js";
import { property as a } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as s } from "../core/accessorSupport/decorators/subclass.js";
import r from "./2d/FrameTask.js";
import { GoToManager as o } from "./2d/GoToManager.js";
import p from "./2d/MapViewConstraints.js";
import { ViewStateManager as g } from "./2d/ViewStateManager.js";
import l from "./2d/input/MapViewInputManager.js";
import { StationaryManager as h } from "./2d/support/StationaryManager.js";
import { ViewEvents as d } from "./input/ViewEvents.js";
const u = u => {
  let m = class extends u {
    constructor(...t) {
      super(...t), this.fullOpacity = 1, this.stateManager = new g({
        constraints: new p({
          view: this
        })
      }), this.goToManager = new o({
        view: this
      }), this.stationaryManager = new h(), this.mapViewNavigation = null, this.renderingOptions = {
        samplingMode: "dynamic",
        edgeLabelsVisible: !1,
        labelsAnimationTime: 0,
        labelCollisionsEnabled: !1
      }, this.frameTask = new r(this), this.inputManager = new l({
        view: this
      }), this.viewEvents = new d(this), this.padding = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }, this.addHandles([i(() => this.viewpoint, () => this.stationaryManager.flip(), n), this.on("resize", t => this.stateManager.resize(t.width, t.height))]);
    }
    get constraintsInfo() {
      return {
        lods: null,
        spatialReference: null
      };
    }
    get extent() {
      return this.stateManager?.extent ?? null;
    }
    set extent(t) {
      this.stateManager.extent = t;
    }
    get state() {
      return this.stateManager.state;
    }
    get interacting() {
      return this.navigating;
    }
    get stationary() {
      return !this.animation && !this.navigating && !this.resizing && this.stationaryManager.stationary;
    }
    set animation(t) {
      const e = this._get("animation");
      if (t === e) return;
      if (e && e.stop(), t !== this.animationManager.animation && this.animationManager.stop(), !t || t.isFulfilled()) return void this._set("animation", null);
      this._set("animation", t);
      const i = () => {
        this.destroyed || t === this._get("animation") && (this._set("animation", null), this.frameTask?.requestFrame());
      };
      t.when(i, i);
    }
    get constraints() {
      return this.stateManager?.constraints;
    }
    set constraints(t) {
      t.view = this;
      const e = this.stateManager.constraints;
      this.stateManager.constraints = t, e?.destroy();
    }
    get padding() {
      return this.stateManager?.padding;
    }
    set padding(t) {
      this.stateManager && (this.stateManager.padding = t);
    }
    get resizeAlign() {
      return this.stateManager.resizeAlign;
    }
    set resizeAlign(t) {
      this.stateManager.resizeAlign = t;
    }
    get rotation() {
      return this.stateManager.rotation ?? 0;
    }
    set rotation(t) {
      const {
        rotationEnabled: e
      } = this.constraints;
      this.constraints.rotationEnabled = !0, this.stateManager.rotation = t, this.constraints.rotationEnabled = e;
    }
    get viewpoint() {
      return this.stateManager.viewpoint ?? null;
    }
    set viewpoint(t) {
      this.stateManager.viewpoint = t, this.frameTask.requestFrame();
    }
    on(t, e, i, n) {
      const a = this.inputManager && this.viewEvents.on(t, e, i, n);
      return a || super.on(t, e);
    }
    hasEventListener(t) {
      return super.hasEventListener(t) || this.viewEvents.hasHandler(t);
    }
    goTo(t, e) {
      return this.goToManager.goTo(t, e);
    }
  };
  return t([a({
    readOnly: !0
  })], m.prototype, "animationManager", void 0), t([a({
    readOnly: !0
  })], m.prototype, "fullOpacity", void 0), t([a()], m.prototype, "stateManager", void 0), t([a()], m.prototype, "constraintsInfo", null), t([a()], m.prototype, "extent", null), t([a()], m.prototype, "goToManager", void 0), t([a({
    readOnly: !0
  })], m.prototype, "state", null), t([a({
    readOnly: !0
  })], m.prototype, "mapViewNavigation", void 0), t([a()], m.prototype, "renderingOptions", void 0), t([a({
    readOnly: !0
  })], m.prototype, "interacting", null), t([a()], m.prototype, "stationary", null), t([a()], m.prototype, "animation", null), t([a({
    type: p
  })], m.prototype, "constraints", null), t([a({
    readOnly: !0
  })], m.prototype, "inputManager", void 0), t([a()], m.prototype, "padding", null), t([a()], m.prototype, "resizeAlign", null), t([a()], m.prototype, "rotation", null), t([a({
    readOnly: !0
  })], m.prototype, "viewEvents", void 0), t([a({
    type: e
  })], m.prototype, "viewpoint", null), m = t([s("esri.views.Viewport2DMixin")], m), m;
};
export { u as Viewport2DMixin };