/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import t from "../Map.js";
import i from "../core/Accessor.js";
import { createTask as r } from "../core/asyncUtils.js";
import a from "../core/Collection.js";
import s from "../core/CollectionFlattener.js";
import { referenceSetter as o } from "../core/collectionUtils.js";
import n from "../core/Error.js";
import l from "../core/Evented.js";
import p from "../core/Handles.js";
import has from "../core/has.js";
import h from "../core/Loadable.js";
import d from "../core/Logger.js";
import { destroyMaybe as y, abortMaybe as u } from "../core/maybe.js";
import m from "../core/Promise.js";
import { after as c } from "../core/promiseUtils.js";
import { watch as g, whenOnce as f, when as w, sync as v, syncAndInitial as _ } from "../core/reactiveUtils.js";
import { property as M } from "../core/accessorSupport/decorators/property.js";
import "../core/RandomLCG.js";
import { subclass as V } from "../core/accessorSupport/decorators/subclass.js";
import { owningCollectionProperty as R } from "../core/support/OwningCollection.js";
import { UpdatingHandles as S } from "../core/support/UpdatingHandles.js";
import j from "../geometry/Extent.js";
import T from "../geometry/HeightModelInfo.js";
import E from "../geometry/SpatialReference.js";
import { equals as F } from "../geometry/support/spatialReferenceUtils.js";
import { AnalysesCollection as k } from "../support/AnalysesCollection.js";
import { GraphicsCollection as x } from "../support/GraphicsCollection.js";
import b from "../time/TimeExtent.js";
import { system as O, isTimeZoneValid as L } from "../time/timeZoneUtils.js";
import C from "./BasemapView.js";
import Z from "./LayerViewManager.js";
import I from "./Magnifier.js";
import H from "./SelectionManager.js";
import W from "./Theme.js";
import { ToolViewManager as D } from "./ToolViewManager.js";
import { makeDefaultHighlightOptions as q } from "./3d/support/DefaultHighlights.js";
import P from "./input/Input.js";
import { ViewEvents as U } from "./input/ViewEvents.js";
import A from "./navigation/Navigation.js";
import { DefaultsFromMap as z } from "./support/DefaultsFromMap.js";
import G from "./support/HighlightOptions.js";
import N from "./support/RequiredFieldsOptions.js";
var $;
let B = $ = class extends l.EventedMixin(m.EsriPromiseMixin(i)) {
  constructor(e) {
    super(e), this._userSpatialReference = null, this._cursor = null, this.handles = new p(), this.updatingHandles = new S(), this.allLayerViews = new s({
      getCollections: () => [this.basemapView?.baseLayerViews, this.groundView?.layerViews, this.layerViews, this.basemapView?.referenceLayerViews],
      getChildrenFunction: Q
    }), this.groundView = null, this.basemapView = null, this.displayFilterEnabled = !0, this.fatalError = null, this.graphics = new x(), this.analyses = new k(), this.typeSpecificPreconditionsReady = !0, this.layerViews = new a(), this.magnifier = new I(), this.padding = {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    }, this.ready = !1, this._readyStateWaitingTask = null, this.supportsGround = !0, this.type = null, this.scale = null, this.updating = !1, this.initialExtentRequired = !0, this.input = new P(), this.navigation = new A(), this.layerViewManager = null, this.analysisViewManager = null, this.isHeightModelInfoRequired = !1, this.width = null, this.height = null, this.resizing = !1, this.suspended = !1, this.viewEvents = new U(this), this.persistableViewModels = new a(), this.requiredFieldsOptions = new N(), this._isValid = !1, this._readyCycleForced = !1, this._lockedSpatialReference = null, this._userTimeZone = null, this._lockedTimeZone = null, this._userTimeExtent = null, this._lockedTimeExtent = null, this.theme = null, this.handles.add(g(() => this.preconditionsReady, e => {
      const t = this.ready;
      if (e ? (this._lockedSpatialReference = this.spatialReference, this._lockedTimeZone = this.timeZone, this._lockedTimeExtent = this.timeExtent, $.views.add(this)) : (this._lockedSpatialReference = null, $.views.remove(this)), this.notifyChange("spatialReference"), !e && t) this.toolViewManager?.detach(), null != this.analysisViewManager && this.analysisViewManager.detach(), this.layerViewManager?.clear(), this._teardown();else if (e && !t) {
        try {
          this._startup();
        } catch (i) {
          return void queueMicrotask(() => {
            this.fatalError = new n("startup-error", null, i);
          });
        }
        null != this.analysisViewManager && this.analysisViewManager.attach(), this.toolViewManager.attach();
      }
    }, v));
  }
  initialize() {
    this.addResolvingPromise(Promise.all([this.loadAsyncDependencies(), this.validate()]).then(() => (this._isValid = !0, f(() => this.ready)))), this.basemapView = new C({
      view: this
    }), this.layerViewManager = new Z({
      view: this,
      layerViewImporter: {
        importLayerView: e => this.importLayerView(e),
        hasLayerViewModule: e => this.hasLayerViewModule(e)
      },
      supportsGround: this.supportsGround
    }), this.toolViewManager = new D({
      view: this
    }), this.selectionManager = new H({
      view: this
    }), this.addHandles([w(() => "map-content-error" === this.readyState && !this.spatialReference, () => {
      d.getLogger(this).warn("#spatialReference", "no spatial reference could be derived from the currently added map layers");
    }), g(() => this.initialExtentRequired, e => this.defaultsFromMap.required = {
      ...this.defaultsFromMap.required,
      extent: e
    }, _), g(() => this.ready, e => {
      this.defaultsFromMap && (this.defaultsFromMap.suspended = e, this.defaultsFromMap.userSpatialReference = e ? this.spatialReference : this._userSpatialReference);
    }, v), g(() => this._userSpatialReference, e => {
      this.defaultsFromMap && (this.defaultsFromMap.userSpatialReference = e);
    }, _)]);
  }
  destroy() {
    this.destroyed || ($.views.remove(this), this.viewEvents.destroy(), this.allLayerViews.destroy(), this.navigation && (this.navigation.destroy(), this._set("navigation", null)), this.graphics = y(this.graphics), this.analyses = y(this.analyses), this.defaultsFromMap.destroy(), this._set("defaultsFromMap", null), y(this.analysisViewManager), this.toolViewManager = y(this.toolViewManager), this.layerViewManager = y(this.layerViewManager), this.selectionManager = y(this.selectionManager), this.basemapView = y(this.basemapView), this.groundView?.destroy(), this.layerViews?.forEach(e => e.destroy()), this.layerViews.length = 0, this.invalidate(), this._emitter.clear(), this.handles.destroy(), this.map = y(this.map), this.updatingHandles.destroy());
  }
  _startup() {
    this._set("ready", !0);
  }
  _teardown() {
    this._set("ready", !1);
  }
  whenReady() {
    return Promise.resolve(this);
  }
  toMap() {
    return d.getLogger(this).error("#toMap()", "Not implemented on this instance of View"), null;
  }
  get highlights() {
    return this._get("highlights") ?? q();
  }
  set highlights(e) {
    this._set("highlights", o(e, this._get("highlights")));
  }
  get activeTool() {
    return this.toolViewManager?.activeTool;
  }
  set activeTool(e) {
    this.toolViewManager && (this.toolViewManager.activeTool = e);
  }
  get animation() {
    return this._get("animation");
  }
  set animation(e) {
    this._set("animation", e);
  }
  get center() {
    return null;
  }
  get defaultsFromMapSettings() {
    return {};
  }
  get defaultsFromMap() {
    return new z({
      required: {
        tileInfo: !1,
        heightModelInfo: !1,
        extent: !1
      },
      map: () => this.map,
      getSpatialReferenceSupport: (e, t) => this.getSpatialReferenceSupport(e, t),
      ...this.defaultsFromMapSettings
    });
  }
  get extent() {
    return this._get("extent");
  }
  set extent(e) {
    this._set("extent", e);
  }
  get heightModelInfo() {
    return this.getDefaultHeightModelInfo();
  }
  get interacting() {
    return this.navigating;
  }
  get navigating() {
    return !1;
  }
  get preconditionsReady() {
    return !(this.fatalError || !this._isValid || this._readyCycleForced || !this.map || h.isLoadable(this.map) && !this.map.loaded || 0 === this.width || 0 === this.height || !this.spatialReference || !this._validateSpatialReference(this.spatialReference) || !this._lockedSpatialReference && !this.defaultsFromMap?.ready || !this.typeSpecificPreconditionsReady);
  }
  get resolution() {
    return 0;
  }
  set map(e) {
    e !== this._get("map") && (e?.destroyed && (d.getLogger(this).warn("#map", "The provided map is already destroyed", {
      map: e
    }), e = null), h.isLoadable(e) && e.load().catch(() => {}), this.constructed && !this.destroyed && (this.forceReadyCycle(), this._lockedSpatialReference = null), this._set("map", e));
  }
  get readyState() {
    if (this.destroyed) return this._get("readyState");
    if (!this.map) return "missing-map";
    if ("container" in this && !this.container) return "missing-container";
    if (this.fatalError) return "rendering-error";
    if ((this.defaultsFromMap?.ready ?? !1) && !this.spatialReference) {
      const e = !("loaded" in this.map) || this.map.loaded,
        t = this.map.ground.loaded,
        i = this.map.basemap?.loaded ?? !0;
      return e && i && t && !this.map?.allLayers.length ? "empty-map" : (this._readyStateWaitingTask || (this._readyStateWaitingTask = r(e => c(has("view-readyState-waiting-delay"), null, e)), this.addHandles(this._readyStateWaitingTask), this.addHandles(this._readyStateWaitingTask, "ready-state-task")), this._readyStateWaitingTask?.finished ? "map-content-error" : "loading");
    }
    return this._readyStateWaitingTask = u(this._readyStateWaitingTask), this.removeHandles("ready-state-task"), this.ready ? "ready" : "loading";
  }
  get spatialReference() {
    const e = this._userSpatialReference || this._lockedSpatialReference || this.getDefaultSpatialReference() || null;
    if (e && this.defaultsFromMap?.required?.heightModelInfo) {
      const t = e.clone();
      return t.vcsWkid = this.defaultsFromMap.vcsWkid, t.latestVcsWkid = this.defaultsFromMap.latestVcsWkid, t;
    }
    return e;
  }
  set spatialReference(e) {
    const t = !F(e, this._get("spatialReference"));
    this._set("_userSpatialReference", e), t && (this._set("spatialReference", e), this._spatialReferenceChanged(e));
  }
  _spatialReferenceChanged(e) {}
  get stationary() {
    return !this.animation && !this.navigating && !this.resizing;
  }
  get timeExtent() {
    return this._userTimeExtent ?? this._lockedTimeExtent ?? this.getDefaultTimeExtent() ?? null;
  }
  set timeExtent(e) {
    this._userTimeExtent = e;
  }
  get timeZone() {
    return this._userTimeZone ?? this._lockedTimeZone ?? this.getDefaultTimeZone() ?? O;
  }
  set timeZone(e) {
    this._userTimeZone = e, L(e) || d.getLogger(this).warn("#timeZone", `the parsed value '${e}' may not be a valid IANA time zone`);
  }
  get tools() {
    return this.toolViewManager?.tools;
  }
  get initialExtent() {
    return this.defaultsFromMap?.extent;
  }
  get cursor() {
    return this.toolViewManager?.cursor ?? this._cursor ?? "default";
  }
  set cursor(e) {
    this._cursor = e, this.notifyChange("cursor");
  }
  get size() {
    return [this.width, this.height];
  }
  get effectiveTheme() {
    return this.theme ?? new W();
  }
  whenLayerView(e) {
    return this.layerViewManager?.whenLayerView(e) ?? Promise.reject();
  }
  getDefaultSpatialReference() {
    return this.defaultsFromMap?.spatialReference;
  }
  getDefaultHeightModelInfo() {
    return (this.map && "heightModelInfo" in this.map ? this.map.heightModelInfo : void 0) ?? this.defaultsFromMap?.heightModelInfo ?? null;
  }
  getDefaultTimeZone() {
    return null;
  }
  getDefaultTimeExtent() {
    return null;
  }
  importLayerView(e) {
    throw new n("importLayerView() not implemented");
  }
  hasLayerViewModule(e) {
    return !1;
  }
  async validate() {}
  async loadAsyncDependencies() {}
  invalidate() {
    this._isValid = !1;
  }
  getSpatialReferenceSupport() {
    return {
      constraints: null
    };
  }
  _validateSpatialReference(e) {
    return null != this.getSpatialReferenceSupport(e);
  }
  when(e, t) {
    return this.isResolved() && !this.ready && d.getLogger(this).warn("#when()", "Calling view.when() while the view is no longer ready but was already resolved once will resolve immediately. Use reactiveUtils.whenOnce(() => view.ready).then(...) instead."), super.when(e, t);
  }
  forceReadyCycle() {
    this.ready && (w(() => this.destroyed || !1 === this.preconditionsReady, () => this._readyCycleForced = !1, {
      once: !0
    }), this._readyCycleForced = !0);
  }
  addAndActivateTool(e) {
    this.toolViewManager.tools.add(e), this.activeTool = e;
  }
  tryFatalErrorRecovery() {
    this.fatalError = null;
  }
};
B.views = new a(), e([M()], B.prototype, "_userSpatialReference", void 0), e([M({
  type: a.ofType(G)
})], B.prototype, "highlights", null), e([M()], B.prototype, "activeTool", null), e([M({
  readOnly: !0
})], B.prototype, "allLayerViews", void 0), e([M()], B.prototype, "groundView", void 0), e([M()], B.prototype, "animation", null), e([M()], B.prototype, "basemapView", void 0), e([M()], B.prototype, "center", null), e([M()], B.prototype, "defaultsFromMapSettings", null), e([M()], B.prototype, "defaultsFromMap", null), e([M()], B.prototype, "displayFilterEnabled", void 0), e([M()], B.prototype, "fatalError", void 0), e([M({
  type: j
})], B.prototype, "extent", null), e([M(R(x, "graphics"))], B.prototype, "graphics", void 0), e([M(R(k, "analyses"))], B.prototype, "analyses", void 0), e([M({
  readOnly: !0,
  type: T
})], B.prototype, "heightModelInfo", null), e([M({
  readOnly: !0
})], B.prototype, "interacting", null), e([M({
  readOnly: !0
})], B.prototype, "navigating", null), e([M({
  readOnly: !0,
  dependsOn: ["fatalError", "_isValid", "_readyCycleForced", "map", "map.loaded?", "width", "height", "spatialReference", "_lockedSpatialReference", "defaultsFromMap.ready", "typeSpecificPreconditionsReady"]
})], B.prototype, "preconditionsReady", null), e([M({
  readOnly: !0
})], B.prototype, "typeSpecificPreconditionsReady", void 0), e([M({
  type: a,
  readOnly: !0
})], B.prototype, "layerViews", void 0), e([M()], B.prototype, "resolution", null), e([M({
  type: I
})], B.prototype, "magnifier", void 0), e([M({
  value: null,
  type: t
})], B.prototype, "map", null), e([M()], B.prototype, "padding", void 0), e([M({
  readOnly: !0
})], B.prototype, "ready", void 0), e([M()], B.prototype, "_readyStateWaitingTask", void 0), e([M({
  readOnly: !0
})], B.prototype, "readyState", null), e([M({
  type: E
})], B.prototype, "spatialReference", null), e([M()], B.prototype, "stationary", null), e([M({
  readOnly: !0
})], B.prototype, "supportsGround", void 0), e([M({
  type: b
})], B.prototype, "timeExtent", null), e([M({
  type: String,
  nonNullable: !0
})], B.prototype, "timeZone", null), e([M()], B.prototype, "tools", null), e([M()], B.prototype, "toolViewManager", void 0), e([M({
  readOnly: !0
})], B.prototype, "type", void 0), e([M({
  type: Number
})], B.prototype, "scale", void 0), e([M({
  readOnly: !0
})], B.prototype, "updating", void 0), e([M({
  readOnly: !0
})], B.prototype, "initialExtentRequired", void 0), e([M({
  readOnly: !0
})], B.prototype, "initialExtent", null), e([M()], B.prototype, "cursor", null), e([M({
  readOnly: !0
})], B.prototype, "input", void 0), e([M({
  type: A,
  nonNullable: !0
})], B.prototype, "navigation", void 0), e([M()], B.prototype, "layerViewManager", void 0), e([M()], B.prototype, "analysisViewManager", void 0), e([M()], B.prototype, "selectionManager", void 0), e([M()], B.prototype, "width", void 0), e([M()], B.prototype, "height", void 0), e([M({
  readOnly: !0
})], B.prototype, "resizing", void 0), e([M({
  value: null,
  readOnly: !0
})], B.prototype, "size", null), e([M({
  readOnly: !0
})], B.prototype, "suspended", void 0), e([M({
  readOnly: !0
})], B.prototype, "viewEvents", void 0), e([M({
  readOnly: !0
})], B.prototype, "persistableViewModels", void 0), e([M()], B.prototype, "_isValid", void 0), e([M()], B.prototype, "_readyCycleForced", void 0), e([M()], B.prototype, "_lockedSpatialReference", void 0), e([M()], B.prototype, "_userTimeZone", void 0), e([M()], B.prototype, "_lockedTimeZone", void 0), e([M()], B.prototype, "_userTimeExtent", void 0), e([M()], B.prototype, "_lockedTimeExtent", void 0), e([M({
  type: W
})], B.prototype, "theme", void 0), e([M({
  readOnly: !0,
  type: W
})], B.prototype, "effectiveTheme", null), B = $ = e([V("esri.views.View")], B);
const J = globalThis.$arcgis;
J && !J.views && Object.defineProperty(J, "views", {
  configurable: !1,
  enumerable: !0,
  writable: !1,
  value: B.views
});
const K = B;
function Q(e) {
  return e.layerViews;
}
export { K as default };