/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import "../../intl.js";
import o from "../../core/Accessor.js";
import { isSome as e } from "../../core/arrayUtils.js";
import n from "../../core/Evented.js";
import { makeHandle as i } from "../../core/handleUtils.js";
import { watch as r, initial as s } from "../../core/reactiveUtils.js";
import { property as a } from "../../core/accessorSupport/decorators/property.js";
import { cast as p } from "../../core/accessorSupport/decorators/cast.js";
import "../../core/has.js";
import { subclass as d } from "../../core/accessorSupport/decorators/subclass.js";
import { setCalciteThemeClass as c } from "../../support/themeUtils.js";
import l from "./Component.js";
import { isRTL as h } from "../../widgets/support/widgetUtils.js";
import { getLocale as m, onLocaleChange as u } from "../../intl/locale.js";
const f = {
    left: 0,
    top: 0,
    bottom: 0,
    right: 0
  },
  _ = {
    bottom: 30,
    top: 15,
    right: 15,
    left: 15
  },
  g = "esri-ui",
  y = {
    ui: g,
    corner: `${g}-corner`,
    innerContainer: `${g}-inner-container`,
    manualContainer: `${g}-manual-container`,
    cornerContainer: `${g}-corner-container`,
    topLeft: `${g}-top-left`,
    topRight: `${g}-top-right`,
    bottomLeft: `${g}-bottom-left`,
    bottomRight: `${g}-bottom-right`
  };
function C(t) {
  return t && !t._started && "function" == typeof t.postMixInProperties && "function" == typeof t.buildRendering && "function" == typeof t.postCreate && "function" == typeof t.startup;
}
function v(t) {
  return 0 === t ? "0" : `${t}px`;
}
function b(t) {
  const o = "object" == typeof t && null !== t && Object.getPrototypeOf(t);
  return (null === o || o === Object.prototype) && ("component" in t || "index" in t || "position" in t) ? t : null;
}
function w(t, {
  top: o,
  bottom: e,
  left: n,
  right: i
}) {
  t.style.top = o, t.style.bottom = e, t.style.left = n, t.style.right = i;
}
let P = class extends n.EventedAccessor {
  constructor(t) {
    super(t), this._cornerNameToContainerLookup = {}, this._positionNameToContainerLookup = {}, this._components = new Array(), this._componentMap = new Map(), this._removeWidgetHandleKey = Symbol("componentOnRemoveSymbol"), this._locale = m(), this.view = null, this._applyViewPadding = () => {
      const t = this.container;
      t && w(t, this._toPixelPosition(this._getViewPadding()));
    }, this._applyUIPadding = () => {
      const t = this._innerContainer;
      t && w(t, this._toPixelPosition(this.padding));
    }, this._initContainers();
  }
  initialize() {
    this.addHandles([r(() => [this.view?.padding, this.container], this._applyViewPadding, s), r(() => this.padding, this._applyUIPadding, s), r(() => [this.container, this._locale], ([t, o]) => {
      t && t.setAttribute("lang", o);
    }, s), u(t => {
      this._locale = t;
    })]);
  }
  destroy() {
    this.container = null;
    for (const t of this._components) t.destroy();
    this._components.length = 0, this._componentMap.clear();
  }
  set container(t) {
    const o = this._get("container");
    t !== o && (t && (t.classList.add(y.ui), c(t), this._attachContainers(t)), o && (o.classList.remove(y.ui), w(o, {
      top: "",
      bottom: "",
      left: "",
      right: ""
    }), o.textContent = ""), this._set("container", t));
  }
  get height() {
    const t = this.view?.height ?? 0;
    if (0 === t) return t;
    const o = this._getViewPadding(),
      {
        top: e,
        bottom: n
      } = o;
    return Math.max(t - e - n, 0);
  }
  get padding() {
    return this._get("padding");
  }
  set padding(t) {
    this._overrideIfSome("padding", t);
  }
  castPadding(t) {
    return "number" == typeof t ? {
      bottom: t,
      top: t,
      right: t,
      left: t
    } : {
      ..._,
      ...t
    };
  }
  get width() {
    const t = this.view?.width ?? 0;
    if (0 === t) return t;
    const o = this._getViewPadding(),
      {
        left: e,
        right: n
      } = o;
    return Math.max(t - e - n, 0);
  }
  add(t, o) {
    let e, n, i;
    if (Array.isArray(t)) return void t.forEach(t => this.add(t, o));
    const r = b(t);
    r && ({
      index: e,
      position: o,
      component: t,
      key: n
    } = r), o && "object" == typeof o && ({
      index: e,
      key: n,
      position: o,
      internal: i
    } = o), !t || o && !this._isValidPosition(o) || this._add(t, o, e, n, i);
  }
  remove(t, o) {
    if (!t) return;
    if (Array.isArray(t)) return t.map(t => this.remove(t, o));
    const e = this._find(t);
    if (e) {
      if (this._componentMap.has(e) && this._componentMap.get(e)?.key !== o) return;
      const t = this._components.indexOf(e),
        n = e.node.parentNode;
      return n?.removeChild(e.node), this._componentMap.delete(e), e.widget?.removeHandlesReference(this._removeWidgetHandleKey), this._components.splice(t, 1)[0];
    }
  }
  empty(t, o = {
    removeInternal: !1
  }) {
    if (Array.isArray(t)) {
      for (const e of t) this.empty(e, o);
      return;
    }
    const e = this._positionNameToContainerLookup[t ?? "manual"],
      n = Array.prototype.slice.call(e.children).map(t => this._findByNode(t)).filter(t => {
        if (null == t) return !1;
        return !(this._componentMap.get(t)?.internal ?? !1) || o.removeInternal;
      });
    for (const i of n) this.remove(i);
  }
  move(t, o) {
    if (Array.isArray(t) && t.forEach(t => this.move(t, o)), !t) return;
    let e;
    const n = b(t) || b(o);
    if (n && (e = n.index, o = n.position, t = n.component || t), o && !this._isValidPosition(o)) return;
    const i = this.remove(t);
    i && this.add(i, {
      position: o,
      index: e
    });
  }
  find(t) {
    if (!t) return null;
    const o = this._findById(t);
    return o && (o.widget || o.node);
  }
  getComponents(t, o = {
    includeInternal: !1
  }) {
    return t ? Array.isArray(t) ? t.flatMap(t => this._getComponentsAtPosition(t, o)) : this._getComponentsAtPosition(t, o) : this._components.filter(t => o.includeInternal || !this._componentMap.get(t)?.internal).map(({
      widget: t,
      node: o
    }) => t ?? o);
  }
  getPosition(t) {
    for (const o in this._positionNameToContainerLookup) {
      if (this._positionNameToContainerLookup[o].contains(t)) return o;
    }
    return null;
  }
  _add(t, e, n, r, s) {
    t instanceof l || (t = new l({
      node: t
    }));
    const {
      widget: a
    } = t;
    null != a && a instanceof o && a.addHandles(i(() => {
      queueMicrotask(() => this.remove(t));
    }), this._removeWidgetHandleKey), this._place({
      component: t,
      position: e,
      index: n
    }), this._components.push(t), this._componentMap.set(t, {
      key: r,
      internal: s
    });
  }
  _find(t) {
    return t ? t instanceof l ? this._findByComponent(t) : "string" == typeof t ? this._findById(t) : this._findByNode(t.domNode || t) : null;
  }
  _getViewPadding() {
    return this.view?.padding ?? f;
  }
  _attachContainers(t) {
    t.appendChild(this._innerContainer), t.appendChild(this._manualContainer);
  }
  _initContainers() {
    const t = document.createElement("div");
    t.classList.add(y.innerContainer, y.cornerContainer);
    const o = document.createElement("div");
    o.classList.add(y.innerContainer, y.manualContainer);
    const e = document.createElement("div");
    e.classList.add(y.topLeft, y.corner), t.appendChild(e);
    const n = document.createElement("div");
    n.classList.add(y.topRight, y.corner), t.appendChild(n);
    const i = document.createElement("div");
    i.classList.add(y.bottomLeft, y.corner), t.appendChild(i);
    const r = document.createElement("div");
    r.classList.add(y.bottomRight, y.corner), t.appendChild(r), this._innerContainer = t, this._manualContainer = o;
    const s = h();
    this._cornerNameToContainerLookup = {
      "top-left": e,
      "top-right": n,
      "bottom-left": i,
      "bottom-right": r,
      "top-leading": s ? n : e,
      "top-trailing": s ? e : n,
      "bottom-leading": s ? r : i,
      "bottom-trailing": s ? i : r
    }, this._positionNameToContainerLookup = {
      manual: o,
      ...this._cornerNameToContainerLookup
    };
  }
  _isValidPosition(t) {
    return !!this._positionNameToContainerLookup[t];
  }
  _place(t) {
    const o = t.position ?? "manual",
      {
        component: e,
        index: n
      } = t,
      i = this._positionNameToContainerLookup[o],
      r = null != n && n > -1;
    if (C(e.widget) && e.widget.startup(), !r) return void i.appendChild(e.node);
    const s = Array.from(i.children);
    if (0 === n) {
      const {
        firstChild: t
      } = i;
      return void (t ? t.parentNode?.insertBefore(e.node, t) : i.appendChild(e.node));
    }
    if (n >= s.length) return void i.appendChild(e.node);
    const a = s[n];
    a.parentNode?.insertBefore(e.node, a);
  }
  _toPixelPosition(t) {
    return {
      top: v(t.top),
      left: v(t.left),
      right: v(t.right),
      bottom: v(t.bottom)
    };
  }
  _findByComponent(t) {
    return this._components.find(o => o === t) ?? null;
  }
  _findById(t) {
    return this._components.find(({
      id: o
    }) => o === t) ?? null;
  }
  _findByNode(t) {
    return this._components.find(({
      node: o
    }) => o === t) ?? null;
  }
  _getComponentsAtPosition(t, o) {
    const n = this._positionNameToContainerLookup[t];
    return Array.prototype.slice.call(n.children).map(t => this._findByNode(t)).filter(e).filter(t => o.includeInternal || !this._componentMap.get(t)?.internal).map(({
      widget: t,
      node: o
    }) => t ?? o);
  }
};
t([a()], P.prototype, "_locale", void 0), t([a()], P.prototype, "container", null), t([a()], P.prototype, "height", null), t([a({
  value: _
})], P.prototype, "padding", null), t([p("padding")], P.prototype, "castPadding", null), t([a()], P.prototype, "view", void 0), t([a()], P.prototype, "width", null), P = t([d("esri.views.ui.UI")], P);
const L = P;
export { L as default };