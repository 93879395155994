import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatestWith, map, switchMap} from 'rxjs/operators';
import {combineLatest, firstValueFrom, Observable, Subscription} from 'rxjs';
import {FormService} from '../../services/form.service';
import {FormUtils} from '../../utils/form-utils';
import {ToastrService} from 'ngx-toastr';
import {FormImageService} from '../../services/form-image.service';
import {ProjectJobAnswer, ProjectJobAnswerValue} from '../../models/project-job-answer';
import {paramMapGetNumberOrFail} from '../../utils/param-map-util';
import {AnyProjectJobForm} from '../../models/project-job-form';
import {Question} from '../../models/question/question';
import {FormSyncService} from '../../services/form-sync.service';
import {ProjectJobLocationAnswer} from '../../models/project-job-form-extra';
import {ProjectJobService} from '../../services/project-job.service';
import {IndexedLayerNode} from '../../models/layered-form-node';
import {WorkspaceConfigService} from '../../services/workspace-config.service';
import {AnswerOverviewMenuService} from '../../services/answer-overview-menu.service';

@Component({
    selector: 'app-question-footer',
    templateUrl: './question-footer.component.html',
    standalone: false
})
export class QuestionFooterComponent implements OnInit, OnDestroy {
    @Input() currentValue: ProjectJobAnswerValue | null = null;
    @Input() locationValue: ProjectJobLocationAnswer | null = null;
    @Input() isLocationQuestion = false;
    @Input() public canGoForward = false;
    @Input() public doGoForward: Observable<void> | null = null;
    @Output() public tapOnInvalidButton = new EventEmitter<void>();

    public showSpinner$ = combineLatest([
        this.formImageService.isUploading$,
        this.formService.saving$,
    ]).pipe(map(loading => loading.some(l => l)));

    public current$ = combineLatest([this.route.params, this.route.parent?.params ?? []])
        .pipe(
            map(([{ question }, { job }]) => ({ question: +question, job: +job})),
        );

    public node$ = this.route
        .data
        .pipe(
            map(({ node }) => node as IndexedLayerNode),
        );

    public question$ = this.current$
        .pipe(
            combineLatestWith(this.node$),
            switchMap(([{ question, job }, node]) => this.formService.getQuestionAsObservable(job, question, node)),
        );

    public form$ = this.current$
        .pipe(
            switchMap(({ job }) => this.formService.getJobOrFail(job)),
        );

    public canGoBack$ = combineLatest([this.route.params, this.form$, this.node$])
        .pipe(
            map(([{ question }, form, node]) => FormUtils.getPreviousPosition(form, +question, node) !== null),
        );

    public subscriptions: Subscription[] = [];

    constructor(private router: Router,
                private route: ActivatedRoute,
                private formService: FormService,
                private formImageService: FormImageService,
                @Inject('ProjectJobService') private projectJobService: ProjectJobService,
                private toastr: ToastrService,
                private formSyncService: FormSyncService,
                private workspaceConfigService: WorkspaceConfigService,
                private answerOverviewMenuService: AnswerOverviewMenuService) {
    }

    async ngOnInit() {
        const doGoForwardFeatureToggleEnabled = await firstValueFrom(this.workspaceConfigService.isFeatureEnabled('goToNextQuestionOnSubmit'));
        if (this.doGoForward && doGoForwardFeatureToggleEnabled) {
            this.subscriptions.push(this.doGoForward.subscribe(() => {
                this.goForward().catch(err => console.error('goForward failed', err));
            }));
        }
    }

    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    public async goBack() {
        const canGoBack = await firstValueFrom(this.canGoBack$);

        if (!canGoBack) {
            return;
        }

        const question = await firstValueFrom(this.question$);
        const form = await firstValueFrom(this.form$);
        const node = await firstValueFrom(this.node$);

        if (!question || !form) {
            return;
        }

        const previousPosition = FormUtils.getPreviousPosition(form, question.position, node);

        if (previousPosition) {
            await this.router.navigate(['..', previousPosition], { relativeTo: this.route, queryParamsHandling: 'preserve' });
        }
    }

    public async goForward() {
        if (!this.canGoForward) {
            this.tapOnInvalidButton.emit();
            return;
        }

        await this.saveAnswer();

        const question = paramMapGetNumberOrFail(this.route.snapshot.paramMap, 'question');
        const form = await firstValueFrom(this.form$);
        const node = await firstValueFrom(this.node$);

        if (!question || !form) {
            return;
        }

        const nextPosition = FormUtils.getNextPosition(form, question, node);

        if (nextPosition) {
            await this.router.navigate(['..', nextPosition], { relativeTo: this.route, queryParamsHandling: 'preserve' });
        } else {
            const isLayeredForm = !!node;

            if (isLayeredForm) {
                // If the form is layered, we want to go back to the overview
                // except, if the form was started by creating a leaf. If so, a returnTo is set

                const returnUrl = this.route.snapshot.queryParamMap.get('returnTo');

                if (returnUrl) {
                    await this.router.navigateByUrl(returnUrl);
                } else {
                    await this.router.navigate(['overview'], {relativeTo: this.route.parent});
                }
            } else {
                this.answerOverviewMenuService.$open.next();
            }
        }
    }

    public async saveAnswer(synchronize = true) {
        try {
            this.formService.saving$.next(true);

            const form = await firstValueFrom(this.form$);
            const node = await firstValueFrom(this.node$);
            const currentPendingAnswer = this.currentValue;

            if (this.isLocationQuestion) {
                await this.saveLocationAnswer(form);
            } else {
                const question = await firstValueFrom(this.question$);
                if (question === null) {
                    throw new Error('No current question found');
                }

                const currentAnswer = form && FormUtils.getLatestAnswer(form, question.position, node);

                // Post answer if new answer is given or current answer is changed
                if ((!currentAnswer && currentPendingAnswer?.value)
                    || (currentAnswer && currentAnswer.value !== currentPendingAnswer?.value)
                    || (currentAnswer && currentAnswer.remarkText !== currentPendingAnswer?.remarkText)
                    || (currentAnswer && currentAnswer.remarkImage !== currentPendingAnswer?.remarkImage)
                ) {
                    const jobAnswer = await this.makeJobAnswer(form, question, node);
                    await this.formSyncService.saveAnswer(paramMapGetNumberOrFail(this.route.parent?.snapshot.paramMap, 'project'), jobAnswer);
                }

                // Sync in background
                this.formSyncService.sync().catch(error => {
                    console.error('Background sync error', error);
                });
            }
        } catch (error) {
            console.error('saveAnswer error', error);
            await this.toastr.error('Antwoord opslaan mislukt');
            throw error; // re-throw for page handling/toast msg reasons
        } finally {
            this.formService.saving$.next(false);
        }
    }

    private async saveLocationAnswer(form: AnyProjectJobForm) {
        if (!this.locationValue) {
            throw new Error('No location value set');
        }

        await this.formService.mergeForm({
            ...form,
            extraFields: {
                ...form.extraFields,
                ...this.locationValue
            }
        });

        await firstValueFrom(
            this.projectJobService.saveLocationQuestionAnswer(
                form.project,
                form.id,
                this.locationValue
            )
        );
    }

    private async makeJobAnswer(form: AnyProjectJobForm, question: Question, node?: IndexedLayerNode): Promise<ProjectJobAnswer> {
        return {
            id: null,
            job: form.id,
            position: question.position,
            value: this.currentValue?.value ?? null,
            remarkText: this.currentValue?.remarkText ?? null,
            remarkImage: this.currentValue?.remarkImage ?? null,
            revision: form.answerRevision,
            node: node || null,
            updatedAt: new Date(),
        };
    }
}
