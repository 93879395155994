import {Injectable} from '@angular/core';
import {PictureUtil} from '../utils/picture';

export type ImageOutput = 'image/png' | 'image/jpeg';

@Injectable({
    providedIn: 'root'
})
export class PdfReaderService {

    // The workerSrc option should always be set,
    // in order to prevent any issues when using the PDF.js library
    async pdfJsLib() {
        const {GlobalWorkerOptions, getDocument} = (await import('pdfjs-dist'));
        GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs'
        return getDocument;
    }

    async toImageBlob(input: File, imageOutput: ImageOutput = 'image/png'): Promise<Blob[]> {
        const getDocument = await this.pdfJsLib();

        const pdfPagePromises: Promise<unknown>[] = [];
        const imageBlobs: Blob[] = [];

        try {
            const pdfDocument = await getDocument(URL.createObjectURL(input)).promise;

            for (let i = 1; i < pdfDocument.numPages + 1; ++i) {
                pdfPagePromises.push(pdfDocument.getPage(i).then(async (page) => {
                    const viewport = page.getViewport({scale: 1, rotation: 0, dontFlip: false});

                    // Prepare canvas using PDF page dimensions
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    if (context === null) {
                        throw new Error('Could not get canvas context');
                    }

                    canvas.height = viewport.viewBox[3];
                    canvas.width = viewport.viewBox[2];

                    // Render PDF page into canvas context
                    await page.render({
                        canvasContext: context,
                        viewport: viewport
                    }).promise;
                    imageBlobs.push(PictureUtil.dataURItoBlob(canvas.toDataURL(imageOutput)));
                }));
            }

            // Wait for all PDF page promises
            await Promise.all(pdfPagePromises);
            return imageBlobs;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}
