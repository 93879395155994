import {Component, Inject, Input} from '@angular/core';
import {ObjectListModalComponent} from '../../object-list-modal/object-list-modal.component';
import {ProjectObjectService} from '../../../services/project-object.service';
import {BehaviorSubject, of, switchMap} from 'rxjs';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormControl, Validators} from '@angular/forms';
import {FormUtils} from '../../../utils/form-utils';
import {ObjectQuestion} from '../../../models/question/object-question';
import {ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {controlValueChanges} from '../../../utils/form-control-value';
import {combineLatest} from 'rxjs';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';
import {PopupService} from '../../../services/popup.service';
import {PaulaObject} from '../../../models/paula-object';

@Component({
    selector: 'app-question-v2-object',
    templateUrl: './question-v2-object.component.html',
    standalone: false
})
export class QuestionV2ObjectComponent {
    private currentForm = new BehaviorSubject<AnyProjectJobForm | null>(null);
    public currentQuestion: ObjectQuestion | null = null;
    public currentNode: AnyLayeredFormNode | undefined = undefined;

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm.next(form);
        this.updateAnswer();
    }
    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNode = node;
        this.updateAnswer();
    }
    @Input({required: true}) set question(question: ObjectQuestion) {
        this.currentQuestion = question;

        if (this.currentQuestion.required) {
            this.control.addValidators(Validators.required);
        } else {
            this.control.removeValidators(Validators.required);
        }

        this.updateAnswer();
    }

    public disabled = false;
    public control = new FormControl({ value: '', disabled: false }, { nonNullable: true });

    object$ = combineLatest([
        controlValueChanges(this.control),
        this.currentForm,
    ]).pipe(
        switchMap(([controlValue, currentForm]) => {
            if (controlValue === '' || currentForm === null) {
                return of(null);
            }

            return this.projectObjectService.get(currentForm.project, +controlValue);
        })
    );

    constructor(
        private popupService: PopupService,
        @Inject('ProjectObjectService') private projectObjectService: ProjectObjectService,
    ) {
    }

    get currentValue(): ProjectJobAnswerValue {
        return {
            value: this.control.value,
            remarkText: null,
            remarkImage: null,
        }
    }

    async openModal() {
        const popup = this.popupService.open(
            ObjectListModalComponent, {
                data: {
                    project: this.currentForm.value?.project
                }
            });

        const result = await popup.result<PaulaObject>();
        if (result) {
            this.control.setValue(result.id.toString());
        }
    }

    private updateAnswer() {
        if (!this.currentForm.value || !this.currentQuestion) {
            return;
        }

        const latestAnswer = FormUtils.getLatestAnswer(this.currentForm.value, this.currentQuestion.position, this.currentNode)?.value;
        if (latestAnswer) {
            this.control.setValue(latestAnswer);
        } else {
            this.control.reset();
        }
    }
}
