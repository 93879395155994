import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PopupRef} from '../../helpers/popup-ref';
import {PopupConfig} from '../../models/popup-config';
import {AnyLayeredFormNode} from '../../models/layered-form-node';
import {PopupService} from '../../services/popup.service';
import {DeleteLayeredNodePopupComponent} from '../delete-layered-node-popup/delete-layered-node-popup.component';
import {NodeService} from '../../services/node.service';
import {FormService} from '../../services/form.service';
import {firstValueFrom} from 'rxjs';
import {createCriticalErrorOverlay} from '../../utils/create-critical-error-overlay';
import {Subtype} from '../../models/subtype';
import {PaulaDatabaseRecord} from '../../models/paula-database';
import {FormUtils} from '../../utils/form-utils';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-edit-layered-node-popup',
    templateUrl: './edit-layered-node-popup.component.html',
    standalone: false
})
export class EditLayeredNodePopupComponent {
    public form = new FormGroup({
        name: new FormControl('', Validators.required),
        type: new FormControl<PaulaDatabaseRecord | Subtype | null>({ value: null, disabled: true }),

    });
    public form$ = this.formService.openForm$;
    public layerName$ = this.form$.pipe(
        map(form => {
            if (!form) {
                return '';
            }

            if (form?.type === 'jobForm') {
                throw new Error('Form is not a layered job form');
            }
            const node = this.node;
            if (node?.type === 'subtype') {
                const subtypeLayer = form.layers.find(layer =>
                    layer.type === 'subtypeLayer' && layer.subtypes.some(subtype => subtype.id === node.subtype.id)
                );
                if (!subtypeLayer) {
                    throw new Error('Subtype layer not found for subtype id ' + node.subtype.id);
                }
                return subtypeLayer.name;
            } else if (node?.type === 'database') {
                return form.layers.find(layer => layer.id === node.layer)?.name ?? '';
            }
        })
    )

    private readonly node?: AnyLayeredFormNode;
    private readonly tree: AnyLayeredFormNode[] = [];

    constructor(private formService: FormService, private fb: FormBuilder, private popupService: PopupService, private popupRef: PopupRef, {
         data
    }: PopupConfig<{ node: AnyLayeredFormNode, tree: AnyLayeredFormNode[]}>, private nodeService: NodeService) {
        if (data) {
            this.node = data.node;
            this.tree = data.tree;

            this.form.patchValue({ ...data.node, type: this.type });
        }
    }

    async submit() {
        if (!this.form.valid || !this.node) {
            return;
        }

        const node = this.node;
        const value = this.form.value;

        node.name = value.name ?? '';

        const form = await firstValueFrom(this.form$);
        if (!form) {
            createCriticalErrorOverlay('Form not found');
            return;
        }
        if (form.type !== 'layeredJobForm') {
            throw new Error('Form is not a layered job form');
        }

        await this.nodeService.updateNode(form, node);

        this.popupRef.close();
    }

    async openDeletePopup() {
        if (!this.node) {
            return;
        }

        const popup = this.popupService.open(
            DeleteLayeredNodePopupComponent,
            {
                data: {
                    node: this.node,
                    tree: this.tree,
                }
            }
        );

        const result = await popup.result();

        if (result) {
            this.popupRef.close();
        }
    }

    get type() {
        if (FormUtils.isSubtypeNode(this.node)) {
            return this.node.subtype;
        } else if (FormUtils.isDatabaseNode(this.node)) {
            return this.node.record;
        }
    }
}
