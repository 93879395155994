/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import e from "../../core/Accessor.js";
import { result as i } from "../../core/asyncUtils.js";
import r from "../../core/Collection.js";
import { watch as s, on as o, when as n } from "../../core/reactiveUtils.js";
import { property as a } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as c } from "../../core/accessorSupport/decorators/subclass.js";
import l from "../../geometry/Extent.js";
import h from "../../geometry/SpatialReference.js";
import { extentContainsPoint as u } from "../../geometry/support/contains.js";
import { geographicToWebMercator as d, project as m } from "../../geometry/support/webMercatorUtils.js";
import { getLyr3DWasm as p } from "../../views/3d/layers/Lyr3DWasm.js";
function f(t, e) {
  return t && "copyright" in t && (!e || "function" == typeof t.originOf && "user" === t.originOf("copyright"));
}
function y(t, e) {
  return t.length !== e.length || t.some((t, i) => t.text !== e[i].text);
}
function b(t, e, i) {
  if (!i || !e) return;
  t.find(t => t.layerView === e && t.text === i) || t.push({
    text: i,
    layerView: e
  });
}
function g(t) {
  return "bing-maps" === t.type;
}
const A = [];
let _ = class extends e {
  constructor(t) {
    super(t), this._clear = () => {
      this._fetchedAttributionData.clear(), this._pendingAttributions.clear(), this.removeHandles("suspension"), this.notifyChange("state");
    }, this._pendingAttributions = new Set(), this._fetchedAttributionData = new Map(), this.items = new r(), this.view = null, this._allLayerViewsChange = t => {
      this.removeHandles("suspension"), this.removeHandles("visible-geometry-changed");
      const e = this.view?.allLayerViews;
      e && (this.addHandles(e.map(t => s(() => [t.suspended, t.layer?.attributionVisible], () => this._updateAttributionItems())).toArray(), "suspension"), e.forEach(t => {
        "esri.views.3d.layers.Tiles3DLayerView3D" === t.declaredClass && this.addHandles(t.on("visible-geometry-changed", () => this._updateAttributionItems()), "visible-geometry-changed");
      })), t?.removed && t.removed.forEach(t => {
        this._pendingAttributions.delete(t), this._fetchedAttributionData.delete(t);
      }), this._updateAttributionItems();
    }, this.addHandles([o(() => this.view?.allLayerViews, "change", t => this._allLayerViewsChange(t), {
      onListenerAdd: () => this._allLayerViewsChange(),
      onListenerRemove: this._clear
    }), n(() => !0 === this.view?.stationary, () => this._updateAttributionItems())]);
  }
  destroy() {
    this.view = null, this._fetchedAttributionData.clear(), this._pendingAttributions.clear(), this.items.removeAll();
  }
  get state() {
    return this.view?.ready ? this._pendingAttributions.size > 0 ? "loading" : "ready" : "disabled";
  }
  _updateAttributionItems() {
    const t = this.view,
      e = t?.allLayerViews;
    if (A.length = 0, !t || !e) return void this._clear();
    e.forEach(e => {
      if (e.suspended || !e.layer?.attributionVisible) return;
      const i = e.layer;
      if (f(i, "user")) return void b(A, e, i.copyright);
      if (i.hasAttributionData) {
        if (this._fetchedAttributionData.has(e)) {
          const r = this._fetchedAttributionData.get(e);
          return void (r ? b(A, e, this._getDynamicAttribution(r, t, i)) : f(i) && b(A, e, i.copyright));
        }
        return void this._fetchAttributionData(e);
      }
      const r = "portalItem" in i ? i.portalItem?.accessInformation : void 0;
      b(A, e, r || i.copyright);
    });
    const i = e.find(t => "integrated-mesh-3dtiles" === t.layer?.type);
    if (this.view && i) {
      const t = p(this.view);
      if (t) {
        const e = t.getAttributionText();
        for (let t = 0; t < e.length; ++t) b(A, i, e[t]);
      }
    }
    y(this.items, A) && (this.items.removeAll(), this.items.addMany(A)), A.length = 0, this.notifyChange("state");
  }
  async _fetchAttributionData(t) {
    if (this._pendingAttributions.has(t)) return;
    this._pendingAttributions.add(t);
    const e = await i(t.layer.fetchAttributionData());
    if (this._pendingAttributions.has(t)) {
      const i = e.ok ? this._createContributionIndex(e.value, g(t.layer)) : null;
      this._pendingAttributions.delete(t), this._fetchedAttributionData.set(t, i);
    }
    this._updateAttributionItems();
  }
  _createContributionIndex(t, e) {
    const i = t.contributors,
      r = {};
    if (!i) return r;
    for (let s = 0; s < i.length; s++) {
      const t = i[s],
        o = t.coverageAreas;
      if (!o) return;
      for (const i of o) {
        const o = i.bbox,
          n = i.zoomMin - (e && i.zoomMin ? 1 : 0),
          a = i.zoomMax - (e && i.zoomMax ? 1 : 0),
          c = new l({
            xmin: o[1],
            ymin: o[0],
            xmax: o[3],
            ymax: o[2],
            spatialReference: h.WGS84
          }),
          u = {
            extent: d(c),
            attribution: t.attribution || "",
            score: null != i.score ? i.score : 100,
            id: s
          };
        for (let t = n; t <= a; t++) r[t] ??= [], r[t].push(u);
      }
    }
    return r.maxKey = Math.max.apply(null, Object.keys(r)), r;
  }
  _getDynamicAttribution(t, e, i) {
    const {
      extent: r,
      scale: s
    } = e;
    let o = i.tileInfo?.scaleToZoom(s) ?? 0;
    if (o = Math.min(t.maxKey ?? 0, Math.round(o)), !r || null == o || o <= -1) return "";
    const n = t[o],
      a = m(r.center.clone().normalize(), h.WebMercator),
      c = new Set();
    return n ? n.filter(t => {
      const e = t.id,
        i = !c.has(e) && a && t.extent && u(t.extent, a);
      return i && c.add(e), i;
    }).sort((t, e) => e.score - t.score || t.objectId - e.objectId).map(t => t.attribution).join(", ") : "";
  }
};
t([a({
  readOnly: !0,
  type: r
})], _.prototype, "items", void 0), t([a({
  readOnly: !0
})], _.prototype, "state", null), t([a()], _.prototype, "view", void 0), _ = t([c("esri.widgets.Attribution.AttributionViewModel")], _);
const v = _;
export { v as default };