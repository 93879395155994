/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
let e,
  t = null;
const i = new Map();
async function n(n) {
  null == t && (null == e && (e = import("../../../layers/Lyr3DWasmPerSceneView.js")), t = await e);
  const r = n.view;
  let s = i.get(r);
  s || (s = new t.default({
    view: r
  }), i.set(r, s));
  const a = !!r._stage.renderView.renderingContext.capabilities.compressedTextureS3TC,
    l = !!r._stage.renderView.renderingContext.capabilities.compressedTextureETC;
  return await s.initializeWasm(a, l), s.add3DTilesLayerView(n);
}
function r(e) {
  return i.get(e);
}
function s(n) {
  const r = n.view,
    s = i.get(r);
  if (s) {
    s.remove3DTilesLayerView(n) < 1 && (i.delete(r), 0 === i.size && (e = null, t = null));
  }
}
export { n as addLayerViewToWasm, r as getLyr3DWasm, s as removeLayerViewFromWasm };