import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {debounceTime} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {firstValueFrom, Subscription} from 'rxjs';
import {QrCodeScannerModalComponent} from '../qr-code-scanner-modal/qr-code-scanner-modal.component';
import {ProjectJobsObjectSearchState, ProjectJobsObjectSearchType} from '../../models/project-jobs-object-search';
import {PopupService} from '../../services/popup.service';

@Component({
    selector: 'app-project-jobs-object-search',
    templateUrl: './project-jobs-object-search.component.html',
    standalone: false
})
export class ProjectJobsObjectSearchComponent implements OnInit, OnDestroy {

    tags: string[] = [];
    searchType: ProjectJobsObjectSearchType = 'text';
    @Output() searchCriteria = new EventEmitter<ProjectJobsObjectSearchState | null>;

    searchControl = new FormControl<string>('', {nonNullable:true});
    searchControlSubscription: Subscription | null = null;

    constructor(private popupService: PopupService) {
    }

    ngOnInit() {
        this.searchControlSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(500)
        ).subscribe(value => {
            this.emit(this.searchType, value);
        });
    }

    async search(type: ProjectJobsObjectSearchType = this.searchType) {
        this.emit(type, this.searchControl.value);
        this.searchType = type;
    }

    clearSearchInput() {
        this.searchControl.patchValue('', {emitEvent: false});
        this.search('text')
    }
    async scanQrCode() {
        const modal = this.popupService.open(QrCodeScannerModalComponent, {
            data: {
                title: 'Zoek object via QR- of barcode'
            }
        });

        const result = await firstValueFrom(modal.afterClosed);

        if (typeof result === 'string') {
            this.searchControl.patchValue(result, {emitEvent: false});
            this.search('qrCode');
        }
    }

    private emit(type: ProjectJobsObjectSearchType, value: string) {
        this.searchCriteria.emit({type: type, value: value.trim() });
    }

    ngOnDestroy() {
        this.searchControlSubscription?.unsubscribe();
    }
}
