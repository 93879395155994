import { Component } from '@angular/core';

@Component({
    selector: 'app-question-body',
    templateUrl: './question-body.component.html',
    standalone: false
})
export class QuestionBodyComponent {

}
