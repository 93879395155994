/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import e from "../Basemap.js";
import r from "../config.js";
import s from "../core/Collection.js";
import o from "../core/Logger.js";
import { destroyMaybe as n } from "../core/maybe.js";
import { ensureType as t } from "../core/accessorSupport/ensureType.js";
import { esriBasemapDefinitions as i } from "./basemapDefinitions.js";
import { layerDefinitionEquals as a, layerToDefinition as c } from "./basemapUtils.js";
const f = () => o.getLogger("esri.support.basemapUtils");
function p() {
  return {};
}
function l(e) {
  for (const r in e) {
    const s = e[r];
    n(s), delete e[r];
  }
}
function m(s, o) {
  let n;
  if ("string" == typeof s) {
    const t = s in i,
      a = !t && s.includes("/");
    if (!t && !a) {
      if (r.apiKey) f().warn(`Unable to find basemap definition for: ${s}. See available styles at https://developers.arcgis.com/rest/basemap-styles/`);else {
        const e = Object.entries(i).filter(([e, r]) => r.classic || r.is3d).map(([e]) => `"${e}"`).sort().join(", ");
        f().warn(`Unable to find basemap definition for: ${s}. Try one of these: ${e}`);
      }
      return null;
    }
    o && (n = o[s]), n || (n = t ? e.fromId(s) : new e({
      style: {
        id: s
      }
    }), o && (o[s] = n));
  } else n = t(e, s);
  return n?.destroyed && (f().warn("The provided basemap is already destroyed", {
    basemap: n
  }), n = null), n;
}
function u(e, r = null) {
  const s = m(e);
  if (!s) return null;
  const o = s.clone();
  return r && (o.baseLayers = d(o.baseLayers, r.baseLayers), o.referenceLayers = d(o.referenceLayers, r.referenceLayers)), o;
}
function d(e, r) {
  const o = new s();
  return e.forEach(e => {
    const s = r.find(r => "scene" !== r.type && a(c(e), c(r))) || e;
    o.includes(s) ? o.push(e) : o.push(s);
  }), o;
}
export { m as basemapEnsureType, u as clonePreservingTiledLayers, p as createCache, l as destroyCache };