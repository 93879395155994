import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[appPopupContent]',
    standalone: true
})
export class PopupContentDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
