/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { DragEventSeparator as t } from "../../../input/DragEventSeparator.js";
import { InputHandler as i } from "../../../input/InputHandler.js";
import { eventMatchesPointerActions as a } from "../../../input/handlers/support.js";
class n extends i {
  constructor(t, i, a) {
    super(!0), this.view = t, this.pointerActions = i, this.registerIncoming("drag", a, t => this._handleDrag(t)), this.registerIncoming("pointer-down", () => this.stopMomentumNavigation());
  }
  onInstall(i) {
    super.onInstall(i), this._dragEventSeparator = new t({
      start: (t, i) => {
        this.view.mapViewNavigation.pan.begin(this.view, i.data), i.stopPropagation();
      },
      update: (t, i) => {
        this.view.mapViewNavigation.pan.update(this.view, i.data), i.stopPropagation();
      },
      end: (t, i) => {
        this.view.mapViewNavigation.pan.end(this.view, i.data), i.stopPropagation();
      },
      condition: (t, i) => 1 === t && a(i.data, this.pointerActions)
    });
  }
  _handleDrag(t) {
    const i = this.view.mapViewNavigation;
    i.pinch.zoomMomentum || i.pinch.rotateMomentum ? this.stopMomentumNavigation() : this._dragEventSeparator.handle(t);
  }
  stopMomentumNavigation() {
    this.view.mapViewNavigation.pan.stopMomentumNavigation();
  }
}
export { n as DragPan };