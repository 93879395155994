/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import { byId as t, reparent as s } from "../core/domUtils.js";
import { on as i } from "../core/events.js";
import { makeHandle as r } from "../core/handleUtils.js";
import o from "../core/Logger.js";
import { removeMaybe as n } from "../core/maybe.js";
import { watch as a, initial as h } from "../core/reactiveUtils.js";
import { addFrameTask as d } from "../core/scheduling.js";
import { property as l } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/RandomLCG.js";
import { subclass as u } from "../core/accessorSupport/decorators/subclass.js";
import c from "./overlay/ViewOverlay.js";
const p = [0, 0];
function m(e) {
  const t = (e.ownerDocument || window.document).defaultView,
    s = e.getBoundingClientRect();
  return p[0] = s.left + (t?.pageXOffset ?? 0), p[1] = s.top + (t?.pageYOffset ?? 0), p;
}
function f(e) {
  e && (e.textContent = "", e.parentNode && e.parentNode.removeChild(e));
}
function y(e) {
  const t = document.createElement("div");
  return e.appendChild(t), t;
}
const g = 16,
  v = 750,
  _ = 512,
  w = 2,
  C = p => {
    let C = class extends p {
      constructor(...e) {
        super(...e), this._freqInfo = {
          freq: g,
          time: v
        }, this._overlayRenderTaskHandle = null, this.height = 0, this.messagesCommon = null, this.overlay = null, this.position = null, this.resizing = !1, this.root = null, this.surface = null, this.suspended = !0, this.ui = null, this.userContent = null, this.width = 0, this.widthBreakpoint = null, this.addHandles([a(() => this.cursor, e => {
          const {
            surface: t
          } = this;
          t && t.setAttribute("data-cursor", e);
        }), a(() => this.navigating, e => {
          const {
            surface: t
          } = this;
          t && t.setAttribute("data-navigating", e.toString());
        })]);
      }
      initialize() {
        this.addHandles([a(() => this.ui, (e, t) => this._handleUIChange(e, t), h), this.on("focus", () => this.notifyChange("focused")), this.on("blur", () => this.notifyChange("focused"))]);
      }
      destroy() {
        this.destroyed || (this.ui?.destroy(), this.container = null);
      }
      get container() {
        return this._get("container") ?? null;
      }
      set container(e) {
        const i = this._get("container"),
          r = t(e);
        if (r || "string" != typeof e || o.getLogger(this).error("#container", `element with id '${e}' not found`), i === r) return;
        if (this._stopMeasuring(), i && (i.classList.remove("esri-view"), this._overlayRenderTaskHandle && (this._overlayRenderTaskHandle.remove(), this._overlayRenderTaskHandle = null), this.overlay && (this.overlay.destroy(), this._set("overlay", null)), this.root && (f(this.root), this._set("root", null)), this.userContent && (s(this.userContent, i), f(this.userContent), this._set("userContent", null))), !r) return this._set("width", 0), this._set("height", 0), this._set("position", null), this._set("suspended", !0), this._set("surface", null), void this._set("container", null);
        r.classList.add("esri-view");
        const h = document.createElement("div");
        h.className = "esri-view-user-storage", s(r, h), r.appendChild(h), this._set("userContent", h);
        const l = document.createElement("div");
        l.className = "esri-view-root", r.insertBefore(l, r.firstChild), this._set("root", l);
        const u = document.createElement("div");
        u.className = "esri-view-surface", u.setAttribute("role", "application"), u.tabIndex = 0, l.appendChild(u), this._set("surface", u);
        const p = new c();
        l.appendChild(p.surface), this._set("overlay", p), this.addHandles(a(() => p.needsRender, e => {
          e && !this._overlayRenderTaskHandle ? this._overlayRenderTaskHandle = d({
            render: () => this.overlay?.render()
          }) : this._overlayRenderTaskHandle = n(this._overlayRenderTaskHandle);
        })), this.forceDOMReadyCycle(), this._set("container", r), this._startMeasuring();
      }
      get focused() {
        const e = document.activeElement === this.surface;
        return document.hasFocus() && e;
      }
      get size() {
        return [this.width, this.height];
      }
      blur() {
        this.surface?.blur();
      }
      focus() {
        this.surface?.focus();
      }
      pageToContainer(e, t, s) {
        const i = this.position;
        return e -= i ? i[0] : 0, t -= i ? i[1] : 0, s ? (s[0] = e, s[1] = t) : s = [e, t], s;
      }
      containerToPage(e, t, s) {
        const i = this.position;
        return e += i ? i[0] : 0, t += i ? i[1] : 0, s ? (s[0] = e, s[1] = t) : s = [e, t], s;
      }
      _handleUIChange(e, t) {
        this.removeHandles("ui"), t && t !== e && t.destroy(), e && (e.view = this, this.addHandles(a(() => this.root, t => {
          e.container = t ? y(t) : null;
        }, h), "ui")), this._set("ui", e);
      }
      _stopMeasuring() {
        this.removeHandles("measuring"), this._get("resizing") && this._set("resizing", !1);
      }
      _startMeasuring() {
        const e = this._freqInfo;
        e.freq = g, e.time = v;
        const t = d({
            prepare: e => {
              const s = this._measure(),
                i = this._freqInfo;
              if (i.time += e.deltaTime, s && (i.freq = g, this._get("resizing") || this._set("resizing", !0)), i.time < i.freq) return;
              i.time = 0;
              const r = this._position();
              i.freq = r || s ? g : Math.min(v, i.freq * w), !s && i.freq >= _ && (t.pause(), this._get("resizing") && this._set("resizing", !1));
            }
          }),
          s = new ResizeObserver(s => {
            e.freq = g, e.time = v, t.resume();
          });
        null != this.container && s.observe(this.container);
        const o = r(() => s.disconnect());
        this.addHandles([i(window, "resize", () => {
          e.freq = g, e.time = v, t.resume();
        }), o, t], "measuring"), this._measure(), this._position();
      }
      _measure() {
        const e = this.container,
          t = e ? e.clientWidth : 0,
          s = e ? e.clientHeight : 0;
        if (0 === t || 0 === s) return this.suspended || this._set("suspended", !0), !1;
        const i = this.width,
          r = this.height;
        return t === i && s === r ? (this.suspended && this._set("suspended", !1), !1) : (this._set("width", t), this._set("height", s), this.suspended && this._set("suspended", !1), this.emit("resize", {
          oldWidth: i,
          oldHeight: r,
          width: t,
          height: s
        }), !0);
      }
      _position() {
        const e = this.container,
          t = this.position,
          s = e && m(e);
        return !!s && (!t || s[0] !== t[0] || s[1] !== t[1]) && (this._set("position", [s[0], s[1]]), !0);
      }
      forceDOMReadyCycle() {}
    };
    return e([l()], C.prototype, "container", null), e([l({
      readOnly: !0
    })], C.prototype, "focused", null), e([l({
      readOnly: !0
    })], C.prototype, "height", void 0), e([l()], C.prototype, "messagesCommon", void 0), e([l({
      type: c
    })], C.prototype, "overlay", void 0), e([l({
      readOnly: !0
    })], C.prototype, "position", void 0), e([l({
      readOnly: !0
    })], C.prototype, "resizing", void 0), e([l({
      readOnly: !0
    })], C.prototype, "root", void 0), e([l({
      value: null,
      readOnly: !0
    })], C.prototype, "size", null), e([l({
      readOnly: !0
    })], C.prototype, "surface", void 0), e([l({
      readOnly: !0
    })], C.prototype, "suspended", void 0), e([l({
      nonNullable: !0
    })], C.prototype, "ui", void 0), e([l({
      readOnly: !0
    })], C.prototype, "userContent", void 0), e([l({
      readOnly: !0
    })], C.prototype, "width", void 0), e([l()], C.prototype, "widthBreakpoint", void 0), C = e([u("esri.views.DOMContainer")], C), C;
  };
export { C as DOMContainer };