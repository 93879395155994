/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import o from "../../core/Accessor.js";
import { initial as i, watch as e } from "../../core/reactiveUtils.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as a } from "../../core/accessorSupport/decorators/subclass.js";
import { getGoToDuration as s } from "../../views/2d/navigation/duration.js";
import { canShowNorth as n } from "./utils.js";
import d from "../support/GoTo.js";
let h = class extends d(o) {
  constructor(t) {
    super(t), this.orientation = {
      x: 0,
      y: 0,
      z: 0
    }, this.view = null, this._updateForCamera = this._updateForCamera.bind(this), this._updateForRotation = this._updateForRotation.bind(this), this._updateRotationWatcher = this._updateRotationWatcher.bind(this);
  }
  initialize() {
    this._watchForView(i);
  }
  destroy() {
    this.view = null;
  }
  get canShowNorth() {
    return n(this.view);
  }
  get state() {
    return !this.view?.ready || "2d" === this.view.type && !this.view.constraints.rotationEnabled ? "disabled" : this.canShowNorth ? "compass" : "rotation";
  }
  reset() {
    this.view?.ready && ("2d" === this.view?.type ? this.callGoTo({
      target: {
        rotation: 0
      },
      options: {
        animationMode: "always",
        duration: s()
      }
    }) : this.callGoTo({
      target: {
        heading: 0
      }
    }));
  }
  _updateForRotation(t) {
    null != t && this._set("orientation", {
      z: t
    });
  }
  _updateForCamera(t) {
    if (!t) return;
    const o = -t.heading;
    this._set("orientation", {
      x: 0,
      y: 0,
      z: o
    });
  }
  _updateRotationWatcher(t) {
    this.removeAllHandles(), this._watchForView(), t && this.addHandles("2d" === t.type ? e(() => t?.rotation, this._updateForRotation, i) : e(() => t?.camera, this._updateForCamera, i));
  }
  _watchForView(t) {
    this.addHandles(e(() => this.view, this._updateRotationWatcher, t));
  }
};
t([r({
  readOnly: !0
})], h.prototype, "canShowNorth", null), t([r({
  readOnly: !0
})], h.prototype, "orientation", void 0), t([r({
  readOnly: !0
})], h.prototype, "state", null), t([r()], h.prototype, "view", void 0), h = t([a("esri.widgets.Compass.CompassViewModel")], h);
const p = h;
export { p as default };