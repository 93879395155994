import {Pipe, PipeTransform} from '@angular/core';
import {Questions} from '../utils/questions';
import {Question} from "../models/question/question";

@Pipe({
    name: 'questionTitle',
    standalone: true
})
export class QuestionTitlePipe implements PipeTransform {
    /**
     * Takes a value and formats date
     */
    transform(question: Question | null) {
        if (!question) {
            return '';
        }

        return Questions.getQuestionTitle(question);
    }
}
