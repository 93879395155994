// @ts-strict-ignore
import {Component, Input} from '@angular/core';
import {AccordionController} from '../../controllers/accordion.controller';
import {map} from 'rxjs/operators';

export type AccordionIcon = 'none' | 'oval-open' | 'check' | 'indeterminate' | 'oval-exclamation' | 'arrow-right';

@Component({
    selector: 'app-accordion-group',
    templateUrl: './accordion-group.component.html',
    standalone: false
})
export class AccordionGroupComponent {
    @Input() startIcon: AccordionIcon;
    @Input() accordionGroupId: string;
    @Input() accordionId: string;

    collapsed$ = this.controller.asObservable().pipe(map((state) => state[this.accordionGroupId] === this.accordionId));

    constructor(public controller: AccordionController) {}
}
