import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormControl, Validators} from '@angular/forms';
import {ProjectJobAnswer, ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormUtils} from '../../../utils/form-utils';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';
import {TableQuestion} from '../../../models/question/table-question';
import {Subject, Subscription} from 'rxjs';
import {numberQuestionValidator,} from '../../../validators/number-question.validator';
import {convertToNumber} from '../../../utils/number-utils';
import {QuestionTolerance, ToleranceMessage} from '../../../utils/question-tolerance';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-question-v2-table',
    templateUrl: './question-v2-table.component.html',
    standalone: false
})
export class QuestionV2TableComponent implements OnInit, OnDestroy {
    private currentForm: AnyProjectJobForm | null = null;
    public currentQuestion: TableQuestion | null = null;
    public currentNode: AnyLayeredFormNode | undefined = undefined;
    public subscriptions: Subscription[] = [];
    public tolerance: ToleranceMessage | null = null;
    public showPreviousAnswer = false;
    public doGoForward = new Subject<void>();

    public rowControls: FormArray<FormControl> = new FormArray<FormControl>([]);
    public rowControlsForTolerance: FormArray<FormControl> = new FormArray<FormControl>([]);

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.updateAnswer();
    }

    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNode = node;
        this.updateAnswer();
    }
    @Input({required: true}) set question(question: TableQuestion) {
        this.currentQuestion = question;

        this.updateAnswer();
    }

    constructor(private toastr: ToastrService) {
    }


    ngOnInit() {
        this.subscriptions.push(
            this.rowControls.valueChanges.subscribe(rowValues => this.updateTolerance(rowValues)),
        );
    }

    submit() {
        this.doGoForward.next();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    get isValid() {
        return this.rowControls.valid;
    }

    get currentValue(): ProjectJobAnswerValue {
        return {
            value: this.mapRowValuesToValue(this.rowControls.value),
            remarkText: null,
            remarkImage: null,
        }
    }

    calculateStatistics(rowValues: string[]) {
        const filledRows = rowValues
            .map(row => parseFloat((row || '').replace(',', '.')))
            .filter(row => !isNaN(row));

        if (filledRows.length > 0) {
            return {
                total: filledRows.reduce((row, sum) => sum + row),
                average: filledRows.reduce((row, sum) => sum + row) / filledRows.length,
            }
        } else {
            return null;
        }
    }

    showErrorToast() {
        this.rowControls.markAllAsTouched();
        this.toastr.error('Het antwoord van een verplichte vraag mag niet leeg zijn');
    }

    private updateAnswer() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const latest = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);
        this.fillControls(this.rowControls, latest);

        const previousAnswer = FormUtils.getPreviousAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);
        this.fillControls(this.rowControlsForTolerance, previousAnswer);

        this.showPreviousAnswer = !!previousAnswer && this.currentForm.status === 'Rejected'
            && !this.calculateTolerance(this.currentQuestion, previousAnswer.value ?? '').tolerant;
    }

    private mapValueToRowValues(value: string): string[] {
        return value ? value.split(',') : [];
    }

    private mapRowValuesToValue(rowValues: string[]): string {
        return rowValues
            .map(it => {
                if (typeof it === 'string') {
                    return convertToNumber(it);
                }
                return it;
            })
            .join(',');
    }

    private updateTolerance(rowValues: string[]) {
        if (!this.currentQuestion) {
            return;
        }

        this.tolerance = this.calculateTolerance(this.currentQuestion, rowValues);
    }

    private calculateTolerance(question: TableQuestion, values: string | string[]): ToleranceMessage {
        return QuestionTolerance.validateTableQuestionTolerance(
            question,
            values,
        );
    }

    private fillControls(control: FormArray, answer: ProjectJobAnswer | null) {
        if (!this.currentQuestion) {
            return;
        }

        control.clear();

        for (let i = 0; i < this.currentQuestion.questionCount; i++) {
            const validators = [numberQuestionValidator()];
            if (this.currentQuestion.required) {
                validators.push(Validators.required);
            }

            control.push(new FormControl(null, validators));
        }

        if (answer) {
            const value = answer.value ?? '';

            const rowValues = this.mapValueToRowValues(value);
            if (rowValues.length === this.currentQuestion.questionCount) {
                control.setValue(this.mapValueToRowValues(value));
            }

            this.updateTolerance(rowValues);
        }
    }
}
