import {Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {AnnotatableImage, AnnotationShape, ImageAnnotationResult} from './annotatable-image';
import {ImageAnnotationTextPopupComponent} from '../image-annotation-text-popup/image-annotation-text-popup.component';
import {PopupService} from '../../services/popup.service';
import {PhotoAnnotationEditor} from '../../classes/annotate/photo-annotation-editor';
import {AnnotateTool} from '../../models/annotate-tool';
import {AnnotateToolConfiguration} from '../../models/annotate-tool-configuration';
import {
    AnnotateComponentAction,
    AnnotateComponentActionShape,
    AnnotateComponentActionType,
    AnnotateComponentState
} from '../../classes/annotate/annotate-component';
import {AnnotateHistoryManager} from '../../classes/annotate/annotate-history-manager';
import {debounceTime, filter} from 'rxjs/operators';
import {FormImageService} from '../../services/form-image.service';
import {getLoadedImageElement} from '../../utils/image';
import {AnnotateComponentIcon} from '../../classes/annotate/annotate-component-icon';
import {ShapeConfig} from 'konva/lib/Shape';

export type AnnotationTools = 'shape' | 'pencil' | 'text' | 'color';

@Component({
    selector: 'app-image-annotation-v3',
    templateUrl: './image-annotation-v3.component.html',
    standalone: false
})
export class ImageAnnotationV3Component implements OnInit, OnChanges {
    @ViewChild('container', {static: true}) container: ElementRef<HTMLDivElement> | null = null;
    @ViewChild('toolbar', {static: true}) toolbar: ElementRef<HTMLDivElement> | null = null;

    @Input() showRemove = true;
    @Input() toolConfig: AnnotateToolConfiguration | null = null;

    @Output() cancelAnnotation = new EventEmitter<string>();
    @Output() remove = new EventEmitter<string>();
    @Output() save = new EventEmitter<ImageAnnotationResult>();

    canUndo = false;
    canRedo = false;

    palette = [
        '#ffde40',
        '#b7e52d',
        '#06cdd2',
        '#3398ef',
        '#ffb42b',
        '#ff5454',
        '#efefef',
        '#999999',
        '#434343'
    ];

    private _image: AnnotatableImage | null = null;
    private _visible = false;
    private backgroundImageElement: HTMLImageElement | null = null;
    private imageAnnotationEditor: PhotoAnnotationEditor | null = null;
    private readonly annotateHistoryManager = new AnnotateHistoryManager();

    textShapeContents = '';
    private chosenTool: AnnotateTool | null = null;
    public comment: string | null = null;

    currentTool: AnnotationTools | null = 'pencil';
    openToolMenu: AnnotationTools | null = null;
    currentColor: string = this.palette[5]; // red is the default color

    constructor(
        private popupService: PopupService,
        private formImageService: FormImageService
    ) {
    }

    @Input() set visible(visible) {
        this._visible = visible;
    }

    get visible() {
        return this._visible;
    }

    @Input() set image(image: AnnotatableImage | undefined) {
        const imageChanged = image !== this._image;
        this._image = image ?? null;

        if (imageChanged) {
            this.clearCanvasMemory();
        }
    }

    ngOnInit(): void {
        this.annotateHistoryManager.onChange.pipe(
            debounceTime(100) // Debounce the creation of bitmap filled events to make sure the undo and redo actions stay performant
        ).subscribe(() => {
            this.canUndo = this.annotateHistoryManager.canUndoAction() || false;
            this.canRedo = this.annotateHistoryManager.canRedoAction() || false;
        });
    }

    ngOnChanges() {
        if (this._visible && this._image) {
            this.initializeStage();
        }
    }

    chooseImageTool(imageTool: AnnotateTool) {
        this.chosenTool = imageTool;
        this.openToolMenu = null;

        const shapeConfig = this.modifyShapeConfig(imageTool, this.currentColor);
        this.imageAnnotationEditor?.addComponent(imageTool.component, shapeConfig);
    }

    private modifyShapeConfig(imageTool: AnnotateTool, currentColor: string): ShapeConfig {
        const copy: ShapeConfig = JSON.parse(JSON.stringify(imageTool.initialisationObject));

        if (copy.stroke) {
            copy.stroke = currentColor;
        }
        if (copy.iconColor) {
            copy.iconColor = currentColor;
        }

        return copy;
    }

    async close() {
        this.cancelAnnotation.emit();
    }

    async removeImage() {
        this.remove.emit();
        this.cancelAnnotation.emit();
    }

    @HostListener('window:resize')
    onResize() {
        if (this.imageAnnotationEditor) {
            this.imageAnnotationEditor.fitContainerInParentElement();
        }
    }

    private async initializeStage() {
        const imageUrl = await this.formImageService.getImageUrl(this._image!.originalPhotoId);
        this.backgroundImageElement = await getLoadedImageElement(imageUrl);

        const history: AnnotateComponentAction[] = this._image?.shapes?.map(shape => ({
            type: AnnotateComponentActionType.ADD,
            shape: shape.shape,
            uuid: shape.id,
            state: shape.shapeConfig as AnnotateComponentState
        })) ?? [];

        this.rebuildCanvas(history);

        this.annotateHistoryManager.clear();
        this.annotateHistoryManager.setActions(history);
    }

    private rebuildCanvas(history: AnnotateComponentAction[]) {
        // Clear some memory from our previous instance
        // And keep the selected uuid
        let selectedUuid = null;
        if (this.imageAnnotationEditor) {
            selectedUuid = this.imageAnnotationEditor.selectedUuid;
            this.clearCanvasMemory();
        }

        if (this.container && this._image) {
            this.imageAnnotationEditor = new PhotoAnnotationEditor(
                this.container.nativeElement,
                this.backgroundImageElement!,
                history,
                this.toolConfig!,
                {
                    selectedUuid: selectedUuid,
                    isDrawing: this.currentTool === 'pencil',
                    pencilColor: this.currentColor
                }
            );

            this.canUndo = this.annotateHistoryManager.canUndoAction();
            this.canRedo = this.annotateHistoryManager.canRedoAction();
        }

        this.imageAnnotationEditor!.fitContainerInParentElement();

        this.imageAnnotationEditor!.onChange.subscribe((action) => {
            this.annotateHistoryManager.appendAction(action);
        });

        this.imageAnnotationEditor!.onSelectionChange$.pipe(filter(x => !!x)).subscribe(() => {
            this.currentTool = null;
        })
    }

    undo() {
        const undoneAction = this.annotateHistoryManager.undoAction();
        if (undoneAction) {
            if (this.annotateHistoryManager) {
                this.rebuildCanvas(this.annotateHistoryManager.getCompressedActions());
            }
        }
    }

    redo() {
        const redoneAction = this.annotateHistoryManager?.redoAction();
        if (redoneAction) {
            if (redoneAction.type === AnnotateComponentActionType.VIEWPORT) {
                if (this.annotateHistoryManager) {
                    this.rebuildCanvas(this.annotateHistoryManager.getCompressedActions());
                }
            } else {
                this.imageAnnotationEditor?.executeAction(redoneAction, true);
            }
        }
    }

    private clearCanvasMemory() {
        if (this.imageAnnotationEditor) {
            this.imageAnnotationEditor.destroy();
            this.imageAnnotationEditor = null;
        }
    }

    async sendSaveEvent() {
        if (!this.imageAnnotationEditor) {
            return console.error('unable to save: drawComponents is undefined')
        }

        this.imageAnnotationEditor.components.forEach(component => {
            component.hideTransformTool();
        });

        const saveData = {
            shapes: this.getShapeData(),
            image: this._image!,
            blob: await this.imageAnnotationEditor.toBlob()
        };

        this.save.emit(saveData);
    }

    private getShapeData(): AnnotationShape[] {
        const shapeData: AnnotationShape[] = [];
        for (const component of this.imageAnnotationEditor!.components) {
            const {uuid, shape, ...config} = component;
            const configWithoutImage = config.state.config;
            const points: number[] | undefined = configWithoutImage.points;
            if (points && points.length <= 2) {
                // Skip lines with only a single point
                continue;
            }

            const shapeConfig = {
                ...config.state,
                config: {
                    ...configWithoutImage,
                    ...(points ? {points: points.map(it => Math.round(it))} : {}) // optimization to reduce JSON size
                }
            };

            shapeData.push({id: uuid, shape, shapeConfig});
        }

        return shapeData;
    }

    changeTool(value: AnnotationTools, openMenu?: boolean) {
        this.openToolMenu = openMenu ? value : null;

        if (value !== 'color') {
            this.currentTool = value;
        }

        if (value === 'pencil') {
            this.imageAnnotationEditor?.drawLineStart(this.currentColor);
        }
    }

    selectColor(color: string) {
        this.openToolMenu = null;
        this.currentColor = color;

        this.imageAnnotationEditor?.currentColorChanged(color);
        if (this.currentTool === 'pencil') {
            this.imageAnnotationEditor?.drawLineStart(this.currentColor);
        }
    }

    async openTextModal() {
        this.currentTool = 'text';

        const popup = this.popupService.open(ImageAnnotationTextPopupComponent);
        const result = await firstValueFrom(popup.afterClosed);

        if (typeof result === 'string') {
            this.textShapeContents = result;
            this.addTextComponent();
        }
    }

    addTextComponent() {
        const config = {
            'component': AnnotateComponentActionShape.TEXT,
            'backgroundRect': {
                'fill': '#FFFFFF',
                'stroke': this.currentColor,
                'strokeWidth': 2,
                'padding': [5, 5, 5, 5]
            },
            'textConfig': {
                'fontStyle': 'bold',
                'fill': this.currentColor,
                'text': this.textShapeContents
            }
        };

        if (config) {
            this.imageAnnotationEditor?.addComponent(AnnotateComponentActionShape.TEXT, config);
        }

        this.currentTool = null;
        this.textShapeContents = '';
    }

    replaceIconColors(icon: string) {
        return AnnotateComponentIcon.replaceIconColor(icon, '#FFF', '#06141D');
    }
}
