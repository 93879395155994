import {Component} from '@angular/core';
import {ProjectJobForm} from '../../models/project-job-form';
import {ProjectJobFormSubmit} from '../../models/project-job-form-submit';
import {AnswerCopyService} from '../../services/answer-copy.service';
import {firstValueFrom} from 'rxjs';
import {PopupRef} from '../../helpers/popup-ref';
import {PopupConfig} from '../../models/popup-config';

@Component({
    selector: 'app-copy-answers-modal',
    templateUrl: './copy-answers-modal.component.html',
    standalone: false
})
export class CopyAnswersModalComponent {
    source: ProjectJobForm | null = null;
    destination: ProjectJobForm | null = null;

    constructor(
        private answerCopyService: AnswerCopyService,
        private popupRef: PopupRef,
        {data}: PopupConfig<{ source: ProjectJobForm, destination: ProjectJobForm }>
    ) {
        if (data) {
            this.source = data.source;
            this.destination = data.destination;
        } else {
            throw new Error('Data must be set');
        }
    }

    async doCopy(doCopy: boolean) {
        if (!this.source || !this.destination) {
            throw new Error('Source and destination must be set');
        }

        let newDestination = null;
        if (doCopy) {
            newDestination = await firstValueFrom(this.answerCopyService.copyAnswers(this.source, this.destination));
        }

        this.popupRef.close(newDestination);
    }

    latestSubmit(form: ProjectJobForm): ProjectJobFormSubmit {
        return form.submits.sort((a, b) => {
            return +new Date(b.submittedAt) - +new Date(a.submittedAt);
        })[0];
    }
}
