/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { easingFunctions as t } from "../2d/unitBezier.js";
const u = t => t,
  i = t => t * t,
  o = t => 1 - i(1 - t),
  n = t => t < .5 ? i(2 * t) / 2 : (o(2 * (t - .5)) + 1) / 2,
  a = t => t * t * t,
  c = t => 1 - a(1 - t),
  s = t => t < .5 ? a(2 * t) / 2 : (c(2 * (t - .5)) + 1) / 2,
  e = t => t * t * t * t,
  q = t => 1 - e(1 - t),
  r = t => t < .5 ? e(2 * t) / 2 : (q(2 * (t - .5)) + 1) / 2,
  d = t => t * t * t * t * t,
  b = t => 1 - d(1 - t),
  p = t => t < .5 ? d(2 * t) / 2 : (b(2 * (t - .5)) + 1) / 2,
  h = t => 1 - Math.cos(t * Math.PI / 2),
  x = t => 1 - h(1 - t),
  M = t => t < .5 ? h(2 * t) / 2 : (x(2 * (t - .5)) + 1) / 2,
  f = t => 2 ** (10 * (t - 1)),
  I = t => 1 - f(1 - t),
  m = t => t < .5 ? f(2 * t) / 2 : (I(2 * (t - .5)) + 1) / 2,
  O = t => -(Math.sqrt(1 - t * t) - 1),
  j = t => 1 - O(1 - t),
  l = t => t < .5 ? O(2 * t) / 2 : (j(2 * (t - .5)) + 1) / 2;
function z(t) {
  const u = 2 * (t - Math.sqrt((t - 1) * t)),
    i = u / 2 / t;
  return o => o < i ? t * o * o : u * o - u + 1;
}
function B(t, u) {
  return (i, o) => i < u ? u * t(i / u, o) : 1 - t((1 - i) / (1 - u), o) * (1 - u);
}
const P = B(z(1), 1),
  g = B(z(1), 0),
  k = B(z(1), .5),
  v = B(z(2), 1),
  w = B(z(2), 0),
  y = B(z(2), .5),
  A = B(z(3), 1),
  C = B(z(3), 0),
  D = B(z(3), .5),
  E = B(z(4), 1),
  F = B(z(4), 0),
  G = B(z(4), .5),
  H = {
    linear: u,
    "in-quad": i,
    "out-quad": o,
    "in-out-quad": n,
    "in-coast-quad": P,
    "out-coast-quad": g,
    "in-out-coast-quad": k,
    "in-cubic": a,
    "out-cubic": c,
    "in-out-cubic": s,
    "in-coast-cubic": v,
    "out-coast-cubic": w,
    "in-out-coast-cubic": y,
    "in-quart": e,
    "out-quart": q,
    "in-out-quart": r,
    "in-coast-quart": A,
    "out-coast-quart": C,
    "in-out-coast-quart": D,
    "in-quint": d,
    "out-quint": b,
    "in-out-quint": p,
    "in-coast-quint": E,
    "out-coast-quint": F,
    "in-out-coast-quint": G,
    "in-sine": h,
    "out-sine": x,
    "in-out-sine": M,
    "in-expo": f,
    "out-expo": I,
    "in-out-expo": m,
    "in-circ": O,
    "out-circ": j,
    "in-out-circ": l,
    ease: u => t.ease(u),
    "ease-in": u => t.easeIn(u),
    "ease-out": u => t.easeOut(u),
    "ease-in-out": u => t.easeInOut(u)
  };
export { H as EasingFunctions, O as inCirc, v as inCoastCubic, P as inCoastQuad, A as inCoastQuart, E as inCoastQuint, a as inCubic, f as inExpo, l as inOutCirc, y as inOutCoastCubic, k as inOutCoastQuad, D as inOutCoastQuart, G as inOutCoastQuint, s as inOutCubic, m as inOutExpo, n as inOutQuad, r as inOutQuart, p as inOutQuint, M as inOutSine, i as inQuad, e as inQuart, d as inQuint, h as inSine, u as linear, j as outCirc, w as outCoastCubic, g as outCoastQuad, C as outCoastQuart, F as outCoastQuint, c as outCubic, I as outExpo, o as outQuad, q as outQuart, b as outQuint, x as outSine };