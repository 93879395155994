import {Component, HostBinding, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
    selector: 'app-input-error',
    templateUrl: './input-error.component.html',
    standalone: false
})
export class InputErrorComponent {
    @Input({required: true})
    control: AbstractControl | null = null;
    @Input({required: true})
    errorName: string | null = null;

    @HostBinding('class.hidden')
    get invisible(): boolean {
        return this.control === null
            || this.errorName === null
            || this.control.pristine
            || !this.control.hasError(this.errorName);
    }

}
