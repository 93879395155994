/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import r from "../../core/Accessor.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
let t = class extends r {
  constructor(o) {
    super(o), this.featureTitleFields = !1, this.utilityNetworkFields = !1, this.globalIdField = !1;
  }
};
o([e()], t.prototype, "featureTitleFields", void 0), o([e()], t.prototype, "utilityNetworkFields", void 0), o([e()], t.prototype, "globalIdField", void 0), t = o([s("esri.views.support.RequiredFieldsOptions")], t);
const i = t;
export { i as default };