/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import o from "../core/Accessor.js";
import { clock as e } from "../core/clock.js";
import i from "../core/Collection.js";
import "../core/has.js";
import a from "../core/Logger.js";
import { removeMaybe as r } from "../core/maybe.js";
import { watch as s } from "../core/reactiveUtils.js";
import { property as n } from "../core/accessorSupport/decorators/property.js";
import "../core/RandomLCG.js";
import { subclass as l } from "../core/accessorSupport/decorators/subclass.js";
import { UpdatingHandles as h } from "../core/support/UpdatingHandles.js";
import { ViewEventPriorities as c } from "./input/InputManager.js";
import { eventTypes as u } from "./input/ViewEvents.js";
import { getToolCollectionHandles as p, areToolManipulatorsEditable as m } from "./interactive/interactiveToolUtils.js";
import { EditableFlag as d } from "./interactive/interfaces.js";
import { ToolViewManagerManipulatorState as v } from "./interactive/ToolViewManagerManipulatorState.js";
const _ = "attached",
  g = "tools",
  T = 1e3;
let f = class extends o {
  constructor(t) {
    super(t), this._updatingHandles = new h(), this._clock = e, this._manipulatorState = new v(), this.tools = new i(), this.cursor = null, this._interacting = !1, this._interactingTimeout = T, this._interactingTimeoutHandle = null, this._forEachTool = t => {
      for (const o of this.tools.items) if (t(o)) return;
    };
  }
  initialize() {
    this.addHandles([this.view.on(u, t => {
      this._handleInputEvent(t);
    }, c.TOOL), ...p(this.tools), this.tools.on("before-add", ({
      item: t
    }) => {
      this._updateToolEditableFlag(t);
    }), this.tools.on("before-remove", ({
      item: t
    }) => {
      this._manipulatorState.clearPointers(t, this._manipulatorStateEventArgs), this._updateCursor();
    }), this.tools.on("change", () => {
      this._refreshToolWatchers();
    })]);
  }
  destroy() {
    this.activeTool = null, this.tools.drain(t => t.destroy()), this._clearInteractingTimeout(), this._interacting = !1, this._updatingHandles.destroy();
  }
  get _manipulatorStateEventArgs() {
    return {
      forEachTool: this._forEachTool,
      activeTool: this.activeTool,
      setActiveTool: t => {
        this.activeTool = t;
      },
      view: this.view
    };
  }
  set activeTool(t) {
    if (null != t && !this.view.ready) return void a.getLogger(this).error("Cannot set active tool while view is not ready.");
    if (t === this.activeTool) return;
    const o = this.activeTool;
    this._set("activeTool", t), null != o && o.deactivate(), null != t && t.activate(), this._removeIncompleteTools(t);
    for (const e of this.tools) {
      this._updateToolEditableFlag(e);
      const t = m(e);
      null != this.activeTool && t || this._manipulatorState.clearPointers(e, this._manipulatorStateEventArgs, !t);
    }
    this._updateCursor();
  }
  get updating() {
    return this._updatingHandles.updating || this.tools.some(t => t.updating);
  }
  get interacting() {
    return this._interacting;
  }
  _clearInteractingTimeout() {
    this._interactingTimeoutHandle = r(this._interactingTimeoutHandle);
  }
  _startInteractingTimeout() {
    this._clearInteractingTimeout(), this._interactingTimeoutHandle = this._clock.setTimeout(() => this._interacting = !1, this._interactingTimeout);
  }
  attach() {
    "3d" === this.view.type ? this.addHandles([s(() => {
      const {
        state: t
      } = this.view;
      return "camera" in t && t.camera;
    }, () => this._forEachManipulator(t => t.onViewChange())), this.view.elevationProvider?.on("elevation-change", t => this._forEachManipulator(o => o.onElevationChange(t)))], _) : this.addHandles(s(() => this.view.extent, () => this._forEachManipulator(t => t.onViewChange())));
  }
  detach() {
    this.activeTool = null, this.tools.removeAll(), this.removeHandles(_), this._clearInteractingTimeout(), this._interacting = !1;
  }
  _forEachManipulator(t) {
    this._forEachTool(o => {
      o.manipulators && o.manipulators.forEach(({
        manipulator: e
      }) => t(e, o));
    });
  }
  _handleInputEvent(t) {
    let o = !1;
    const e = {
      ...t,
      stopPropagation: () => {
        o = !0, t.stopPropagation();
      }
    };
    null != this.activeTool ? this.activeTool.handleInputEvent && this.activeTool.handleInputEvent(e) : this._forEachTool(t => {
      !o && t.visible && t.handleInputEvent(e);
    }), !o && "key-down" === t.type && "Escape" === t.key && this.activeTool && (t.stopPropagation(), this.activeTool = null), this._manipulatorState.handleInputEvent(e, this._manipulatorStateEventArgs), o || null == this.activeTool || this.activeTool.handleInputEventAfter(e), this._manipulatorState.handleHoverEvent(e, this._forEachTool), this._updateCursor(), "pointer-move" === t.type && (this._manipulatorState.hasFocusedManipulators() || this.activeTool) && (this._interacting = !0, this._startInteractingTimeout());
  }
  _refreshToolWatchers() {
    this.removeHandles(g), this._forEachTool(t => {
      if (t instanceof o) {
        const o = s(() => [t.cursor, t.visible, t.editable], () => {
          m(t) || this._manipulatorState.clearPointers(t, this._manipulatorStateEventArgs), this._updateCursor();
        });
        this.addHandles(o, g);
      }
      t.manipulators && this.addHandles([t.manipulators.on("after-remove", o => {
        this._manipulatorState.clearPointers(t, this._manipulatorStateEventArgs, !0, o.item.manipulator);
      }), t.manipulators.on("change", () => {
        this._manipulatorState.updateHoveredStateFromKnownPointers(this._forEachTool), this._updateCursor();
      })], g);
    }), this._manipulatorState.updateHoveredStateFromKnownPointers(this._forEachTool), this._updateCursor();
  }
  _updateToolEditableFlag(t) {
    t.setEditableFlag?.(d.MANAGER, null == this.activeTool || t === this.activeTool);
  }
  _updateCursor() {
    let t = this._manipulatorState.cursor;
    null == t && this._forEachTool(o => !(null == o.cursor || !o.visible) && (t = o.cursor, !0)), this._get("cursor") !== t && this._set("cursor", t);
  }
  _removeIncompleteTools(t) {
    this.tools.filter(o => (null == t || o !== t) && !o.created && o.removeIncompleteOnCancel).forEach(t => {
      this.tools.remove(t);
    });
  }
  get test() {}
};
t([n({
  constructOnly: !0,
  nonNullable: !0
})], f.prototype, "view", void 0), t([n({
  value: null
})], f.prototype, "activeTool", null), t([n({
  readOnly: !0,
  type: i
})], f.prototype, "tools", void 0), t([n({
  readOnly: !0
})], f.prototype, "cursor", void 0), t([n({
  readOnly: !0
})], f.prototype, "updating", null), t([n()], f.prototype, "_interacting", void 0), t([n({
  readOnly: !0
})], f.prototype, "interacting", null), f = t([l("esri.views.ToolViewManager")], f);
export { f as ToolViewManager, T as interactingTimeout };